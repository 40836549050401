const { NODE_ENV: env } = process.env

const backendUrl =
  env === "development"
    ? "http://localhost:8080/v1/"
    : "https://backend.zondabus.com.mx/v1/"

const socketUrl =
  env === "development"
    ? "http://localhost:5500"
    : "https://socket.zondabus.com.mx"
export { backendUrl, socketUrl }
