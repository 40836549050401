import React, { useState } from "react"
import TableCustom from "../../components/TableCustom"
import _ from "lodash"
import Input from "../../components/Input"
import { Grid, IconButton } from "@mui/material"
import Vista from "../../components/Vista"
import dateComponent from "../../components/dateComponent"
import { useModal } from "../../hooks/useModal"
import VisibilityIcon from "@mui/icons-material/Visibility"
import { id } from "date-fns/locale"

const busquedaDefault = {
  idCompra: "",
  fechaInicio: dateComponent.getFirstDayOfYear(),
  fechaFinal: dateComponent.getActualDay()
}

const Compras = ({ onClick, onKeyDown, ...props }) => {
  const [busqueda, setBusqueda] = useState({ ...busquedaDefault })
  const { setContainerModal, setOpen } = useModal()
  const [modalData, setModalData] = useState({ tipo: "", data: [] })

  const moreInfo = (dataList) => {
    setModalData({ data: dataList })
    setContainerModal(
      <div style={{ textAlign: "center" }}>
        <>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr style={{ backgroundColor: "#157CC1", color: "#FFFFFF" }}>
                <th style={{ padding: "10px" }}>Nombe</th>
                <th style={{ padding: "10px" }}>Cantidad</th>
                <th style={{ padding: "10px" }}>Precio</th>
                <th style={{ padding: "10px" }}>Total</th>
              </tr>
            </thead>
            <tbody>
              {dataList.map((e, i) => (
                <tr
                  key={i}
                  style={{
                    backgroundColor: i % 2 === 0 ? "#FFFFFF" : "#D9D9D9"
                  }}
                >
                  <td style={{ padding: "10px", textAlign: "center" }}>
                    {e.articulo}
                  </td>
                  <td style={{ padding: "10px", textAlign: "center" }}>
                    {e.cantidad}
                  </td>
                  <td style={{ padding: "10px", textAlign: "center" }}>
                    {e.precio}
                  </td>
                  <td style={{ padding: "10px", textAlign: "center" }}>
                    {e.total}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      </div>
    )
    setOpen(true)
  }

  let dataTable = {
    idCompra: {
      nombre: "No.de compra",
      content: (e) => {
        return e.idCompra
      }
    },
    fecha: {
      nombre: "Fecha",
      content: (e) => {
        return e.creado
      }
    },
    // status: {
    //   nombre: "Status",
    //   content: (e) => {
    //     return e.status
    //   }
    // },
    total: {
      nombre: "Total de la compra",
      content: (e) => {
        return e.total
      }
    },
    detalles: {
      nombre: "Detalles de la compra",
      content: (e) => {
        const detalleCompras = _.get(e, "detalleCompras", [])
        return (
          <>
            {detalleCompras.length > 0 && (
              <IconButton onClick={() => moreInfo(detalleCompras)}>
                <VisibilityIcon />
              </IconButton>
            )}
          </>
        )
      }
    }
  }
  //PDF
  let dataPdf = {
    idCompra: {
      nombre: "No.de compra",
      content: (e) => {
        return e.idCompra
      }
    },
    fecha: {
      nombre: "Fecha",
      content: (e) => {
        return e.creado
      }
    },
    // status: {
    //   nombre: "Status",
    //   content: (e) => {
    //     return e.status
    //   }
    // },
    total: {
      nombre: "Total de la compra",
      content: (e) => {
        return e.total
      }
    }
  }
  return (
    <Vista>
      <TableCustom
        filtroCustom={(d) => {
          //
          if (d?.eliminado) {
            return false
          }
          // if (busqueda.status && d?.status !== busqueda.status) {
          //   return false
          // }

          if (
            busqueda.idCompra &&
            Number(d?.idCompra) !== Number(busqueda.idCompra)
          ) {
            return false
          }
          return true
        }}
        tipo={"idCompra"}
        busqueda={busqueda}
        setBusqueda={setBusqueda}
        dataTable={dataTable}
        dataPdf={dataPdf}
        fechaInicio={busqueda.fechaInicio}
        fechaFinal={busqueda.fechaFinal}
        buscador={[
          <Grid
            sx={{
              order: "2",
              padding: "0 5px 0 5px",
              height: "40px",
              "@media (min-width: 900px)": {
                marginTop: "0px",
                marginBottom: "10px",
                order: "0"
              },
              "@media (max-width: 600px)": {
                padding: "0 5px 0 5px",
                height: "40px",
                marginTop: "20px",
                marginRight: "15px"
                // margin: "0",
                // order: "0"
              }
            }}
            item
            xs={12}
            sm={4}
            md={4}
            key={1}
          >
            <Input
              value={busqueda?.idCompra}
              placeholder={"No.de compra"}
              labelToShow="Numero de la compra"
              typeInput={"onlyInput"}
              nombreBusqueda="idCompra"
              onChange={(e) =>
                setBusqueda({ ...busqueda, idCompra: e.target.value })
              }
            />
          </Grid>
          // <Grid sx={{
          //   order: "2",
          //   padding: "0 5px 0 5px",
          //   margintTop: "10px",
          //   "@media (min-width: 900px)": {
          //     marginTop: "0px",
          //     marginBottom: "10px",
          //     order: "0",
          //   },
          // }}
          // item
          // xs={12}
          // key={2}
          // >
          //   <Input
          //   typeInput={"formSelect"}
          //   value={busqueda?.status}
          //   nombre= "Status de la compra"
          //   onChange={(e) =>
          //     setBusqueda({ ...busqueda, status: e.target.value })
          //   }
          //   data={["PENDIENTE", "COMPLETADO", "CANCELADO"]}
          //   />
          // </Grid>
        ]}
        expand={"detalleCompras, sucursal"}
        permisoCheck={"compras"}
        nombreBusqueda={"idCompra"}
      />
    </Vista>
  )
}

export default Compras
