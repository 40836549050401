import { CircularProgress, Grid } from "@mui/material"
import React, { useEffect, useState } from "react"
import Input from "../../components/Input"
import Vista from "../../components/Vista"
import { productDetails } from "../../utils/styles"
import FirebaseStorage from "../../services/firebase"
import moment from "moment"
import UploadPhotoComponent from "../../components/UploadPhotoComponent"
import { NavLink } from "react-router-dom"
import { useLocalStorage } from "../../hooks/useLocalStorage"
import Servo from "../../services/servo"
import { useHistory } from "react-router-dom"
import { useMessages } from "../../hooks/useMessages"
import { HiEye, HiEyeOff } from "react-icons/hi"
import { LoadingButton } from "@mui/lab"
import AutocompleteDeluxe from "../../components/AutocompleteDelux"

let vs = productDetails()
const Detail = ({ match }) => {
  const history = useHistory()
  const formDefault = {
    correo: "",
    nombre: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    ine: "",
    telefono: "",
    archivo: {
      nombre: "foto",
      tipo: "",
      nombreFirebase: "",
      url: "",
      folder: ""
    }
  }

  //AREA DE DECLARACIONES
  const { setMessages } = useMessages()
  const permisoCheck = "usuarios"
  const [permiso] = useLocalStorage("permiso", null)
  const [formDetails, setformDetails] = useState({ ...formDefault })
  const id = match?.params?.id
  const editado = !!id
  const [editarState, setEditarState] = useState(false)
  const [file, setFile] = useState(null)
  const [tipoImagen, setTipoImagen] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadingButton, setLoadingButton] = useState(false)

  //SE SUBE LA IMAGEN A FIREBASE IDENTIFICANDO SI ES CANVA O SOURCE
  const uploadFile = async () => {
    if (tipoImagen != null) {
      const folder = "Archivos"
      const ranm = Math.floor(Math.random() * 1000)
      const nombreFirebase =
        "Usuario_" +
        formDetails?.nombre +
        "_" +
        formDetails?.apellidoPaterno +
        "_" +
        formDetails?.apellidoMaterno +
        "_" +
        moment(new Date()).format("DD_MM_YYYY_HH_mm_ss") +
        "_" +
        ranm
      if (tipoImagen == "camera") {
        //DETECTANDO QUE ES CAMARA
        let lastName = formDetails.archivo.nombreFirebase
        const blob = await new Promise((resolve) =>
          file.current.toBlob(resolve)
        )
        let url = await FirebaseStorage.upload(
          folder + "/" + nombreFirebase,
          blob
        )
        if (lastName) {
          await FirebaseStorage.delete(folder, lastName)
        }
        return { nombreFirebase, folder, tipo: blob?.type, url }
      } else if (tipoImagen == "src") {
        //DETECTANDO SI ES UN SOURCE
        let url = await FirebaseStorage.upload(
          folder + "/" + nombreFirebase,
          file
        )
        formDetails.archivo.nombre = file?.name
        return { nombreFirebase, folder, tipo: file?.type, url }
      }
    }
  }

  //SE EXTRAEN LOS PRODUCTOS
  const getData = async () => {
    setLoading(true)
    let res = await Servo.get("usuarios?idUsuario=" + id + "&expand=archivo")
    if (res?.cuerpo?.resultado?.length > 0) {
      setformDetails({ ...formDetails, ...res?.cuerpo?.resultado[0] })
    }
    setLoading(false)
  }

  useEffect(() => {
    if (editado) {
      getData()
    }
  }, [])

  //SE GUARDAN LOS PRODUCTOS
  const guardar = async () => {
    setLoadingButton(true)
    if (editarState) {
      let firebaseInfo = await uploadFile()
      formDetails.archivo = { ...formDetails?.archivo, ...firebaseInfo }
    }
    let res = await Servo.post(
      "usuarios/guardar",
      formDetails,
      "usuarios",
      true,
      history
    )
    if (res?.cuerpo) {
      setMessages(res?.cuerpo)
    }
    setLoadingButton(false)
  }

  let productDetails = vs()

  return (
    <Vista>
      <div className={productDetails.screenSize}>
        <div className={productDetails.top2}>
          {(permiso[permisoCheck] & 1) === 1 ? (
            <NavLink exact to={"/usuarios"}>
              « Volver
            </NavLink>
          ) : (
            <p>« Volver</p>
          )}
        </div>
        <div className={productDetails.top}>
          <div className={productDetails.title}>
            {editado ? <p>Editar Usuario</p> : <p>Nuevo Usuario</p>}
          </div>
        </div>
        <div className={productDetails.formArea}>
          <Grid container direction="row" columns={{ xs: 12, sm: 12, md: 12 }}>
            {!loading ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px"
                    }
                  }}
                  className={productDetails.inputArea}
                >
                  <Input
                    typeInput={"form"}
                    valor={formDetails?.nombre}
                    setValor={setformDetails}
                    placeholder=""
                    nombre="Nombres"
                    required="true"
                    onChange={(e) => {
                      setformDetails({
                        ...formDetails,
                        nombre: e.target.value.toUpperCase()
                      })
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px"
                    }
                  }}
                  className={productDetails.inputArea}
                >
                  <Input
                    typeInput={"form"}
                    valor={formDetails?.apellidoPaterno}
                    setValor={setformDetails}
                    placeholder=""
                    nombre="Apellido Paterno"
                    required="true"
                    onChange={(e) => {
                      setformDetails({
                        ...formDetails,
                        apellidoPaterno: e.target.value.toUpperCase()
                      })
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px"
                    }
                  }}
                  className={productDetails.inputArea}
                >
                  <Input
                    typeInput={"form"}
                    valor={formDetails?.apellidoMaterno}
                    setValor={setformDetails}
                    placeholder=""
                    nombre="Apellido Materno"
                    required="true"
                    onChange={(e) => {
                      setformDetails({
                        ...formDetails,
                        apellidoMaterno: e.target.value.toUpperCase()
                      })
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px"
                    }
                  }}
                  className={productDetails.inputArea}
                >
                  <Input
                    typeInput={"form"}
                    valor={formDetails?.ine}
                    setValor={setformDetails}
                    placeholder=""
                    nombre="INE"
                    required="true"
                    onChange={(e) => {
                      setformDetails({
                        ...formDetails,
                        ine: e.target.value
                      })
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px"
                    }
                  }}
                  className={productDetails.inputArea}
                >
                  <Input
                    typeInput={"form"}
                    valor={formDetails?.correo}
                    setValor={setformDetails}
                    placeholder=""
                    nombre="Correo"
                    required="true"
                    onChange={(e) => {
                      setformDetails({
                        ...formDetails,
                        correo: e.target.value.toUpperCase()
                      })
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px"
                    }
                  }}
                  className={productDetails.inputArea}
                >
                  <Input
                    typeInput={"form"}
                    typeFormat={"phone"}
                    valor={formDetails?.telefono}
                    setValor={setformDetails}
                    placeholder=""
                    nombre="Teléfono"
                    required="false"
                    onChange={(e) => {
                      setformDetails({
                        ...formDetails,
                        telefono: e.target.value
                      })
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px"
                    }
                  }}
                  className={productDetails.inputArea}
                >
                  <Input
                    typeFormat="text"
                    typeInput={"formPassword"}
                    valor={formDetails.password}
                    placeholder="ingrese contraseña"
                    nombre="Contraseña"
                    required="true"
                    onChange={(e) => {
                      setformDetails({
                        ...formDetails,
                        password: e.target.value
                      })
                    }}
                    Icon={HiEye}
                    Icon2={HiEyeOff}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px"
                    }
                  }}
                  className={productDetails.inputArea}
                >
                  <Input
                    typeFormat="text"
                    typeInput={"formPassword"}
                    valor={formDetails.password2}
                    placeholder="Repetir Contraseña"
                    nombre="Repetir Contraseña"
                    required="true"
                    onChange={(e) => {
                      setformDetails({
                        ...formDetails,
                        password2: e.target.value
                      })
                    }}
                    Icon={HiEye}
                    Icon2={HiEyeOff}
                  />
                </Grid>
              </>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{
                  "@media (min-width: 600px)": {
                    margin: "100px"
                  }
                }}
                className={productDetails.inputArea}
              >
                <CircularProgress />
              </Grid>
            )}
          </Grid>
        </div>
        <UploadPhotoComponent
          value={file}
          setValue={setFile}
          setTipo={setTipoImagen}
          editarState={setEditarState}
          editar={
            formDetails.archivo.nombreFirebase ? formDetails.archivo.url : ""
          }
        />
        <div className={productDetails.buttonArea}>
          {(permiso[permisoCheck] & 2) === 2 ? (
            <LoadingButton
              loading={loadingButton}
              onClick={guardar}
              sx={{
                width: "170px",
                height: "50px",
                backgroundColor: "#157CC1",
                fontFamily: "poppins",
                borderRadius: "10px",
                border: "none",
                fontWeight: "500",
                fontSize: "14px",
                letterSpacing: "0.07em",
                color: "#FFFFFF",
                marginLeft: "2px",
                "@media (min-width: 600px)": {
                  marginBottom: "6px"
                },
                "&:hover": {
                  backgroundColor: "#1565c0",
                  cursor: "pointer"
                }
              }}
              className={productDetails.buttonSave}
            >
              GUARDAR
            </LoadingButton>
          ) : (
            <LoadingButton
              onClick={guardar}
              disabled
              className={productDetails.buttonSave}
            >
              GUARDAR
            </LoadingButton>
          )}
        </div>
      </div>
    </Vista>
  )
}

export default Detail
