import React, { useState } from "react"
import TableCustom from "../../components/TableCustom"
import _ from "lodash"
import { Grid, IconButton } from "@mui/material"
import Vista from "../../components/Vista"
import AutocompleteDeluxe from "../../components/AutocompleteDelux"
import dateComponent from "../../components/dateComponent"

const busquedaDefault = {
  fechaInicio: dateComponent.getFirstDayOfYear(),
  fechaFinal: dateComponent.getActualDay()
}

let dataTable = {
  fecha: {
    nombre: "Fecha",
    content: (e) => {
      return e?.creado
    }
  },
  economico: {
    nombre: "Económico",
    content: (e) => {
      return e?.carro?.economico
    }
  },
  placa: {
    nombre: "Placas",
    content: (e) => {
      return e?.carro?.placas
    }
  }
}
//PDF
let dataPdf = {
  fecha: {
    nombre: "Fecha",
    content: (e) => {
      return e?.creado
    }
  },
  economico: {
    nombre: "Económico",
    content: (e) => {
      return e?.carro?.economico
    }
  },
  placa: {
    nombre: "Placas",
    content: (e) => {
      return e?.carro?.placas
    }
  }
}

const ChecklistsCampamento = ({ onClick, onKeyDown, ...props }) => {
  const [busqueda, setBusqueda] = useState({
    ...busquedaDefault
  })
  const [carro, setCarro] = useState(null)
  // const [empresa, setEmpresa] = useState(null)
  // const [area, setArea] = useState(null)
  const [loading, setLoading] = useState(false)

  return (
    <Vista>
      <TableCustom
        filtroCustom={(d) => {
          //
          if (carro) {
            return d?.carro?.idCarro === carro?.idCarro
          } else {
            return d
          }
        }}
        tipo={"checklistsCampamento"}
        busqueda={busqueda}
        setBusqueda={setBusqueda}
        fechaInicio={busqueda.fechaInicio}
        fechaFinal={busqueda.fechaFinal}
        dataTable={dataTable}
        dataPdf={dataPdf}
        Actions={false}
        buscador={[
          <Grid
            sx={{
              order: "2",
              // padding: "0 5px 0 5px",
              // marginTop: "10px",
              padding: "0 5px 0 0px",
              height: "80px",
              "@media (min-width: 900px)": {
                marginTop: "0px",
                marginBottom: "10px",
                order: "0"
              },
              "@media (max-width: 600px)": {
                padding: "0",
                margin: "0",
                orden: "0"
              }
            }}
            item
            xs={12}
            sm={4}
            md={4}
            key={1}
          >
            <AutocompleteDeluxe
              labelToShow="Económico"
              labelProp="economico"
              nombre="carros"
              value={carro}
              setDato={setCarro}
            />
          </Grid>,
          <Grid
            sx={{
              order: "2",
              // padding: "0 5px 0 5px",
              // marginTop: "10px",
              padding: "0 5px 0 5px",
              height: "80px",
              "@media (min-width: 900px)": {
                marginTop: "0px",
                marginBottom: "10px",
                order: "0"
              },
              "@media (max-width: 600px)": {
                padding: "0",
                margin: "0",
                orden: "0"
              }
            }}
            item
            xs={12}
            sm={4}
            md={4}
            key={2}
          >
            {/* <AutocompleteDeluxe
              labelToShow="Empresa"
              labelProp="nombre"
              nombre="empresas"
              value={empresa}
              setDato={setEmpresa}
            /> */}
          </Grid>,
          <Grid
            item
            sx={{
              order: "2",
              // padding: "0 5px 0 5px",
              // marginTop: "10px",
              padding: "0px 0px 0px 5px",
              height: "80px",
              "@media (min-width: 1000px)": {
                marginTop: "0px",
                marginBottom: "10px",
                order: "0"
              },
              "@media (max-width: 600px)": {
                padding: "0",
                margin: "0",
                orden: "0"
              }
            }}
            xs={12}
            sm={4}
            md={4}
            key={3}
          >
            {/* <AutocompleteDeluxe
              labelToShow="Áreas de trabajo"
              labelProp="nombre"
              nombre="areas"
              value={area}
              setDato={setArea}
            /> */}
          </Grid>
        ]}
        expand={"carro,archivo"}
        permisoCheck={"checklistsCampamento"}
        nombreBusqueda={"economico"}
      />
    </Vista>
  )
}
export default ChecklistsCampamento
