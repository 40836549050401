import { useMemo, createContext, useContext, useEffect, useState } from "react"
import io from "socket.io-client"
import { socketUrl } from "../utils/variables"

const SocketContext = createContext()

export function SocketProvider(props) {
  const [socket, setSocket] = useState(null)

  useEffect(() => {
    const socketInstance = io(socketUrl)
    setSocket(socketInstance)

    return () => {
      socketInstance.disconnect() // Desconectar al desmontar el componente
    }
  }, [])

  const memData = useMemo(() => {
    return { socket }
  }, [socket])

  return <SocketContext.Provider value={memData} {...props} />
}

export function useSocket() {
  const context = useContext(SocketContext)
  if (!context) {
    throw new Error("Error: SocketContext not defined.")
  }
  return context
}
