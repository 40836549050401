import { CircularProgress, Grid } from "@mui/material"
import React, { useEffect, useState } from "react"
import Input from "../../components/Input"
import Vista from "../../components/Vista"
import { productDetails } from "../../utils/styles"
import { NavLink } from "react-router-dom"
import { useLocalStorage } from "../../hooks/useLocalStorage"
import Servo from "../../services/servo"
import { useHistory } from "react-router-dom"
import { useMessages } from "../../hooks/useMessages"
import { LoadingButton } from "@mui/lab"

let vs = productDetails()
const Detail = ({ match }) => {
  const history = useHistory()
  const formDefault = {
    precio: 0,
    duracion: 0,
    claveSat: "",
    descripcion: "",
    codigo: "",
    tipo: ""
  }

  //AREA DE DECLARACIONES
  const { setMessages } = useMessages()
  const permisoCheck = "manosObra"
  const [permiso] = useLocalStorage("permiso", null)
  const [formDetails, setformDetails] = useState({ ...formDefault })
  const id = match?.params?.id
  const editado = !!id
  const [loadingB, setLoadingB] = useState(false)
  const [loading, setLoading] = useState(false)

  //SE EXTRAEN LOS PRODUCTOS
  const getDatos = async () => {
    setLoading(true)
    let res = await Servo.get("manos-obra" + "?idManoObra=" + id)
    if (res?.cuerpo?.resultado?.length > 0) {
      setformDetails((prevFormDetails) => ({
        ...prevFormDetails,
        ...res?.cuerpo?.resultado[0]
      }))
    }
    setLoading(false)
  }

  useEffect(() => {
    if (editado) {
      getDatos()
    }
  }, [])

  //SE GUARDAN LOS PRODUCTOS
  const guardar = async () => {
    setLoadingB(true)
    let res = await Servo.post(
      "manos-obra/guardar",
      formDetails,
      permisoCheck,
      true,
      history
    )
    if (res?.cuerpo) {
      setMessages(res?.cuerpo)
    }
    setLoadingB(false)
  }

  let productDetails = vs()

  return (
    <Vista>
      <div className={productDetails.screenSize}>
        <div className={productDetails.volverManosObra}>
          {(permiso[permisoCheck] & 1) === 1 ? (
            <NavLink exact to={"/" + permisoCheck}>
              « Volver
            </NavLink>
          ) : (
            <p>« Volver</p>
          )}
        </div>
        <div className={productDetails.top}>
          <div className={productDetails.title}>
            {editado ? <p>Editar Mano de Obra</p> : <p>Nuevo Mano de Obra</p>}
          </div>
        </div>
        <div className={productDetails.formArea}>
          <Grid container direction="row" columns={{ xs: 12, sm: 12, md: 12 }}>
            {!loading ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px"
                    }
                  }}
                  className={productDetails.inputArea}
                >
                  <Input
                    typeInput={"form"}
                    valor={formDetails?.codigo}
                    setValor={setformDetails}
                    placeholder="Codigo"
                    nombre="Codigo"
                    required="true"
                    onChange={(e) => {
                      setformDetails({
                        ...formDetails,
                        codigo: e.target.value
                      })
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px"
                    }
                  }}
                  className={productDetails.inputArea}
                >
                  <Input
                    typeInput={"form"}
                    valor={formDetails?.claveSat}
                    setValor={setformDetails}
                    placeholder="Clave del SAT"
                    nombre="Clave del SAT"
                    required="true"
                    onChange={(e) => {
                      setformDetails({
                        ...formDetails,
                        claveSat: e.target.value
                      })
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px"
                    }
                  }}
                  className={productDetails.inputArea}
                >
                  <Input
                    typeInput={"form"}
                    valor={formDetails?.descripcion}
                    setValor={setformDetails}
                    placeholder="Descripción"
                    nombre="Descripción"
                    required="true"
                    onChange={(e) => {
                      setformDetails({
                        ...formDetails,
                        descripcion: e.target.value
                      })
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px"
                    }
                  }}
                  className={productDetails.inputArea}
                >
                  <Input
                    typeInput={"form"}
                    valor={formDetails?.precio}
                    setValor={setformDetails}
                    placeholder="Precio"
                    nombre="Precio"
                    onChange={(e) => {
                      setformDetails({
                        ...formDetails,
                        precio: e.target.value
                      })
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px"
                    }
                  }}
                  className={productDetails.inputArea}
                >
                  <Input
                    typeInput={"form"}
                    valor={formDetails?.duracion}
                    setValor={setformDetails}
                    placeholder="Tiempo estimado"
                    nombre="Duracion"
                    onChange={(e) => {
                      setformDetails({
                        ...formDetails,
                        duracion: e.target.value
                      })
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px"
                    }
                  }}
                  className={productDetails.inputArea}
                >
                  <Input
                    typeInput={"formSelect"}
                    valor={formDetails?.tipo}
                    nombre="Tipo de Mano de Obra"
                    required="true"
                    onChange={(e) =>
                      setformDetails({ ...formDetails, tipo: e.target.value })
                    }
                    data={[
                      "FRENOS",
                      "S. ELEC",
                      "TREN M.",
                      "SOLDADURA",
                      "A/C",
                      "CRISTALES",
                      "TRANSMISION",
                      "MOTOR",
                      "LLANTA",
                      "CORR. PROG.",
                      "INDICAR"
                    ]}
                  />
                </Grid>
              </>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{
                  "@media (min-width: 600px)": {
                    margin: "100px"
                  }
                }}
                className={productDetails.inputArea}
              >
                <CircularProgress />
              </Grid>
            )}
          </Grid>
        </div>
        <div className={productDetails.buttonArea}>
          {(permiso[permisoCheck] & 2) === 2 ? (
            <LoadingButton
              loading={loadingB}
              onClick={guardar}
              sx={{
                width: "170px",
                height: "50px",
                backgroundColor: "#157CC1",
                fontFamily: "poppins",
                borderRadius: "10px",
                border: "none",
                fontWeight: "500",
                fontSize: "14px",
                letterSpacing: "0.07em",
                color: "#FFFFFF",
                marginLeft: "2px",
                "@media (min-width: 600px)": {
                  marginBottom: "6px"
                },
                "&:hover": {
                  backgroundColor: "#1565c0",
                  cursor: "pointer"
                }
              }}
            >
              GUARDAR
            </LoadingButton>
          ) : (
            <LoadingButton
              onClick={guardar}
              disabled
              sx={{
                width: "170px",
                height: "50px",
                backgroundColor: "#157CC1",
                fontFamily: "poppins",
                borderRadius: "10px",
                border: "none",
                fontWeight: "500",
                fontSize: "14px",
                letterSpacing: "0.07em",
                color: "#FFFFFF",
                marginLeft: "2px",
                "@media (min-width: 600px)": {
                  marginBottom: "6px"
                },
                "&:hover": {
                  backgroundColor: "#1565c0",
                  cursor: "pointer"
                }
              }}
            >
              GUARDAR
            </LoadingButton>
          )}
        </div>
      </div>
    </Vista>
  )
}

export default Detail
