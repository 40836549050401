import Vista from "../../components/Vista"
import { ChecklistStyles } from "../../utils/styles"
import Selector from "./Selector"
import { useEffect, useRef, useState } from "react"
import { NavLink } from "react-router-dom"
import { useLocalStorage } from "../../hooks/useLocalStorage"
import DatosGenerales from "./DatosGenerales"
import Check from "./Check"
import Gasolina from "./Gasolina"
import Firma from "./Firma"
import { useAlert } from "../../hooks/useAlert"
import Servo from "../../services/servo"
import { useMessages } from "../../hooks/useMessages"
import { useHistory } from "react-router-dom"
import moment from "moment"
import FirebaseStorage from "../../services/firebase"
import { CircularProgress, Grid } from "@mui/material"
import ChecklistArchivos from "./ChecklistArchivos"
import { set } from "lodash"

let vs = ChecklistStyles()
const Detail = ({ match }) => {
  const [screen, setScreen] = useState("DatosGenerales")
  const { showAlert } = useAlert()
  const id = match?.params?.id
  const editado = !!id
  const { setMessages } = useMessages()
  const permisoCheck = "checklists"
  const [permiso] = useLocalStorage("permiso", {})
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [uploadedPhotos, setUploadedPhotos] = useState([])

  const getDatos = async () => {
    setLoading(true)
    let res = await Servo.get(
      "checklists" +
        "?idChecklist=" +
        id +
        "&expand=datoschecklists.areas,datoschecklists.carros,datoschecklists.empresas,detallechecklists,gasolinachecklists.notas,checklistsarchivos.archivos,firmachecklists"
    )
    if (res?.cuerpo?.resultado?.length > 0) {
      setCarro(res?.cuerpo?.resultado[0]?.datoschecklists?.carros)
      setArea(res?.cuerpo?.resultado[0]?.datoschecklists?.areas)
      setEmpresa(res?.cuerpo?.resultado[0]?.datoschecklists?.empresas)
      setformDetails(res?.cuerpo?.resultado[0]?.datoschecklists)

      setLucesDelanteras(
        JSON.parse(
          res?.cuerpo?.resultado[0]?.detallechecklists?.lucesDelanteras
        )
      )
      setLucesTraseras(
        JSON.parse(res?.cuerpo?.resultado[0]?.detallechecklists?.lucesTraseras)
      )
      setLiquidoVehiculo(
        JSON.parse(
          res?.cuerpo?.resultado[0]?.detallechecklists?.liquidoVehiculo
        )
      )
      setCarroceria(
        JSON.parse(res?.cuerpo?.resultado[0]?.detallechecklists?.carroceria)
      )
      setEquipoSeguridad(
        JSON.parse(
          res?.cuerpo?.resultado[0]?.detallechecklists?.equipoSeguridad
        )
      )
      setFrenos(
        JSON.parse(res?.cuerpo?.resultado[0]?.detallechecklists?.frenos)
      )
      setLlantas(
        JSON.parse(res?.cuerpo?.resultado[0]?.detallechecklists?.llantas)
      )
      setBateria(
        JSON.parse(res?.cuerpo?.resultado[0]?.detallechecklists?.baterias)
      )
      setGasolinaInfo(res?.cuerpo?.resultado[0]?.gasolinachecklists)
      setPoints(res?.cuerpo?.resultado[0]?.gasolinachecklists?.notas)
      setDataGas(res?.cuerpo?.resultado[0]?.gasolinachecklists?.gasolina)
      let archivosParaCheck = []
      res?.cuerpo?.resultado[0]?.checklistsarchivos.forEach((check) => {
        archivosParaCheck.push(check.archivos)
      })
      setChecklistArchivos(archivosParaCheck)
    }

    setLoading(false)
  }

  useEffect(() => {
    if (editado) {
      getDatos()
    }
  }, [])

  const validacionesToChange = () => {
    let dg = true

    if (!carro?.idCarro) {
      if (screen == "DatosGenerales") {
        showAlert({
          message: "seleccionar un carro, es requerido",
          severity: "warning"
        })
      }
      dg = false
    }
    if (!empresa?.idEmpresa) {
      if (screen == "DatosGenerales") {
        showAlert({
          message: "seleccionar una empresa, es requerido",
          severity: "warning"
        })
      }
      dg = false
    }
    if (!area?.idArea) {
      if (screen == "DatosGenerales") {
        showAlert({
          message: "seleccionar un Área, es requerido",
          severity: "warning"
        })
      }
      dg = false
    }

    let check = true

    Object.keys(frenos).forEach((freno) => {
      if (frenos[freno] == "") {
        if (screen == "Checklist") {
          showAlert({
            message: "el campo " + freno + ", es requerido",
            severity: "warning"
          })
        }
        check = false
      }
    })
    Object.keys(llantas).forEach((llanta) => {
      if (llantas[llanta] == "") {
        if (screen == "Checklist") {
          showAlert({
            message: "el campo " + llanta + ", es requerido",
            severity: "warning"
          })
        }
        check = false
      }
    })

    let gas = true

    if (gasolinaInfo?.gasolina == "") {
      if (screen == "Gasolina") {
        showAlert({
          message: "el campo gasolina, es requerido",
          severity: "warning"
        })
      }
      gas = false
    }
    if (gasolinaInfo?.kilometraje == "") {
      if (screen == "Gasolina") {
        showAlert({
          message: "el campo kilometraje, es requerido",
          severity: "warning"
        })
      }
      gas = false
    }

    let evidencia = true

    // Verifica si se han subido al menos 4 fotos
    if (checklistArchivos?.length < 4) {
      if (screen == "checklistArchivos") {
        showAlert({
          message: "Subir al menos 4 fotos",
          severity: "warning"
        })
      }
      evidencia = false
    }

    return { dg, check, gas, evidencia }
  }

  const formDefault = {
    seguro: false,
    tarjetaCirculacion: false,
    licencia: false,
    ubicacion: "",
    tipoChecklist: "",
    asignado: ""
  }

  const uploadFile = async (fileToUpload) => {
    if (archivo) {
      await FirebaseStorage.delete(folder, archivo?.nombreFirebase)
    }
    const blob = await new Promise((resolve) => fileToUpload.toBlob(resolve))
    const folder = "Archivos"
    const ranm = Math.floor(Math.random() * 1000)
    const nombreFirebase =
      "Checklist_Firma_" +
      nombre +
      "_" +
      moment(new Date()).format("DD_MM_YYYY_HH_mm_ss") +
      "_" +
      ranm

    let url = await FirebaseStorage.upload(folder + "/" + nombreFirebase, blob)
    return {
      nombreFirebase,
      folder,
      tipo: blob?.type,
      url,
      nombre: nombreFirebase
    }
  }

  const uploadEvidence = async (fileToUpload) => {
    if (archivo) {
      await FirebaseStorage.delete(folder, archivo?.nombreFirebase)
    }
    const blob = await new Promise((resolve) => {
      fileToUpload.current.toBlob((blob) => resolve(blob))
    })
    const folder = "Archivos"
    const ranm = Math.floor(Math.random() * 1000)
    const nombreFirebase =
      "Checklist_Evidencia_" +
      carro?.economico +
      "_" +
      moment(new Date()).format("DD_MM_YYYY_HH_mm_ss") +
      "_" +
      ranm

    let url = await FirebaseStorage.upload(folder + "/" + nombreFirebase, blob)

    // Actualiza el array de fotos subidas
    setUploadedPhotos((prevPhotos) => [...prevPhotos, url])

    return {
      nombreFirebase,
      folder,
      tipo: blob?.type,
      url,
      nombre: nombreFirebase
    }
  }

  const [loadingButton, setLoadingButton] = useState(false)

  const [formDetails, setformDetails] = useState({ ...formDefault })
  const [carro, setCarro] = useState(null)
  const [area, setArea] = useState(null)
  const [archivo, setArchivo] = useState(null)
  const [empresa, setEmpresa] = useState(null)
  const [points, setPoints] = useState([])
  const [ubicacion, setUbicacion] = useState("")
  const [tipoCheklist, setTipoCheklist] = useState("")

  const canvasFirma = useRef(null)
  const [firma, setFirma] = useState(null)
  const [nombre, setNombre] = useState(null)
  const [entregado, setEntregado] = useState(null)

  const [lucesDelanteras, setLucesDelanteras] = useState({
    principalDerecha: false,
    principalIzquierda: false,
    direccionalDerecha: false,
    direccionalIzquierda: false,
    intermitenteDerecha: false,
    intermitenteIzquierda: false
  })
  const [liquidoVehiculo, setLiquidoVehiculo] = useState({
    aceiteMotor: false,
    aceiteTransmision: false,
    liquidoFrenos: false,
    refrigerante: false,
    limpiaParabrisas: false,
    direccionHidraulica: false
  })
  const [lucesTraseras, setLucesTraseras] = useState({
    direccionalDerecha: false,
    direccionalIzquierda: false,
    intermitenteDerecha: false,
    intermitenteIzquierda: false,
    frenoDerecho: false,
    frenoIzquierdo: false,
    reversaDerecha: false,
    reversaIzquierda: false,
    ledDerecho: false,
    ledIzquierdo: false
  })
  const [carroceria, setCarroceria] = useState({
    portaCalza: false,
    porteExterior: false,
    parabrisasDelantero: false,
    medallonTrasero: false,
    ventanas: false,
    espejosRetrovisores: false,
    llantas: false,
    aireAcondicionado: false,
    interiores: false,
    asientos: false
  })
  const [equipoSeguridad, setEquipoSeguridad] = useState({
    cruceta: false,
    gato: false,
    botiquin: false,
    trianguloSeguridad: false,
    alarmaReversa: false,
    extintor: false,
    calzaEstacionamiento: false,
    limpiaparabrisas: false,
    cinturones: false,
    claxon: false,
    cablesCorriente: false,
    llantaExtra: false,
    frenoDeMano: false,
    frenosBalatas: false,
    banderola: false,
    torreta: false,
    ledsLaterales: false,
    conos: false
  })
  const [frenos, setFrenos] = useState({
    frontalIzquierda: "",
    frontalDerecha: "",
    traseraIzquierda: "",
    traseraDerecha: "",
    zapataTrasera: ""
  })
  const [llantas, setLlantas] = useState({
    frontalIzquierda: "",
    frontalDerecha: "",
    traseraIzquierda: "",
    traseraDerecha: "",
    extra: ""
  })
  const [bateria, setBateria] = useState({
    terminales: false,
    base: false
  })
  const [gasolinaInfo, setGasolinaInfo] = useState({
    kilometraje: "",
    gasolina: "",
    observaciones: "",
    tipo: "",
    notas: []
  })
  const [checklistArchivos, setChecklistArchivos] = useState([])
  const [files, setFiles] = useState([])
  const [dataGas, setDataGas] = useState(0)
  const [selected, setSelected] = useState()
  const [image, setImage] = useState("default")
  const imageChange = (screen) => {
    setSelected(screen)
    screen == "pickup"
      ? setImage(
          "https://firebasestorage.googleapis.com/v0/b/zonda-b0ce9.appspot.com/o/Sistema%2FpickupPlanos.png?alt=media&token=43ac161e-cb44-46a6-9ddc-a57e50b81d3e"
        )
      : screen === "sedan"
      ? setImage(
          "https://firebasestorage.googleapis.com/v0/b/zonda-b0ce9.appspot.com/o/Sistema%2FsedanPlanos.png?alt=media&token=12861520-a432-47c4-aa44-f15f222c1c37"
        )
      : screen === "grua"
      ? setImage(
          "https://firebasestorage.googleapis.com/v0/b/zonda-b0ce9.appspot.com/o/Sistema%2FgruaPlanos.png?alt=media&token=c8bc20b9-15c8-4986-b74d-4d3e110bf6e6 "
        )
      : screen === "tonelada"
      ? setImage(
          "https://firebasestorage.googleapis.com/v0/b/zonda-b0ce9.appspot.com/o/Sistema%2FtoneladaPlanos.png?alt=media&token=9a34d3ea-4688-4996-bb31-4dfb0c118f87"
        )
      : setImage("default")
  }

  const guardar = async () => {
    let archivo = null
    if (firma) {
      archivo = await uploadFile(firma)
    }
    setLoadingButton(true)
    let body = {
      datosChecklists: {
        idCarro: carro?.idCarro,
        idArea: area?.idArea,
        idEmpresa: empresa?.idEmpresa,
        seguro: formDetails?.seguro,
        tarjetaCirculacion: formDetails?.tarjetaCirculacion,
        licencia: formDetails?.licencia,
        ubicacion: ubicacion,
        tipoChecklist: tipoCheklist,
        asignado: formDetails?.asignado
      },
      DetallesChecklists: {
        lucesDelanteras: JSON.stringify(lucesDelanteras),
        lucesTraseras: JSON.stringify(lucesTraseras),
        liquidoVehiculo: JSON.stringify(liquidoVehiculo),
        carroceria: JSON.stringify(carroceria),
        equipoSeguridad: JSON.stringify(equipoSeguridad),
        frenos: JSON.stringify(frenos),
        llantas: JSON.stringify(llantas),
        baterias: JSON.stringify(bateria)
      },
      GasolinaChecklists: {
        ...gasolinaInfo,
        notas: points
      },
      ChecklistsArchivos: {
        ...checklistArchivos
      },
      FirmaChecklists: {
        nombre: nombre,
        archivo,
        entregado: entregado
      }
    }

    if (editado) {
      body = { ...body, idChecklist: id }
    }

    let res = await Servo.post(
      "checklists/guardar",
      body,
      "checklists",
      true,
      history,
      true
    )
    if (res?.cuerpo) {
      setMessages(res?.cuerpo)
    }
    setLoadingButton(false)
  }

  let ChecklistStyles = vs()
  return (
    <Vista style={{ display: "block", backgroundColor: "#FFFFFF" }}>
      {!loading ? (
        <>
          <div className={ChecklistStyles.optionsContainer}>
            <Selector
              toChange={validacionesToChange}
              setScreen={setScreen}
              screen={screen}
            />
          </div>
          <div className={ChecklistStyles.volverCheckList}>
            {(permiso[permisoCheck] & 1) === 1 ? (
              <NavLink exact to={"/" + permisoCheck}>
                « Volver
              </NavLink>
            ) : (
              <p>« Volver</p>
            )}
          </div>
          {screen === "DatosGenerales" ? (
            <DatosGenerales
              formDetails={formDetails}
              setformDetails={setformDetails}
              carro={carro}
              setCarro={setCarro}
              empresa={empresa}
              setEmpresa={setEmpresa}
              area={area}
              setArea={setArea}
              ubicacion={ubicacion}
              setUbicacion={setUbicacion}
              tipoCheklist={tipoCheklist}
              setTipoCheklist={setTipoCheklist}
              files={files}
              setFiles={setFiles}
            />
          ) : screen === "Checklist" ? (
            <Check
              lucesDelanteras={lucesDelanteras}
              setLucesDelanteras={setLucesDelanteras}
              liquidoVehiculo={liquidoVehiculo}
              setLiquidoVehiculo={setLiquidoVehiculo}
              lucesTraseras={lucesTraseras}
              setLucesTraseras={setLucesTraseras}
              carroceria={carroceria}
              setCarroceria={setCarroceria}
              equipoSeguridad={equipoSeguridad}
              setEquipoSeguridad={setEquipoSeguridad}
              frenos={frenos}
              setFrenos={setFrenos}
              llantas={llantas}
              setLlantas={setLlantas}
              bateria={bateria}
              setBateria={setBateria}
            />
          ) : screen === "Gasolina" ? (
            <Gasolina
              gasolinaInfo={gasolinaInfo}
              setGasolinaInfo={setGasolinaInfo}
              points={points}
              setPoints={setPoints}
              dataGas={dataGas}
              selected={selected}
              imageChange={imageChange}
              image={image}
              setDataGas={setDataGas}
            />
          ) : screen === "checklistArchivos" ? (
            <ChecklistArchivos
              editado={editado}
              uploadEvidence={uploadEvidence}
              checklistArchivos={checklistArchivos}
              setChecklistArchivos={setChecklistArchivos}
            />
          ) : screen === "Firma" ? (
            <Firma
              guardar={guardar}
              canvasFirma={canvasFirma}
              nombre={nombre}
              entregado={entregado}
              permisoCheck={"checklists"}
              setNombre={setNombre}
              setEntregado={setEntregado}
              firma={firma}
              setFirma={setFirma}
              loadingButton={loadingButton}
            />
          ) : (
            ""
          )}
        </>
      ) : (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            "@media (min-width: 600px)": {
              margin: "100px"
            }
          }}
        >
          <CircularProgress />
        </Grid>
      )}
    </Vista>
  )
}
export default Detail
