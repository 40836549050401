import { CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Input from "../../components/Input";
import Vista from "../../components/Vista";
import { productDetails } from "../../utils/styles";
import { NavLink } from "react-router-dom";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import Servo from "../../services/servo";
import { useHistory } from "react-router-dom";
import { useMessages } from "../../hooks/useMessages";
import { LoadingButton } from "@mui/lab";

let vs = productDetails();
const Detail = ({ match }) => {
  const history = useHistory();
  const formDefault = {
    nombreSucursal: "",
    direccion: "",
    telefono: "",
    correo: "",
    comentario: "",
    urlFoto: "",
  };

  //AREA DE DECLARACIONES
  const { setMessages } = useMessages();
  const permisoCheck = "sucursales";
  const [permiso] = useLocalStorage("permiso", null);
  const [formDetails, setformDetails] = useState({ ...formDefault });
  const id = match?.params?.id;
  const editado = !!id;
  const [loadingB, setLoadingB] = useState(false);
  const [loading, setLoading] = useState(false);

  //SE EXTRAEN LOS PRODUCTOS
  const getDatos = async () => {
    setLoading(true);
    let res = await Servo.get(permisoCheck + "?idSucursal=" + id);
    if (res?.cuerpo?.resultado?.length > 0) {
      setformDetails({ ...formDetails, ...res?.cuerpo?.resultado[0] });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (editado) {
      getDatos();
    }
  }, []);

  //SE GUARDAN LOS PRODUCTOS
  const guardar = async () => {
    setLoadingB(true);
    try {
      let res = await Servo.post(
        permisoCheck + "/guardar",
        formDetails,
        permisoCheck,
        true,
        history
      );
      if (res?.cuerpo) {
        setMessages(res?.cuerpo);
      }
    } catch (error) {
      console.error("Error al guardar:", error);
    }
    setLoadingB(false);
  };

  let productDetails = vs();
  return (
    <Vista>
      <div className={productDetails.screenSize}>
        <div className={productDetails.volverSucursal}>
          {(permiso[permisoCheck] & 1) === 1 ? (
            <NavLink exact to={"/" + permisoCheck}>
              « Volver
            </NavLink>
          ) : (
            <p>« Volver</p>
          )}
        </div>
        <div className={productDetails.top}>
          <div className={productDetails.title}>
            {editado ? <p>Editar Sucursal</p> : <p>Nueva Sucursal</p>}
          </div>
        </div>
        <div className={productDetails.formArea}>
          <Grid container direction="row" columns={{ xs: 12, sm: 12, md: 12 }}>
            {!loading ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px",
                    },
                  }}
                  className={productDetails.inputArea}>
                  <Input
                    typeInput={"form"}
                    valor={formDetails?.nombreSucursal}
                    setValor={setformDetails}
                    placeholder="Nombre de la Sucursal"
                    nombre="Nombre de la Sucursal"
                    required="true"
                    onChange={(e) => {
                      setformDetails({
                        ...formDetails,
                        nombreSucursal: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px",
                    },
                  }}
                  className={productDetails.inputArea}>
                  <Input
                    typeInput={"form"}
                    valor={formDetails?.direccion}
                    setValor={setformDetails}
                    placeholder="Dirección de la Sucursal"
                    nombre="Dirección"
                    required="true"
                    onChange={(e) => {
                      setformDetails({
                        ...formDetails,
                        direccion: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px",
                    },
                  }}
                  className={productDetails.inputArea}>
                  <Input
                    typeInput={"form"}
                    valor={formDetails?.telefono}
                    setValor={setformDetails}
                    placeholder="Telefono"
                    nombre="Telefono"
                    required="true"
                    onChange={(e) => {
                      setformDetails({
                        ...formDetails,
                        telefono: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px",
                    },
                  }}
                  className={productDetails.inputArea}>
                  <Input
                    typeInput={"form"}
                    valor={formDetails?.correo}
                    setValor={setformDetails}
                    placeholder="Correo de la Sucursal"
                    nombre="Correo electrónico"
                    required="true"
                    onChange={(e) => {
                      setformDetails({
                        ...formDetails,
                        correo: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px",
                    },
                  }}
                  className={productDetails.inputArea}>
                  <Input
                    typeInput={"form"}
                    valor={formDetails?.comentario}
                    setValor={setformDetails}
                    placeholder="Comentario de la Sucursal"
                    nombre="Comentario"
                    required="false"
                    onChange={(e) => {
                      setformDetails({
                        ...formDetails,
                        comentario: e.target.value,
                      });
                    }}
                  />
                </Grid>
              </>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{
                  "@media (min-width: 600px)": {
                    margin: "100px",
                  },
                }}
                className={productDetails.inputArea}>
                <CircularProgress />
              </Grid>
            )}
          </Grid>
        </div>
        <div className={productDetails.buttonArea}>
          {(permiso[permisoCheck] & 2) === 2 ? (
            <LoadingButton
              loading={loadingB}
              onClick={guardar}
              sx={{
                width: "170px",
                height: "50px",
                backgroundColor: "#157CC1",
                fontFamily: "poppins",
                borderRadius: "10px",
                border: "none",
                fontWeight: "500",
                fontSize: "14px",
                letterSpacing: "0.07em",
                color: "#FFFFFF",
                marginLeft: "2px",
                "@media (min-width: 600px)": {
                  marginBottom: "6px",
                },
                "&:hover": {
                  backgroundColor: "#1565c0",
                  cursor: "pointer",
                },
              }}>
              GUARDAR
            </LoadingButton>
          ) : (
            <LoadingButton
              onClick={guardar}
              disabled
              sx={{
                width: "170px",
                height: "50px",
                backgroundColor: "#157CC1",
                fontFamily: "poppins",
                borderRadius: "10px",
                border: "none",
                fontWeight: "500",
                fontSize: "14px",
                letterSpacing: "0.07em",
                color: "#FFFFFF",
                marginLeft: "2px",
                "@media (min-width: 600px)": {
                  marginBottom: "6px",
                },
                "&:hover": {
                  backgroundColor: "#1565c0",
                  cursor: "pointer",
                },
              }}>
              GUARDAR
            </LoadingButton>
          )}
        </div>
      </div>
    </Vista>
  );
};

export default Detail;
