import React, { useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import {
  EstilosButton,
  OtroTallerStyles,
  buttonStyleToT,
  dropzoneStyle
} from "../../utils/styles"
import { LoadingButton } from "@mui/lab"
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material"
import { PictureAsPdf } from "@mui/icons-material"
import { useHistory } from "react-router-dom"
import { useAlert } from "../../hooks/useAlert"
import { BiPlus } from "react-icons/bi"
import DeleteIcon from "@mui/icons-material/Delete"
import AutocompleteDeluxe from "../../components/AutocompleteDelux"
import Multifile from "../../components/Multifile"

let vs = OtroTallerStyles()
let VS2 = EstilosButton()
const OtroTaller = ({
  guardarOtroTaller,
  servicioOtroTaller,
  setServicioOtroTaller,
  files,
  setFiles
}) => {
  let OtroTallerStyles = vs()
  let EstilosButton = VS2()
  const { showAlert } = useAlert()
  const history = useHistory()
  const [manoObraOtroTaller, setManoObraOtroTaller] = useState(null)
  const [loading, setLoading] = useState(false)

  const addServices = async () => {
    if (manoObraOtroTaller && manoObraOtroTaller.idManoObra) {
      // Verifica que idManoObra esté definido
      let copyServices = [...servicioOtroTaller]
      let validacion = servicioOtroTaller.some((services) => {
        return services.idManoObra == manoObraOtroTaller.idManoObra
      })
      if (validacion) {
        showAlert("El servicio ya ha sido ingresado", "error")
      } else {
        copyServices.push({ ...manoObraOtroTaller, cantidad: 1 })
        setServicioOtroTaller([...copyServices])
        setManoObraOtroTaller(null)
      }
    } else {
      showAlert({
        message: "Seleccione una Mano de Obra válida",
        severity: "warning"
      })
    }
  }

  const deleteServices = (idManoObra) => {
    let copyServices = [...servicioOtroTaller]
    let index = copyServices.findIndex((services) => {
      return services.idManoObra == idManoObra
    })
    copyServices.splice(index, 1)
    setServicioOtroTaller([...copyServices])
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div className={OtroTallerStyles.top}>
          <div className={OtroTallerStyles.title}>
            {<p>Servicios de TOT</p>}
          </div>
        </div>
      </div>

      <div className={OtroTallerStyles.container}>
        <div className={OtroTallerStyles.formArea}>
          <div className={OtroTallerStyles.formDataContainer}>
            <Grid
              container
              direction="row"
              sx={{ width: "100%", height: "100%" }}
              columns={{ xs: 12, sm: 12, md: 12 }}
            >
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "92px",
                  padding: "0 10px 0 10px"
                }}
              >
                <AutocompleteDeluxe
                  required
                  labelToShow="Manos de Obra"
                  labelProp="codigo"
                  nombre="manos-obra"
                  value={manoObraOtroTaller}
                  setDato={setManoObraOtroTaller}
                  background={"#FFFFFF"}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  "@media (max-width: 600px)": {
                    alignItems: "center"
                  },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  height: "92px",
                  padding: "0 10px 0 10px"
                }}
              >
                <Button
                  sx={{
                    width: "100%",
                    height: "41px",
                    backgroundColor: "#157CC1",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    textTransform: "none",
                    marginBottom: "7px"
                  }}
                  onClick={addServices}
                  variant="contained"
                >
                  <BiPlus className={EstilosButton.icon} />
                  <p className={EstilosButton.font}>Agregar Mano de Obra</p>
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={OtroTallerStyles.formAreaTable}>
          <Paper
            sx={{
              "@media (max-width: 600px)": { width: "98%", minHeight: "50%" },
              width: "70%",
              overflow: "hidden"
            }}
          >
            <TableContainer sx={{ maxHeight: 320 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className={OtroTallerStyles?.tableHead}>
                    <TableCell
                      className="headColor"
                      sx={{ fontFamily: "poppins" }}
                    >
                      #
                    </TableCell>
                    <TableCell
                      className="headColor"
                      sx={{ fontFamily: "poppins" }}
                    >
                      Codigo
                    </TableCell>
                    <TableCell
                      className="headColor"
                      sx={{ fontFamily: "poppins" }}
                    >
                      Descripcion
                    </TableCell>
                    <TableCell
                      className="headColor"
                      sx={{ fontFamily: "poppins" }}
                    >
                      Eliminar
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {servicioOtroTaller &&
                    servicioOtroTaller.length > 0 &&
                    servicioOtroTaller.map((services, index) => (
                      <TableRow
                        className={OtroTallerStyles?.tableRow}
                        key={index}
                      >
                        <TableCell
                          className="rowColor"
                          sx={{ fontFamily: "poppins" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          className="rowColor"
                          sx={{ fontFamily: "poppins", lineHeight: "13px" }}
                        >
                          {services.codigo}
                        </TableCell>
                        <TableCell
                          className="rowColor"
                          sx={{ fontFamily: "poppins", lineHeight: "13px" }}
                        >
                          {services.descripcion}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => deleteServices(services.idManoObra)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center"
          }}
        >
          <Multifile
            loading={loading}
            setLoading={setLoading}
            files={files}
            setFiles={setFiles}
            firebaseFolder={"PDFs_TOT"}
          />
        </div>

        <div
          className={OtroTallerStyles?.buttonAreaContainer}
          style={{ width: "80%", backgroundColor: "#ffffff" }}
        >
          <div
            className={OtroTallerStyles?.buttonContainer}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <LoadingButton
              sx={buttonStyleToT}
              className={OtroTallerStyles?.buttonSave}
              onClick={async (e) => {
                guardarOtroTaller(files, servicioOtroTaller)
              }}
            >
              GUARDAR TOT
            </LoadingButton>
          </div>
        </div>
      </div>
    </div>
  )
}
export default OtroTaller
