import React from "react"
import { Document, Image, Page, Text, pdf } from "@react-pdf/renderer"
import gasolinaDashboard from "../images/gasolinaDashboard.png"
import pickupPlanos from "../images/pickupPlanos.png"
import sedanPlanos from "../images/sedanPlanos.png"
import toneladaPlanos from "../images/toneladaPlanos.png"
import gruaPlanos from "../images/gruaPlanos.png"
import moment from "moment"

const checkTables = (
  items,
  title,
  number = false,
  medida = false,
  ranngeDanger = false,
  rangeWarning = false,
  rangeOk = false
) => {
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          height: "16px",
          backgroundColor: "#157CC1",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          border: "1px solid black"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "70%",
            height: "100%",
            borderRight: "1px solid black"
          }}
        >
          <Text
            style={{
              fontSize: "9px",
              fontWeight: "500",
              paddingLeft: "10px",
              color: "black",
              height: "80%"
            }}
          >
            {title}
          </Text>
        </div>
        {!number ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "30%",
              height: "100%"
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "50%",
                height: "100%",
                borderRight: "1px solid black"
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: "10px",
                  color: "black",
                  height: "80%"
                }}
              >
                B
              </Text>
            </div>
            <div
              div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "50%",
                height: "100%"
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: "10px",
                  color: "black",
                  height: "80%"
                }}
              >
                M
              </Text>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "30%",
              height: "100%"
            }}
          >
            <Text
              style={{
                textAlign: "center",
                fontSize: "10px",
                color: "black",
                height: "80%"
              }}
            >
              {medida}
            </Text>
          </div>
        )}
      </div>
      {Object.keys(items).map((item, i) => {
        return (
          <div
            key={i}
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "16px",
              borderBottom: "1px solid black",
              borderLeft: "1px solid black",
              borderRight: "1px solid black"
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "10%",
                height: "100%",
                borderRight: "1px solid black"
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: "10px",
                  color: "black",
                  height: "80%"
                }}
              >
                {i + 1}
              </Text>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "60%",
                height: "100%",
                borderRight: "1px solid black"
              }}
            >
              <Text
                style={{
                  fontSize: "10px",
                  paddingLeft: "5px",
                  color: "black",
                  height: "75%"
                }}
              >
                {item
                  .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
                    return index === 0
                      ? word.toLowerCase()
                      : " " + word.toUpperCase()
                  })
                  .replace(/\s+/g, " ")
                  .split(" ")
                  .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
                  .join(" ")}
              </Text>
            </div>
            {!number ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "30%",
                  height: "100%"
                }}
              >
                <div
                  style={{
                    width: "50%",
                    height: "100%",
                    backgroundColor: items[item] ? "#157CC1" : "#FFFFFF",
                    borderRight: "1px solid black"
                  }}
                ></div>
                <div
                  style={{
                    width: "50%",
                    height: "100%",
                    backgroundColor: !items[item] ? "#e71633" : "#FFFFFF"
                  }}
                ></div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "30%",
                  height: "100%",
                  opacity: "0.7",
                  backgroundColor:
                    items[item] == ""
                      ? "#FFFFFF"
                      : items[item] >= rangeOk[0] && items[item] <= rangeOk[1]
                      ? "#09BA1B"
                      : items[item] >= rangeWarning[0] &&
                        items[item] <= rangeWarning[1]
                      ? "#FCCE59"
                      : items[item] > ranngeDanger[0] &&
                        items[item] <= ranngeDanger[1]
                      ? "#ED3F3F"
                      : items[item] == 0 && "#ED3F3F"
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: "10px",
                    color: "black",
                    height: "75%",
                    opacity: "1"
                  }}
                >
                  {items[item]}
                </Text>
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

const PdfChecklistEmpleados = ({ data }) => {
  let folio = data?.idChecklistEmpleado
  let fecha = moment(data?.creado).format("L")
  let empresa = data?.datoschecklistsEmpleados?.empresas?.nombre
  let area = data?.datoschecklistsEmpleados?.areas?.nombre
  let economico = data?.datoschecklistsEmpleados?.carros?.economico
  let placas = data?.datoschecklistsEmpleados?.carros?.placas
  let seguro = data?.datoschecklistsEmpleados?.seguro
  let licencia = data?.datoschecklistsEmpleados?.licencia
  let tarjetaCirculacion = data?.datoschecklistsEmpleados?.tarjetaCirculacion

  let lucesDelanteras = JSON.parse(
    data?.detallechecklistsEmpleados?.lucesDelanteras
  )
  let liquidoVehiculo = JSON.parse(
    data?.detallechecklistsEmpleados?.liquidoVehiculo
  )
  let lucesTraseras = JSON.parse(
    data?.detallechecklistsEmpleados?.lucesTraseras
  )
  let carroceria = JSON.parse(data?.detallechecklistsEmpleados?.carroceria)
  let equipoSeguridad = JSON.parse(
    data?.detallechecklistsEmpleados?.equipoSeguridad
  )
  let frenos = JSON.parse(data?.detallechecklistsEmpleados?.frenos)
  let llantas = JSON.parse(data?.detallechecklistsEmpleados?.llantas)
  let bateria = JSON.parse(data?.detallechecklistsEmpleados?.baterias)
  let gasolinaTanque = data?.gasolinachecklistsEmpleados?.gasolina
  let gasolinaObservaciones = data?.gasolinachecklistsEmpleados?.observaciones
  let gasolinaKilometraje = data?.gasolinachecklistsEmpleados?.kilometraje
  let tipoAuto = data?.gasolinachecklistsEmpleados?.tipo
  let notas = data?.gasolinachecklistsEmpleados?.notas
  let archivosParaCheckEmpleados = []
  data?.checklistsarchivosEmpleados.forEach((check) => {
    archivosParaCheckEmpleados.push(check?.archivos?.url)
  })
  let nombreFirma = data?.firmachecklistsEmpleados?.empleadoEntrega
  let firma = data?.firmachecklistsEmpleados?.archivos?.url
  let tipoChecklist = data?.datoschecklists?.tipoChecklist

  return (
    <Document>
      <Page
        style={{
          padding: "10px"
        }}
      >
        {/* --------------------------------------- */}
        {/* CONTENEDOR PRINCIPAL*/}
        {/* --------------------------------------- */}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            height: "112px",
            width: "100%",
            marginTop: "10px"
          }}
        >
          {/* --------------------------------------- */}
          {/* PRIMERA LINEA */}
          {/* --------------------------------------- */}
          <div
            style={{
              height: "16px",
              width: "100%",
              backgroundColor: "#157CC1",
              borderTopRightRadius: "10px",
              borderTopLeftRadius: "10px",
              border: "1px solid #424141"
            }}
          ></div>
          {/* --------------------------------------- */}
          {/* SEGUNDA LINEA */}
          {/* --------------------------------------- */}
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "32px",
              flexDirection: "row",
              borderLeft: "1px solid #424141",
              borderRight: "1px solid #424141",
              borderBottom: "1px solid #424141"
            }}
          >
            {/* COLUMNA 1 */}
            <div
              style={{
                height: "32px",
                width: "30%",
                borderRight: "1px solid #424141"
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "40%",
                  width: "100%"
                }}
              >
                <div style={{ height: "100%", width: "90%" }}>
                  <Text
                    style={{
                      height: "13px",
                      width: "100%",
                      color: "black",
                      fontSize: "11px",
                      textAlign: "center"
                    }}
                  >
                    Tipo Checklist:
                  </Text>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "60%",
                  width: "100%"
                }}
              >
                <div style={{ height: "70%", width: "90%" }}>
                  <Text
                    style={{
                      height: "19px",
                      color: "#000000",
                      width: "100%",
                      fontSize: "10px",
                      textAlign: "center"
                    }}
                  >
                    {tipoChecklist}
                  </Text>
                </div>
              </div>
              {/* Aqui va el logo */}
            </div>
            {/* COLUMNA 2 */}
            <div
              style={{
                display: "flex",
                height: "32px",
                width: "30%",
                borderRight: "1px solid #424141"
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "16px",
                  borderBottom: "1px solid #424141"
                }}
              >
                <div
                  style={{
                    width: "90%",
                    height: "14px"
                  }}
                >
                  <Text
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "14px",
                      width: "100%",
                      color: "black",
                      fontSize: "12px",
                      textAlign: "center"
                    }}
                  >
                    HOJA: 1 DE 1
                  </Text>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "16px"
                }}
              >
                <div
                  style={{
                    width: "90%",
                    height: "14px"
                  }}
                >
                  <Text
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "14px",
                      width: "100%",
                      color: "black",
                      fontSize: "12px",
                      textAlign: "center"
                    }}
                  >
                    VERSIÓN: 1
                  </Text>
                </div>
              </div>
            </div>
            {/* COLUMNA 3 */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "20%",
                borderRight: "1px solid #424141"
              }}
            >
              <div
                style={{
                  width: "98%",
                  height: "80%"
                }}
              >
                <Text
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                    color: "black",
                    fontSize: "10px",
                    textAlign: "center"
                  }}
                >
                  DEPARTAMENTO DE GESTION VEHICULAR
                </Text>
              </div>
            </div>
            {/* COLUMNA 4 */}
            <div
              style={{
                height: "100%",
                width: "20%"
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "40%",
                  width: "100%"
                }}
              >
                <div style={{ height: "100%", width: "90%" }}>
                  <Text
                    style={{
                      height: "13px",
                      width: "100%",
                      color: "black",
                      fontSize: "11px",
                      textAlign: "center"
                    }}
                  >
                    Folio:
                  </Text>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "60%",
                  width: "100%"
                }}
              >
                <div style={{ height: "70%", width: "90%" }}>
                  <Text
                    style={{
                      height: "19px",
                      color: "#e71633",
                      width: "100%",
                      fontSize: "11px",
                      textAlign: "center"
                    }}
                  >
                    {folio}
                  </Text>
                </div>
              </div>
            </div>
          </div>
          {/* --------------------------------------- */}
          {/* TERCERA LINEA */}
          {/* --------------------------------------- */}
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "16px",
              flexDirection: "row",
              borderLeft: "1px solid #424141",
              borderRight: "1px solid #424141",
              borderBottom: "1px solid #424141"
            }}
          >
            <div
              style={{
                paddingLeft: "5px",
                display: "flex",
                justifyContent: "center",
                height: "100%",
                width: "30%"
              }}
            >
              <Text
                style={{
                  height: "11px",
                  width: "100%",
                  fontSize: "10px",
                  color: "black"
                }}
              >
                FECHA ELABORACIÓN: {fecha}
              </Text>
            </div>
            <div
              style={{
                paddingLeft: "5px",
                display: "flex",
                justifyContent: "center",
                height: "100%",
                width: "30%",
                borderRight: "1px solid #424141"
              }}
            >
              <Text
                style={{
                  height: "11px",
                  width: "100%",
                  fontSize: "10px",
                  color: "black"
                }}
              >
                FECHA REVISIÓN: {fecha}
              </Text>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                width: "40%"
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "100%",
                  width: "50%"
                }}
              >
                <Text
                  style={{
                    height: "11px",
                    width: "100%",
                    fontSize: "10px",
                    color: "black"
                  }}
                >
                  CODIGO: BD-GV-CHECK
                </Text>
              </div>
            </div>
          </div>
          {/* --------------------------------------- */}
          {/* CUARTA LINEA */}
          {/* --------------------------------------- */}
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "16px",
              flexDirection: "row",
              borderLeft: "1px solid #424141",
              borderRight: "1px solid #424141",
              borderBottom: "1px solid #424141"
            }}
          >
            <div
              style={{
                width: "60%",
                height: "100%",
                borderRight: "1px solid #424141"
              }}
            >
              <div
                style={{
                  paddingLeft: "5px",
                  display: "flex",
                  justifyContent: "center",
                  height: "100%",
                  width: "90%"
                }}
              >
                <Text
                  style={{
                    height: "11px",
                    width: "100%",
                    fontSize: "10px",
                    color: "black"
                  }}
                >
                  AREA DE TRABAJO: {area}
                </Text>
              </div>
            </div>
            <div
              style={{
                width: "40%",
                height: "100%"
              }}
            >
              <div
                style={{
                  paddingLeft: "5px",
                  display: "flex",
                  justifyContent: "center",
                  height: "100%",
                  width: "90%"
                }}
              >
                <Text
                  style={{
                    height: "11px",
                    width: "100%",
                    fontSize: "10px",
                    color: "black"
                  }}
                >
                  NÚMERO ECONÓMICO: {economico}
                </Text>
              </div>
            </div>
          </div>
          {/* --------------------------------------- */}
          {/* QUINTA LINEA */}
          {/* --------------------------------------- */}
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "16px",
              flexDirection: "row",
              borderLeft: "1px solid #424141",
              borderRight: "1px solid #424141",
              borderBottom: "1px solid #424141"
            }}
          >
            <div
              style={{
                width: "60%",
                height: "100%",
                borderRight: "1px solid #424141"
              }}
            >
              <div
                style={{
                  paddingLeft: "5px",
                  display: "flex",
                  justifyContent: "center",
                  height: "100%",
                  width: "90%"
                }}
              >
                <Text
                  style={{
                    height: "11px",
                    width: "100%",
                    fontSize: "10px",
                    color: "black"
                  }}
                >
                  EMPRESA: {empresa}
                </Text>
              </div>
            </div>
            <div
              style={{
                width: "40%",
                height: "100%"
              }}
            >
              <div
                style={{
                  paddingLeft: "5px",
                  display: "flex",
                  justifyContent: "center",
                  height: "100%",
                  width: "90%"
                }}
              >
                <Text
                  style={{
                    height: "11px",
                    width: "100%",
                    fontSize: "10px",
                    color: "black"
                  }}
                >
                  PLACAS: {placas}
                </Text>
              </div>
            </div>
          </div>
          {/* --------------------------------------- */}
          {/* SEXTA LINEA */}
          {/* --------------------------------------- */}
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "16px",
              borderLeft: "1px solid #424141",
              borderRight: "1px solid #424141",
              borderBottom: "1px solid #424141",
              flexDirection: "row"
            }}
          >
            <div
              style={{
                width: "60%",
                height: "100%",
                flexDirection: "row"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "40%",
                  height: "100%"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "50%",
                    height: "100%"
                  }}
                >
                  <Text
                    style={{
                      paddingLeft: "5px",
                      height: "11px",
                      width: "100%",
                      fontSize: "10px",
                      color: "black"
                    }}
                  >
                    SEGURO:
                  </Text>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "25%",
                    height: "100%",
                    backgroundColor: !seguro ? "#157CC1" : ""
                  }}
                >
                  <Text
                    style={{
                      textAlign: "center",
                      height: "11px",
                      width: "100%",
                      fontSize: "10px",
                      color: "black"
                    }}
                  >
                    SI
                  </Text>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "25%",
                    height: "100%",
                    backgroundColor: seguro ? "red" : ""
                  }}
                >
                  <Text
                    style={{
                      textAlign: "center",
                      height: "11px",
                      width: "100%",
                      fontSize: "10px",
                      color: "black"
                    }}
                  >
                    NO
                  </Text>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "60%",
                  height: "100%"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "70%",
                    height: "100%"
                  }}
                >
                  <Text
                    style={{
                      paddingLeft: "5px",
                      height: "11px",
                      width: "100%",
                      fontSize: "10px",
                      color: "black"
                    }}
                  >
                    TARJETA DE CIRCULACION:
                  </Text>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "15%",
                    height: "100%",
                    backgroundColor: !tarjetaCirculacion ? "#157CC1" : ""
                  }}
                >
                  <Text
                    style={{
                      textAlign: "center",
                      height: "11px",
                      width: "100%",
                      fontSize: "10px",
                      color: "black"
                    }}
                  >
                    SI
                  </Text>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "15%",
                    height: "100%",
                    backgroundColor: tarjetaCirculacion ? "red" : ""
                  }}
                >
                  <Text
                    style={{
                      textAlign: "center",
                      height: "11px",
                      width: "100%",
                      fontSize: "10px",
                      color: "black"
                    }}
                  >
                    NO
                  </Text>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "40%",
                height: "100%"
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "60%",
                  height: "100%"
                }}
              >
                <Text
                  style={{
                    paddingLeft: "5px",
                    height: "11px",
                    width: "100%",
                    fontSize: "10px",
                    color: "black"
                  }}
                >
                  LICENCIA VIGENTE:
                </Text>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "20%",
                  height: "100%",
                  backgroundColor: !licencia ? "#157CC1" : ""
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    height: "11px",
                    width: "100%",
                    fontSize: "10px",
                    color: "black"
                  }}
                >
                  SI
                </Text>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "20%",
                  height: "100%",
                  backgroundColor: licencia ? "red" : ""
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    height: "11px",
                    width: "100%",
                    fontSize: "10px",
                    color: "black"
                  }}
                >
                  NO
                </Text>
              </div>
            </div>
          </div>
        </div>
        {/* --------------------------------------- */}
        {/* CONTENEDOR SECUNDARIO*/}
        {/* --------------------------------------- */}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            height: "320px",
            width: "100%",
            marginTop: "10px"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              width: "378.66px",
              height: "320px"
            }}
          >
            <div
              style={{
                width: "184.33px",
                height: "112px",
                marginRight: "10px"
              }}
            >
              {checkTables(lucesDelanteras, "LUCES DELANTERAS")}
            </div>
            <div style={{ width: "184.33px", height: "112px" }}>
              {checkTables(liquidoVehiculo, "LÍQUIDOS DEL VEHÍCULO")}
            </div>
            <div
              style={{
                width: "184.33px",
                height: "176px",
                marginRight: "10px",
                marginTop: "16px"
              }}
            >
              {checkTables(lucesTraseras, "LUCES TRASERAS")}
            </div>
            <div
              style={{
                width: "184.33px",
                height: "176px",
                marginTop: "16px"
              }}
            >
              {checkTables(carroceria, "CARROCERÍA")}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              width: "194.33",
              height: "320px"
            }}
          >
            <div
              style={{
                width: "184.33px",
                height: "304px",
                marginLeft: "10px"
              }}
            >
              {checkTables(equipoSeguridad, "EQUIPO DE SEGURIDAD")}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            height: "272px",
            width: "100%",

            marginBottom:
              tipoAuto == "" && archivosParaCheckEmpleados.length <= 0
                ? "0px"
                : "95px"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "272px",
              width: "194.33px"
            }}
          >
            <div
              style={{
                width: "184.33px",
                height: "96px",
                marginBottom: "16px"
              }}
            >
              {checkTables(
                frenos,
                "FRENOS",
                true,
                "MIN-MAX",
                [0, 3],
                [4, 6],
                [7, 12]
              )}
            </div>
            <div
              style={{
                width: "184.33px",
                height: "96px",
                marginBottom: "16px"
              }}
            >
              {checkTables(
                llantas,
                "LLANTAS",
                true,
                "MIN-MAX",
                [0, 2],
                [3, 4],
                [5, 10]
              )}
            </div>
            <div style={{ width: "184.33px", height: "48px" }}>
              {checkTables(bateria, "BATERÍA")}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "272px",
              width: "378.66px"
            }}
          >
            <div style={{ height: "272px", width: "184.33px" }}>
              <div
                style={{
                  width: "100%",
                  height: "96px",
                  marginBottom: "16px"
                }}
              >
                {/* gasArea */}
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%"
                  }}
                >
                  {/* marker */}
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "5px",
                      bottom: "0",
                      transform: `rotate(${
                        (gasolinaTanque * 100) / 85 + 32
                      }deg)`
                    }}
                  >
                    {/* markerline */}
                    <div
                      style={{
                        width: "10%",
                        height: "5px",
                        backgroundColor: "red",
                        zIndex: "10"
                      }}
                    ></div>
                  </div>
                  {/* photoGasArea */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                      width: "100%",
                      height: "100%",
                      zIndex: "1"
                    }}
                  >
                    {/* areaPhoto */}
                    <div style={{ width: "100%", height: "100%" }}>
                      {/* photo */}
                      <Image
                        source={gasolinaDashboard}
                        style={{
                          width: "100%",
                          height: "100%",
                          zIndex: "1"
                        }}
                      ></Image>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "160px"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "378.66px",
                    height: "20%"
                  }}
                >
                  <Text
                    style={{
                      textAlign: "center",
                      height: "11px",
                      width: "90%",
                      height: "40%",
                      fontSize: "10px",
                      color: "black"
                    }}
                  >
                    OBSERVACIONES
                  </Text>
                </div>
                <div
                  style={{
                    width: "378.66px",
                    height: "80%"
                  }}
                >
                  <Text
                    style={{
                      height: "11px",
                      width: "90%",
                      height: "40%",
                      fontSize: "10px",
                      color: "black",
                      textAlign: "justify"
                    }}
                  >
                    {gasolinaObservaciones}
                  </Text>
                </div>
              </div>
            </div>
            {/* AREA DE KILOMETRAJE Y AUTOS */}
            <div style={{ height: "272px", width: "194.33px" }}>
              {/* APARTADO DE KILOMETRAJE */}
              <div
                style={{
                  width: "184.33px",
                  height: "96px",
                  marginLeft: "10px"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    height: "100%",
                    width: "100%"
                  }}
                >
                  <Text
                    style={{
                      textAlign: "center",
                      fontSize: "10px",
                      color: "black",
                      height: "28%"
                    }}
                  >
                    {gasolinaKilometraje}
                  </Text>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "40%",
                    width: "100%",
                    borderTop: "1px solid black"
                  }}
                >
                  <Text
                    style={{
                      textAlign: "center",
                      fontSize: "10px",
                      color: "red",
                      height: "80%"
                    }}
                  >
                    KILOMETRAJE:
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
        {tipoAuto === "" && archivosParaCheckEmpleados.length <= 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "58px",
              width: "100%",
              marginTop: "10px",
              borderBottom: "1px solid black"
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "20%",
                height: "100%"
              }}
            >
              <Text
                style={{ height: "14px", fontSize: "12px", color: "black" }}
              >
                Fecha: {fecha}
              </Text>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "40%",
                height: "100%"
              }}
            >
              <Text
                style={{ height: "14px", fontSize: "12px", color: "black" }}
              >
                Nombre: {nombreFirma}
              </Text>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
                width: "40%",
                height: "100%"
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  height: "14px",
                  width: "15%",
                  fontSize: "12px",
                  color: "black"
                }}
              >
                Firma:
              </Text>
              <div style={{ width: "85%", height: "100%" }}>
                <Image style={{ width: "100%", height: "100%" }} src={firma} />
              </div>
            </div>
          </div>
        )}
      </Page>
      {/* --------------------------------------- */}
      {/* CONTENEDOR FIRMA*/}
      {/* --------------------------------------- */}
      {/* APARTADO DE IMAGEN DEL VEHICULO */}

      {(tipoAuto != "" || archivosParaCheckEmpleados.length > 0) && (
        <Page
          style={{
            padding: "10px"
          }}
        >
          {tipoAuto != "" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                width: "100%",
                marginTop: "2px"
              }}
            >
              <div
                style={{
                  width: "323px"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "17px",
                    width: "95%",
                    backgroundColor: "#157CC1",
                    borderTopRightRadius: "5px",
                    borderTopLeftRadius: "5px",
                    border: "1px solid black"
                  }}
                >
                  <Text
                    style={{
                      textAlign: "center",
                      height: "90%",
                      fontSize: "12px",
                      color: "black"
                    }}
                  >
                    NOTAS DE INSPECCIÓN
                  </Text>
                </div>
                {notas.map((item, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        display: "flex",
                        width: "95%",
                        borderBottom: "1px solid black",
                        borderLeft: "1px solid black",
                        borderRight: "1px solid black"
                      }}
                    >
                      <Text
                        style={{
                          paddingLeft: "10px",
                          fontSize: "12px",
                          color: "black"
                        }}
                      >
                        {i + 1}-. {item.nota}
                      </Text>
                    </div>
                  )
                })}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "250px",
                  height: "250px"
                }}
              >
                <div
                  style={{
                    width: "250px",
                    height: "200px",
                    position: "relative"
                  }}
                >
                  <Image
                    source={
                      tipoAuto == "pickup"
                        ? pickupPlanos
                        : tipoAuto === "sedan"
                        ? sedanPlanos
                        : tipoAuto === "grua"
                        ? gruaPlanos
                        : tipoAuto === "tonelada" && toneladaPlanos
                    }
                    style={{
                      width: "100%",
                      height: "100%",
                      zIndex: "1"
                    }}
                  ></Image>
                  {notas.map((nota, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          left: nota.x * 0.5,
                          top: nota.y * 0.5,
                          width: "10px",
                          height: "10x",
                          borderRadius: "100%",
                          position: "absolute",
                          backgroundColor: "#157CC1"
                        }}
                      >
                        <Text
                          style={{
                            textAlign: "center",
                            height: "90%",
                            width: "100%",
                            color: "#FFFFFF",
                            fontSize: "8px"
                          }}
                        >
                          {i + 1}
                        </Text>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )}
          {archivosParaCheckEmpleados.length > 0 && (
            <div
              style={{
                width: "100%",
                marginTop: "10px"
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "20px",
                  backgroundColor: "#157CC1",
                  borderTopRightRadius: "5px",
                  borderTopLeftRadius: "5px",
                  border: "1px solid black"
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    height: "80%",
                    fontSize: "12px",
                    color: "black"
                  }}
                >
                  EVIDENCIAS
                </Text>
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  width: "100%",
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  borderBottom: "1px solid black"
                }}
              >
                {archivosParaCheckEmpleados.map((archivo, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "33.33%",
                        height: "160px"
                      }}
                    >
                      <div
                        style={{
                          height: "90%",
                          width: "90%"
                        }}
                      >
                        <Image
                          style={{ width: "100%", height: "100%" }}
                          src={archivo}
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "58px",
              width: "100%",
              marginTop: "10px",
              borderBottom: "1px solid black"
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "20%",
                height: "100%"
              }}
            >
              <Text
                style={{ height: "14px", fontSize: "12px", color: "black" }}
              >
                Fecha: {fecha}
              </Text>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "40%",
                height: "100%"
              }}
            >
              <Text
                style={{ height: "14px", fontSize: "12px", color: "black" }}
              >
                Nombre: {nombreFirma}
              </Text>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
                width: "40%",
                height: "100%"
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  height: "14px",
                  width: "15%",
                  fontSize: "12px",
                  color: "black"
                }}
              >
                Firma:
              </Text>
              <div style={{ width: "85%", height: "100%" }}>
                <Image style={{ width: "100%", height: "100%" }} src={firma} />
              </div>
            </div>
          </div>
        </Page>
      )}
    </Document>
  )
}

const blobToExport = async (data) => {
  return await pdf(<PdfChecklistEmpleados data={data} />).toBlob()
}

export default blobToExport
