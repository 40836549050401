import React from "react"
import logo from "../images/logo.png"
import { FaFilePdf } from "react-icons/fa"
import { IconButton } from "@mui/material"
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Image, // Añadir para el enlace de descarga
  Font
} from "@react-pdf/renderer"

Font.register({
  family: "Arial",
  src: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/fonts/fontawesome-webfont.ttf"
})
const CarTablePDF = ({ data, dataPdf }) => {
  if (data.length === 0) {
    return null
  }

  const keys = Object.keys(dataPdf)

  // Función para crear el documento PDF
  const MyDocument = (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          {/* Encabezado con el logo */}
          <View style={styles.header}>
            <Image src={logo} style={styles.logo} />
          </View>
          <Text style={styles.titulo}>DATOS EXPORTADOS</Text>
          {/* Cabecera de la tabla */}
          <View style={styles.table}>
            <View style={styles.tableRow}>
              {keys.map((key, index) => (
                <View style={styles.tableCol} key={index}>
                  <Text style={styles.tableCell}>{dataPdf[key].nombre}</Text>
                </View>
              ))}
            </View>
            {/* Filas dinámicas basadas en los datos */}
            {data.map((item, index) => (
              <View style={styles.tableRow} key={index}>
                {keys.map((key, i) => (
                  <View style={styles.tableCol} key={i}>
                    <Text style={styles.tableCell}>
                      {dataPdf[key].content(item)}
                    </Text>
                  </View>
                ))}
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  )

  //Estilo para el icono
  return (
    <PDFDownloadLink document={MyDocument} fileName="Datos_Exportados.pdf">
      {({ loading }) => (
        <IconButton
          sx={{
            color: "red",
            backgroundColor: "transparent",
            borderRadius: "10px",
            height: { xs: "35px", sm: "40px", md: "45px", lg: "50px" },
            width: { xs: "35px", sm: "40px", md: "45px", lg: "50px" },
            "@media (max-width: 1024px)": {
              marginLeft: "50px"
            },
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.1)"
            },
            display: "block"
          }}
        >
          <FaFilePdf />
        </IconButton>
      )}
    </PDFDownloadLink>
  )
}

// Estilos del PDF
const styles = StyleSheet.create({
  page: {
    fontFamily: "Arial",
    padding: 20,
    backgroundColor: "#fff"
  },
  container: {
    width: "100%",
    backgroundColor: "#fff"
  },
  titulo: {
    textAlign: "center",
    fontSize: 18,
    marginBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: "#000"
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000"
  },
  tableRow: {
    flexDirection: "row"
  },
  tableCol: {
    flex: 1,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5
  },
  tableCell: {
    fontSize: 10
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20
  },
  logo: {
    width: 150,
    height: 35
  }
})

export default CarTablePDF
