import React from "react"
import logo from "../images/logo.png"
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
  pdf
} from "@react-pdf/renderer"

// Registro de la fuente Arial
Font.register({
  family: "Arial",
  src: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/fonts/fontawesome-webfont.ttf"
})

// Definición de estilos
const styles = StyleSheet.create({
  // Página
  page: {
    fontFamily: "Arial",
    padding: 20,
    backgroundColor: "#fff"
  },
  //Contenedor
  container: {
    width: "100%",
    backgroundColor: "#fff"
  },
  text: {
    textAling: "right",
    fontSize: 12,
    marginBottom: 15
  },
  table: {
    display: "table",
    width: "auto",
    marginBottom: 20,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000"
  },
  tableRow1: {
    flexDirection: "row",
    backgroundColor: "#ffffff"
  },
  tableCol: {
    flex: 1,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5
  },
  tableColLine: {
    border: "1px solid black",
    flexDirection: "column"
  },
  tableCell: {
    fontSize: 10,
    fontWeight: "ultrabold"
  },
  tableLetter: {
    fontSize: 10,
    alignItems: "center",
    justifyContent: "center",
    display: "flex"
  },
  logo: {
    width: "120px",
    height: "30px"
  },
  letterSize: {
    fontSize: 10,
    fontWeight: "light"
  },
  letterSiz32: {
    fontSize: 10,
    fontWeight: "light",
    marginLeft: "10.5%"
  },

  letterSiz33: {
    fontSize: 10,
    fontWeight: "light",
    marginLeft: "10.5%",
    marginTop: "-67px"
  },

  marginTextt: {
    marginTop: "5px"
  },
  marginLetterCorrespond: {
    marginTop: "15px"
  },
  margenBeforeTable: {
    marginTop: "10px"
  },
  marginLetterText: {
    marginTop: "20px"
  },
  marginLetterText4: {
    marginTop: "50px"
  },
  marginLetterText5: {
    marginTop: "50px",
    marginLeft: "55%"
  },
  marginLetterText2: {
    marginTop: "5px"
  },

  table: {
    display: "table",
    width: "100%",
    marginBottom: 20,
    borderCollapse: "collapse",
    borderBottom: "1px solid black"
  },
  tableRow: {
    flexDirection: "row",
    width: "100%"
  },
  tableCol: {
    flex: 1,
    border: "1px solid black",
    padding: 5
  },
  lines: {
    borderBottom: " 1px solid black",
    fontSize: 10,
    textAlign: "left",
    width: "101%",
    flexDirection: "row",
    display: "flex"
  },
  margen: {
    marginLeft: "15px"
  },
  firma1: {
    width: "27%",
    borderBottom: "1px solid black",
    marginLeft: "10.5%"
  },
  firma2: {
    width: "60%",
    marginLeft: "10.5%",
    marginTop: "-68px",
    borderBottom: "1px solid black"
  },
  marginLeftT: {
    marginLeft: "80%"
  }
})

const PdfCart = ({ data }) => {
  let area = data?.datoschecklists?.areas?.nombre
  let empresa = data?.datoschecklists?.empresas?.nombre
  let economico = data?.datoschecklists?.carros?.economico
  let placas = data?.datoschecklists?.carros?.placas
  let modelo = data?.datoschecklists?.carros?.modelo
  let year = data?.datoschecklists?.carros?.year
  let vin = data?.datoschecklists?.carros?.vin
  let asignado = data?.datoschecklists?.asignado

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          {/* Cabecera de la tabla de la carta responsiva*/}
          <View style={styles.table}>
            <View style={styles.tableRow1}>
              <View style={styles.tableCol}>
                <Image source={logo} style={styles.logo} />
                <Text style={styles.tableCell} styles={styles.logo}></Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableLetter}>
                  CARTA RESPONSIVA DE VEHICULOS LIGEROS
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>PROYECTO: {area}</Text>
              </View>
            </View>
          </View>
          <View style={styles.marginLetterCorrespond}>
            <Text style={styles.letterSize}>A quien corresponda: </Text>
          </View>
          <View style={styles.marginLetterText}>
            <Text style={styles.letterSize}>
              Por medio de la presente {empresa}, y ZONDA BUS hacen entrega de
              carta responsiva a: {asignado} de vehículo ligero para uso
              exclusivo de labores de la empresa en proyecto {area}. El
              trabajador se compromete a utilizarlo de manera responsable y a
              mantenerlo en buen funcionamiento.
            </Text>
          </View>
          <View style={styles.marginLetterCorrespond}>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.lines}>No.Economico: {economico}</Text>
                  <Text style={styles.lines}>Modelo: {modelo}</Text>
                  <Text style={styles.lines}>Año: {year}</Text>
                  <Text style={styles.lines}>Placas: {placas}</Text>
                  <Text style={styles.letterSize}>Número de serie: {vin}</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.marginLetterCorrespond}>
            <Text style={styles.letterSize}>
              El USUARIO DEBERA CUMPLIR CON LAS SIGUIENTES OBLIGACIONES:
            </Text>
          </View>
          <View style={styles.margenBeforeTable}>
            <View style={styles.margen}>
              <View style={styles.marginTextt}>
                <Text style={styles.letterSize}>
                  1.- El usuario del vehículo debe realizar inspecciones de
                  seguridad para asegurarse de que el vehículo esté en buenas
                  condiciones de funcionamiento.
                </Text>
              </View>
              <View style={styles.marginTextt}>
                <Text style={styles.letterSize}>
                  2.- El usuario es responsable de entregar todos los elementos
                  de seguridad asignados en el vehículo(calza, porta calzas,
                  banderola y pértiga, extintor, varillaje, gato botiquín
                  primeros auxilios, triángulos de seguridad, torreta, luces
                  auxiliares).
                </Text>
              </View>
              <View style={styles.marginTextt}>
                <Text style={styles.letterSize}>
                  3.- El usuario del vehículo deberá reportar si necestia alguna
                  reparación por medio de check list actualizados con imágenes o
                  videos al departamento correspondiente y con su jefe
                  inmediato.
                </Text>
              </View>
              <View style={styles.marginTextt}>
                <Text style={styles.letterSize}>
                  4.- El usuario del vehículo tiene la obligación del llenado
                  correcto y diario del check list.
                </Text>
              </View>
              <View style={styles.marginTextt}>
                <Text style={styles.letterSize}>
                  5.- El usuario del vehículo debe utilizarlo exclusivamente
                  para los fines autorizados y respetar el límite de carga con
                  el que cuenta cada vehículo.
                </Text>
              </View>
              <View style={styles.marginTextt}>
                <Text style={styles.letterSize}>
                  6.- El usuario del vehículo se compromete a no permitir que
                  terceros lo operen sin la debida autorización.
                </Text>
              </View>
              <View style={styles.marginTextt}>
                <Text style={styles.letterSize}>
                  7.- Reportar cualquier daño o anomanlía del vehículo al
                  departamento de mantenimiento y gestión vehicular.
                </Text>
              </View>
              <View style={styles.marginTextt}>
                <Text style={styles.letterSize}>
                  8.- El usuario del vehículo deberá entregar el vehículo en las
                  condiciones excelentes, salvo el desgaste normal.
                </Text>
              </View>
              <View style={styles.marginTextt}>
                <Text style={styles.letterSize}>
                  9.- El usuario del vehículo no podrá hacerle modificaciones al
                  vehículo sin atutorización.
                </Text>
              </View>
              <View style={styles.marginTextt}>
                <Text style={styles.letterSize}>
                  10.- El usuario del vehículo se compromete a cumplir con las
                  leyes de tránsito y reglas internas del proyecto.
                </Text>
              </View>
              <View style={styles.marginTextt}>
                <Text style={styles.letterSize}>
                  11.- El usuario del vehículo será responsable de cualquier
                  daño que ocurra al vehículo mientras esté en su posesión. Es
                  importante inspeccionar el vehículo antes de hacer uso y
                  documentar cualquier daño existente.
                </Text>
              </View>
              <View style={styles.marginTextt}>
                <Text style={styles.letterSize}>
                  12.- Se pondrá en investigación los daños ocasionados a la
                  unidad para verificar si fue por desgaste natural o mal uso
                  del operador.
                </Text>
              </View>
            </View>
            <View style={styles.marginLetterText}>
              <View style={styles.marginTextt}>
                <Text style={styles.letterSize}>
                  En caso de incumplimiento de las obligaciones, la gestión
                  vehicular de Zonda, seguridad y otros departamentos podrá
                  tomar diferentes medidas de acción:
                </Text>
              </View>
              <View style={styles.margen}>
                <View style={styles.marginTextt}>
                  <Text style={styles.letterSize}>
                    1.- En caso de daños al vehículo se realizará una
                    investigación para consolidar responsabilidades y aplicar
                    acciones correctivas.
                  </Text>
                </View>
                <View style={styles.marginTextt}>
                  <Text style={styles.letterSize}>
                    2.- Si el vehículo se usa fuera del horario laboral y no
                    tiene autorización de la asignación del vehículo será
                    acreedor de un acta administrativa.
                  </Text>
                </View>
                <View style={styles.marginTextt}>
                  <Text style={styles.letterSize}>
                    3.- Se le podrá retirar temporal o permanentemente su
                    derecho a usar el vehículo asignado.
                  </Text>
                </View>
                <View style={styles.marginTextt}>
                  <Text style={styles.letterSize}>
                    4.- El vehículo puede ser retirado del proyecto sin previo
                    aviso.
                  </Text>
                </View>
                <View style={styles.marginTextt}>
                  <Text style={styles.letterSize}>
                    5.- En caso de daños al vehículo y si la unidad no contará
                    con la información de check list reportando las fallas, la
                    última persona que uso la unidad será la responsable.
                  </Text>
                </View>
              </View>
              <View style={styles.marginLetterText}>
                <View style={styles.marginTextt}>
                  <Text style={styles.letterSize}>
                    El departamento de gestión vehicular tiene la obligación de
                    mantenerlo actualizado respecto a su responsabilidad
                    vehícular asignada, ya que esta puede estar en
                    modificaciones constantes. El usuario tiene la obligación de
                    comunicar si hubo algun cambio respecto a unidades en uso
                    del proyecto.
                  </Text>
                </View>
              </View>
              <View style={styles.marginLetterText}>
                <View style={styles.marginTextt}>
                  <Text style={styles.letterSize}>
                    Por lo anterior, declarar haber leído y entendido las
                    obligaciones y condiciones establecidas en este documento,
                    comprometiéndose a cumplir con lo aquí descrito.
                  </Text>
                </View>
              </View>
              <View style={styles.marginLetterText4}>
                <View style={styles.marginTextt}>
                  <Text style={styles.letterSize}>Atentamente: {}</Text>
                </View>
              </View>
              <View style={styles.marginLetterText4}>
                <View style={styles.marginTextt}>
                  <Text style={styles.firma1}></Text>
                </View>
                <Text style={styles.letterSiz32}>GESTION VEHICULAR ZONDA</Text>
              </View>
              <View style={styles.marginLetterText5}>
                <View style={styles.marginTextt}>
                  <Text style={styles.firma2}></Text>
                </View>
                <Text style={styles.letterSiz33}>ENCARGADO DE PROYECTO</Text>
              </View>
              <View style={styles.marginLetterText5}>
                <View style={styles.marginTextt}>
                  <Text style={styles.firma2}></Text>
                </View>
                <Text style={styles.letterSiz33}>
                  USUARIO ASIGNADO A UNIDAD
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}
const blobToExport = async (data) => {
  return await pdf(<PdfCart data={data} />).toBlob()
}
export default blobToExport
