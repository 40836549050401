import React, { useState } from "react";
import TableCustom from "../../components/TableCustom";
import _ from "lodash";
import Input from "../../components/Input";
import { Grid } from "@mui/material";
import Vista from "../../components/Vista";
import dateComponent from "../../components/dateComponent";

const busquedaDefault = {
  ubicacion: "",
  fechaInicio: dateComponent.getFirstDayOfYear(),
  fechaFinal: dateComponent.getActualDay(),
};

let dataTable = {
  nombre: {
    nombre: "Nombre",
    content: (e) => {
      return e.nombre;
    },
  },
  encargado: {
    nombre: "Encargado Proyecto",
    content: (e) => {
      return e.encargado;
    },
  },
  correo: {
    nombre: "Correo Electrónico",
    content: (e) => {
      return e.correo;
    },
  },
  telefono: {
    nombre: "Número de Teléfono",
    content: (e) => {
      return e.telefono;
    },
  },
  ubicacion: {
    nombre: "Ubicación",
    content: (e) => {
      return e.ubicacion;
    },
  },
};

//PDF
let dataPdf = {
  nombre: {
    nombre: "Nombre",
    content: (e) => {
      return e.nombre;
    },
  },
  encargado: {
    nombre: "Encargado Proyecto",
    content: (e) => {
      return e.encargado;
    },
  },
  correo: {
    nombre: "Correo Electrónico",
    content: (e) => {
      return e.correo;
    },
  },
  telefono: {
    nombre: "Número de Teléfono",
    content: (e) => {
      return e.telefono;
    },
  },
  ubicacion: {
    nombre: "Ubicación",
    content: (e) => {
      return e.ubicacion;
    },
  },
};

const Area = ({ onClick, onKeyDown, ...props }) => {
  const [busqueda, setBusqueda] = useState({
    ...busquedaDefault,
  });

  return (
    <Vista>
      <TableCustom
        tipo={"idArea"}
        busqueda={busqueda}
        setBusqueda={setBusqueda}
        dataTable={dataTable}
        dataPdf={dataPdf}
        buscador={[
          <Grid item xs={10} sm={8} sx={{ marginRight: "10px", height:"41px" }} key={1}>
            <Input
              value={busqueda?.ubicacion}
              placeholder={"Ubicación"}
              typeInput={"onlyInput"}
              nombreBusqueda="ubicacion"
              onChange={(e) =>
                setBusqueda({ ...busqueda, ubicacion: e.target.value })
              }
            />
          </Grid>,
        ]}
        permisoCheck={"areas"}
        nombreBusqueda={"nombre"}
      />
    </Vista>
  );
};

export default Area;
