import React, { useEffect } from "react"
import Vista from "../../components/Vista"
import TableCustom from "../../components/TableCustom"
import dateComponent from "../../components/dateComponent"
import { useState } from "react"
import { Grid } from "@mui/material"
import Input from "../../components/Input"
import { InventarioStyles } from "../../utils/styles"
import moment from "moment"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"
import pdfOrdenServicio from "../../pdfs/pdfOrdenServicio"
const busquedaDefault = {
  status: "",
  fechaInicio: dateComponent.getFirstDayOfYear(),
  fechaFinal: dateComponent.getActualDay()
}

let VS = InventarioStyles()
const OrdenServicio = () => {
  const [busqueda, setBusqueda] = useState({ ...busquedaDefault })

  let dataTable = {
    creado: {
      nombre: "Fecha",
      content: (e) => {
        return moment(e?.creado).format("L")
      }
    },
    empresa: {
      nombre: "Empresa",
      content: (e) => {
        return e?.empresas?.nombre
      }
    },
    status: {
      nombre: "Status",
      content: (e) => {
        return e?.status
      }
    },
    pdf: {
      nombre: "PDF",
      content: (item) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%"
            }}
          >
            <PictureAsPdfIcon
              style={{ color: "#157CC1", cursor: "pointer" }}
              onClick={async (e) => {
                try {
                  let pdf = await pdfOrdenServicio(item)
                  window.open(window.URL.createObjectURL(pdf), "_blank")
                } catch (error) {
                  console.error("Error al generar el PDF:", error)
                }
              }}
            ></PictureAsPdfIcon>
          </div>
        )
      }
    }
  }
  let dataPdf = {
    creado: {
      nombre: "Fecha",
      content: (e) => {
        return moment(e?.creado).format("L")
      }
    },
    empresa: {
      nombre: "Empresa",
      content: (e) => {
        return e?.empresas?.nombre
      }
    },
    status: {
      nombre: "Status",
      content: (e) => {
        return e?.status
      }
    }
  }

  return (
    <Vista>
      <TableCustom
        filtroCustom={(d) => {
          if (d?.eliminado) {
            return false
          }
          if (busqueda.status && d?.status !== busqueda.status) {
            return false
          }
          return true
        }}
        tipo={"idOrdenServicio"}
        busqueda={busqueda}
        setBusqueda={setBusqueda}
        dataTable={dataTable}
        dataPdf={dataPdf}
        buscador={[
          <Grid
            sx={{
              order: "2",
              padding: "0 5px 0 5px",
              marginTop: "10px",
              "@media (min-width: 900px)": {
                marginTop: "0px",
                marginBottom: "10px",
                order: "0"
              }
            }}
            item
            xs={12}
            key={1}
          >
            <Input
              typeInput={"formSelect"}
              valor={busqueda?.status}
              nombre="Status de la orden de servicio"
              onChange={(e) =>
                setBusqueda({ ...busqueda, status: e.target.value })
              }
              data={[
                "PENDIENTE",
                "DIAGNOSTICO",
                "COTIZADO",
                "PROCESO",
                "CERRADO",
                "FACTURADO",
                "CANCELADO",
                "FACT./CANCELADA"
              ]}
            />
          </Grid>
        ]}
        expand={
          "checklists,empresas.nombre," +
          "checklists.datoschecklists.areas," +
          "checklists.datoschecklists.carros," +
          "checklists.datoschecklists.empresas," +
          "checklists.gasolinachecklists.notas," +
          "observaciones," +
          "manosObraOrdenServicio.manosObra," +
          "refacciones.productos," +
          "diagnosticos.productos.lote," +
          "ordenesCompra.ordenesCompraRefacciones.productos," +
          "ordenesCompra.ordenesCompraRefacciones.proveedores," +
          "checklists.gasolinachecklists"
        }
        permisoCheck={"ordenServicios"}
        nombreBusqueda={"status"}
      />
    </Vista>
  )
}
export default OrdenServicio
