import React from "react"
import { Grid } from "@mui/material"
import { ChecklistStyles } from "../../utils/styles"
import SwitchYN from "../../components/SwitchYN"
import AutocompleteDeluxe from "../../components/AutocompleteDelux"
import { useLocalStorage } from "../../hooks/useLocalStorage"
import Input from "../../components/Input"
import Multifile from "../../components/Multifile"

let vs = ChecklistStyles()
const DatosGenerales = ({
  formDetails,
  setformDetails,
  area,
  setArea,
  empresa,
  setEmpresa,
  carro,
  setCarro,
  ubicacion,
  setUbicacion,
  tipoCheklist,
  setTipoCheklist
}) => {
  let ChecklistStyles = vs()

  const permisoCheck = "checklistsEmpleados"
  const [permiso] = useLocalStorage("permiso", null)

  return (
    <div className={ChecklistStyles.componentContainer}>
      <div className={ChecklistStyles.internContainer}>
        <Grid container direction="row" columns={{ xs: 12, sm: 12, md: 12 }}>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            className={ChecklistStyles.inputArea}
          >
            <AutocompleteDeluxe
              labelToShow="Carros"
              labelProp="economico"
              nombre="carros"
              value={carro}
              setDato={setCarro}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            className={ChecklistStyles.inputArea}
          >
            <AutocompleteDeluxe
              labelToShow="Empresa"
              labelProp="nombre"
              nombre="empresas"
              value={empresa}
              setDato={setEmpresa}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            className={ChecklistStyles.inputArea}
          >
            <AutocompleteDeluxe
              labelToShow="Áreas de trabajo"
              labelProp="nombre"
              nombre="areas"
              value={area}
              setDato={setArea}
            />
          </Grid>
          <Grid
            item
            xs={12}
            smn={6}
            md={4}
            className={ChecklistStyles.inputArea}
          >
            <Input
              typeInput={"formSelect"}
              valor={ubicacion}
              placeholder="Ubicacion del carro"
              nombre="Ubicacion"
              onChange={(e) => {
                setUbicacion(e.target.value)
              }}
              data={[
                "TALLER ZONDA BUS",
                "MULATOS",
                "TALLER BYLSA",
                "LAS CHISPAS"
              ]}
            />
          </Grid>
          <Grid
            item
            xs={12}
            smn={6}
            md={4}
            className={ChecklistStyles.inputArea}
          >
            <Input
              typeInput={"formSelect"}
              valor={tipoCheklist}
              placeholder="tipo de checklist"
              nombre="Recepcion"
              onChange={(e) => {
                setTipoCheklist(e.target.value)
              }}
              data={["INGRESO", "ENTREGA"]}
            />
          </Grid>
          {/* Aqui falta el asignado */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            className={ChecklistStyles.inputArea}
          >
            <div className={ChecklistStyles.seguroContainer}>
              <div className={ChecklistStyles.itemsSeguro}>
                <p className={ChecklistStyles.font}>Seguro Social Activo</p>
                <SwitchYN
                  value={formDetails?.seguro}
                  onChange={(e) => {
                    setformDetails({
                      ...formDetails,
                      seguro: e.target.checked
                    })
                  }}
                />
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            className={ChecklistStyles.inputArea}
          >
            <div className={ChecklistStyles.seguroContainer}>
              <div className={ChecklistStyles.itemsSeguro}>
                <p className={ChecklistStyles.font}>Tarjeta de Circulación</p>
                <SwitchYN
                  value={formDetails?.tarjetaCirculacion}
                  onChange={(e) => {
                    setformDetails({
                      ...formDetails,
                      tarjetaCirculacion: e.target.checked
                    })
                  }}
                />
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            className={ChecklistStyles.inputArea}
          >
            <div className={ChecklistStyles.seguroContainer}>
              <div className={ChecklistStyles.itemsSeguro}>
                <p className={ChecklistStyles.font}>Lincencia Vigente</p>
                <SwitchYN
                  value={formDetails?.licencia}
                  onChange={(e) => {
                    setformDetails({
                      ...formDetails,
                      licencia: e.target.checked
                    })
                  }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
export default DatosGenerales
