import React from "react"
import Vista from "../../components/Vista"
import TableCustom from "../../components/TableCustom"
import dateComponent from "../../components/dateComponent"
import { useState } from "react"
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material"
import { useModal } from "../../hooks/useModal"
import Input from "../../components/Input"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import { InventarioStyles } from "../../utils/styles"
import moment from "moment"

const busquedaDefault = {
  operacion: "",
  fechaInicio: dateComponent.getFirstDayOfYear(),
  fechaFinal: dateComponent.getActualDay()
}

let VS = InventarioStyles()
const Inventario = () => {
  const [busqueda, setBusqueda] = useState({ ...busquedaDefault })
  const { setContainerModal } = useModal()
  let InventarioStyles = VS()

  const openModal = (data) => {
    let container = (
      <>
        <Table>
          <TableHead>
            <TableRow className={InventarioStyles?.tableHead}>
              <TableCell className="headColor">Nombre del Producto</TableCell>
              <TableCell className="headColor">Cantidad</TableCell>
              <TableCell className="headColor">Total del Movimiento</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.movimientos?.map((item, i) => {
              return (
                <TableRow className={InventarioStyles?.tableRow} key={i}>
                  <TableCell className="rowColor">
                    {item?.lote?.nombreProducto}
                  </TableCell>
                  <TableCell className="rowColor">{item?.cantidad}</TableCell>
                  <TableCell className="rowColor">
                    {item?.totalProveedor}
                  </TableCell>
                </TableRow>
              )
            })}
            <TableRow className={InventarioStyles?.tableRow}>
              <TableCell className="totalRowColor">TOTALES</TableCell>
              <TableCell className="totalRowColor">
                {data?.movimientos?.reduce((previous, current) => {
                  return (previous += current.cantidad)
                }, 0)}
              </TableCell>
              <TableCell className="totalRowColor">
                {data?.movimientos?.reduce((previous, current) => {
                  return (previous += parseInt(current?.totalProveedor))
                }, 0)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </>
    )
    setContainerModal(container)
  }

  let dataTable = {
    creado: {
      nombre: "Fecha",
      content: (e) => {
        return moment(e?.creado).format("L")
      }
    },
    operacion: {
      nombre: "Operación",
      content: (e) => {
        return e?.operacion
      }
    },
    empresa: {
      nombre: "Empresa",
      content: (e) => {
        return e?.empresas?.nombre
      }
    },
    comentario: {
      nombre: "Comentario",
      content: (e) => {
        return e?.comentario
      }
    },
    cantidad: {
      nombre: "Cantidad",
      content: (e) => {
        const res = e?.movimientos?.reduce((previous, current) => {
          return (previous += current.cantidad)
        }, 0)
        return res
      }
    },
    total: {
      nombre: "Total",
      content: (e) => {
        const res = e?.movimientos?.reduce((previous, current) => {
          return (previous += parseInt(current?.totalProveedor))
        }, 0)
        return res
      }
    },
    verMas: {
      nombre: "Ver Mas",
      content: (row) => {
        return (
          <IconButton onClick={(e) => openModal(row)}>
            <MoreHorizIcon />
          </IconButton>
        )
      }
    }
  }
  let dataPdf = {
    creado: {
      nombre: "Fecha",
      content: (e) => {
        return moment(e?.creado).format("L")
      }
    },
    operacion: {
      nombre: "Operación",
      content: (e) => {
        return e?.operacion
      }
    },
    empresa: {
      nombre: "Empresa",
      content: (e) => {
        return e?.empresas?.nombre
      }
    },
    comentario: {
      nombre: "Comentario",
      content: (e) => {
        return e?.comentario
      }
    },
    cantidad: {
      nombre: "Cantidad",
      content: (e) => {
        const res = e?.movimientos?.reduce((previous, current) => {
          return (previous += current.cantidad)
        }, 0)
        return res
      }
    },
    total: {
      nombre: "Total",
      content: (e) => {
        const res = e?.movimientos?.reduce((previous, current) => {
          return (previous += parseInt(current?.totalProveedor))
        }, 0)
        return res
      }
    }
  }

  return (
    <Vista>
      <TableCustom
        filtroCustom={(d) => {
          if (d?.operacion == busqueda?.operacion) {
            return d?.operacion === busqueda?.operacion
          }
          if (busqueda?.operacion == "") {
            return d
          }
          if (busqueda?.operacion == "Todos") {
            return d
          }
        }}
        tipo={"idInventario"}
        Actions={false}
        seeDisabledOption={false}
        busqueda={busqueda}
        setBusqueda={setBusqueda}
        dataTable={dataTable}
        dataPdf={dataPdf}
        buscador={[
          <Grid
            sx={{
              order: "2",
              padding: "0 5px 0 5px",
              marginTop: "10px",
              "@media (min-width: 900px)": {
                marginTop: "0px",
                marginBottom: "10px",
                order: "0"
              }
            }}
            item
            xs={12}
            key={1}
          >
            <Input
              typeInput={"formSelect"}
              valor={busqueda?.operacion}
              nombre="Operacíon"
              onChange={(e) =>
                setBusqueda({ ...busqueda, operacion: e.target.value })
              }
              data={["Todos", "Ingreso", "Salidas"]}
            />
          </Grid>
        ]}
        expand={"movimientos.lote,empresas"}
        permisoCheck={"inventarios"}
        nombreBusqueda={"inventarios"}
      />
    </Vista>
  )
}
export default Inventario
