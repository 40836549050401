import {
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material"
import React, { useEffect, useState } from "react"
import Input from "../../components/Input"
import Vista from "../../components/Vista"
import { productDetails } from "../../utils/styles"
import { NavLink } from "react-router-dom"
import { useLocalStorage } from "../../hooks/useLocalStorage"
import Servo from "../../services/servo"
import { useHistory } from "react-router-dom"
import { useMessages } from "../../hooks/useMessages"
import { LoadingButton } from "@mui/lab"
import { useAlert } from "../../hooks/useAlert"
import { BiPlus } from "react-icons/bi"
import DeleteIcon from "@mui/icons-material/Delete"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import AddIcCallIcon from "@mui/icons-material/AddIcCall"
import { ModeOfTravel } from "@mui/icons-material"

let vs = productDetails()
const Detail = ({ match }) => {
  const history = useHistory()
  const formDefault = {
    nombre: "",
    razonSocial: "",
    rfc: ""
  }

  // AREA DE DECLARACIONES
  const { setMessages } = useMessages()
  const { showAlert } = useAlert()
  const permisoCheck = "proveedores"
  const [permiso] = useLocalStorage("permiso", null)
  const [formDetails, setformDetails] = useState({ ...formDefault })
  const id = match?.params?.id
  const editado = !!id
  const [loadingB, setLoadingB] = useState(false)
  const [loading, setLoading] = useState(false)
  const [telefono, setTelefono] = useState("")
  const [telefonos, setTelefonos] = useState([])
  const [correo, setCorreo] = useState("")
  const [correos, setCorreos] = useState([])
  const [banco, setBanco] = useState("")
  const [numeroTarjeta, setNumeroTarjeta] = useState("")
  const [clabe, setClabe] = useState("")
  const [modoPago, setModoPago] = useState("")
  const [datosBancarios, setDatosBancarios] = useState([])

  const addTelefono = () => {
    if (telefono.trim() !== "") {
      setTelefonos([...telefonos, telefono])
    }
    setTelefono("")
  }

  const addCorreo = () => {
    if (correo.trim() !== "") {
      setCorreos([...correos, correo])
    }
    setCorreo("")
  }

  const addDatosBancarios = () => {
    if (
      banco.trim() !== "" &&
      numeroTarjeta.trim() !== "" &&
      clabe.trim() !== "" &&
      modoPago.trim() !== ""
    ) {
      setDatosBancarios([
        ...datosBancarios,
        { banco, numeroTarjeta, clabe, modoPago }
      ])
      setBanco("")
      setNumeroTarjeta("")
      setClabe("")
      setModoPago("")
    }
  }

  const deleteTelefono = (index) => {
    const newTelefonos = [...telefonos]
    newTelefonos.splice(index, 1)
    setTelefonos(newTelefonos)
  }

  const deleteCorreo = (index) => {
    const newCorreos = [...correos]
    newCorreos.splice(index, 1)
    setCorreos(newCorreos)
  }

  const deleteDatosBancarios = (index) => {
    const newDatosBancarios = [...datosBancarios]
    newDatosBancarios.splice(index, 1)
    setDatosBancarios(newDatosBancarios)
  }

  // SE EXTRAEN LOS PRODUCTOS
  const getDatos = async () => {
    setLoading(true)
    let res = await Servo.get(
      "proveedores" +
        "?idProveedor=" +
        id +
        "&expand=telefonos.telefono,correos.correo,datosBancarios.banco,datosBancarios.numeroTarjeta,datosBancarios.clabe,datosBancarios.modoPago"
    )
    if (res?.cuerpo?.resultado?.length > 0) {
      setformDetails((prevFormDetails) => ({
        ...prevFormDetails,
        ...res?.cuerpo?.resultado[0]
      }))
      setTelefonos(
        res?.cuerpo?.resultado[0]?.telefonos?.map((tel) => tel?.telefono)
      )
      setCorreos(
        res?.cuerpo?.resultado[0]?.correos?.map((email) => email?.correo)
      )
      setDatosBancarios(
        res?.cuerpo?.resultado[0]?.datosBancarios?.map((dato) => ({
          banco: dato?.banco,
          numeroTarjeta: dato?.numeroTarjeta,
          clabe: dato?.clabe,
          modoPago: dato?.modoPago
        }))
      )
    }
    setLoading(false)
  }

  useEffect(() => {
    if (editado) {
      getDatos()
    }
  }, [editado])

  // SE GUARDAN LOS PROVEEDORES
  const guardar = async () => {
    setLoadingB(true)

    const datosProveedor = {
      ...formDetails,
      telefono: telefonos,
      correo: correos,
      datoBancario: datosBancarios
    }

    try {
      let res = await Servo.post(
        "proveedores/guardar",
        datosProveedor,
        permisoCheck,
        true,
        history
      )

      if (res?.cuerpo) {
        setMessages(res?.cuerpo)
      }
    } catch (error) {
      console.error("Error al guardar proveedor:", error)
      showAlert(
        "Error al guardar proveedor. Por favor, intenta de nuevo.",
        "error"
      )
    } finally {
      setLoadingB(false)
    }
  }

  let productDetails = vs()

  return (
    <Vista>
      <div className={productDetails.screenSize}>
        <div className={productDetails.volverProveedores}>
          {(permiso[permisoCheck] & 1) === 1 ? (
            <NavLink exact to={"/" + permisoCheck}>
              « Volver
            </NavLink>
          ) : (
            <p>« Volver</p>
          )}
        </div>
        <div className={productDetails.top}>
          <div className={productDetails.title}>
            {editado ? <p>Editar Proveedor</p> : <p>Nuevo Proveedor</p>}
          </div>
        </div>
        <div className={productDetails.formArea}>
          <Grid container direction="row" columns={{ xs: 12, sm: 12, md: 12 }}>
            {!loading ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px"
                    }
                  }}
                  className={productDetails.inputArea}
                >
                  <Input
                    typeInput={"form"}
                    valor={formDetails?.nombre}
                    setValor={setformDetails}
                    placeholder="Nombre del Proveedor"
                    nombre="Nombre del Proveedor"
                    required="true"
                    onChange={(e) => {
                      setformDetails({
                        ...formDetails,
                        nombre: e.target.value
                      })
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px"
                    }
                  }}
                  className={productDetails.inputArea}
                >
                  <Input
                    typeInput={"form"}
                    valor={formDetails?.razonSocial}
                    setValor={setformDetails}
                    placeholder="Razón Social"
                    nombre="Razón Social"
                    required="true"
                    onChange={(e) => {
                      setformDetails({
                        ...formDetails,
                        razonSocial: e.target.value
                      })
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px"
                    }
                  }}
                  className={productDetails.inputArea}
                >
                  <Input
                    typeInput={"form"}
                    valor={formDetails?.rfc}
                    setValor={setformDetails}
                    placeholder="RFC"
                    nombre="RFC"
                    required="false"
                    onChange={(e) => {
                      setformDetails({
                        ...formDetails,
                        rfc: e.target.value
                      })
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px",
                      marginBottom: "10px"
                    },
                    display: "flex",
                    alignItems: "flex-end"
                  }}
                  className={productDetails.inputArea}
                >
                  <Input
                    typeInput={"form"}
                    valor={telefono}
                    setValor={setTelefono}
                    placeholder="Teléfono"
                    nombre="Teléfono"
                    required="false"
                    onChange={(e) => {
                      setTelefono(e.target.value)
                    }}
                  />
                  <IconButton onClick={addTelefono} color="primary">
                    <AddIcCallIcon />
                  </IconButton>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px"
                    }
                  }}
                  className={productDetails.inputArea}
                ></Grid>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Teléfonos</TableCell>
                        <TableCell>Acciones</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {telefonos.map((tel, index) => (
                        <TableRow key={index}>
                          <TableCell>{tel}</TableCell>
                          <TableCell>
                            <IconButton onClick={() => deleteTelefono(index)}>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px",
                      marginBottom: "10px"
                    },
                    display: "flex",
                    alignItems: "flex-end"
                  }}
                  className={productDetails.inputArea}
                >
                  <Input
                    typeInput={"form"}
                    valor={correo}
                    setValor={setCorreo}
                    placeholder="Correo"
                    nombre="Correo"
                    required="false"
                    onChange={(e) => {
                      setCorreo(e.target.value)
                    }}
                  />
                  <IconButton onClick={addCorreo} color="primary">
                    <AddCircleIcon />
                  </IconButton>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px"
                    }
                  }}
                  className={productDetails.inputArea}
                ></Grid>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Correos</TableCell>
                        <TableCell>Acciones</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {correos.map((email, index) => (
                        <TableRow key={index}>
                          <TableCell>{email}</TableCell>
                          <TableCell>
                            <IconButton onClick={() => deleteCorreo(index)}>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px",
                      marginBottom: "10px"
                    },
                    display: "flex",
                    alignItems: "flex-end"
                  }}
                  className={productDetails.inputArea}
                >
                  <Input
                    typeInput={"form"}
                    valor={banco}
                    setValor={setBanco}
                    placeholder="Banco"
                    nombre="Banco"
                    onChange={(e) => {
                      setBanco(e.target.value)
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px",
                      marginBottom: "10px"
                    },
                    display: "flex",
                    alignItems: "flex-end"
                  }}
                  className={productDetails.inputArea}
                >
                  <Input
                    typeInput={"form"}
                    valor={numeroTarjeta}
                    setValor={setNumeroTarjeta}
                    placeholder="Número de Tarjeta"
                    nombre="Número de Tarjeta"
                    onChange={(e) => {
                      setNumeroTarjeta(e.target.value)
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px",
                      marginBottom: "10px"
                    },
                    display: "flex",
                    alignItems: "flex-end"
                  }}
                  className={productDetails.inputArea}
                >
                  <Input
                    typeInput={"form"}
                    valor={modoPago}
                    setValor={setModoPago}
                    placeholder="Tipo de pago"
                    nombre="Tipo de pago"
                    onChange={(e) => {
                      setModoPago(e.target.value)
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px",
                      marginBottom: "10px"
                    },
                    display: "flex",
                    alignItems: "flex-end"
                  }}
                  className={productDetails.inputArea}
                >
                  <Input
                    typeInput={"form"}
                    valor={clabe}
                    setValor={setClabe}
                    placeholder="CLABE"
                    nombre="CLABE"
                    onChange={(e) => {
                      setClabe(e.target.value)
                    }}
                  />
                  <IconButton onClick={addDatosBancarios} color="primary">
                    <AddCircleIcon />
                  </IconButton>
                </Grid>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Banco</TableCell>
                        <TableCell>Número tarjeta</TableCell>
                        <TableCell>Tipo de pago</TableCell>
                        <TableCell>Clabe</TableCell>
                        <TableCell>Acciones</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {datosBancarios.map((dato, index) => (
                        <TableRow key={index}>
                          <TableCell>{dato.banco}</TableCell>
                          <TableCell>{dato.numeroTarjeta}</TableCell>
                          <TableCell>{dato.modoPago}</TableCell>
                          <TableCell>{dato.clabe}</TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => deleteDatosBancarios(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{
                  "@media (min-width: 600px)": {
                    margin: "100px"
                  }
                }}
                className={productDetails.inputArea}
              >
                <CircularProgress />
              </Grid>
            )}
          </Grid>
        </div>
        <div className={productDetails.buttonArea}>
          <div className={productDetails.buttonArea}>
            {(permiso[permisoCheck] & 2) === 2 ? (
              <LoadingButton
                loading={loadingB}
                onClick={guardar}
                sx={{
                  width: "170px",
                  height: "50px",
                  backgroundColor: "#157CC1",
                  fontFamily: "poppins",
                  borderRadius: "10px",
                  border: "none",
                  fontWeight: "500",
                  fontSize: "14px",
                  letterSpacing: "0.07em",
                  color: "#FFFFFF",
                  marginLeft: "2px",
                  "@media (min-width: 600px)": {
                    marginBottom: "6px"
                  },
                  "&:hover": {
                    backgroundColor: "#1565c0",
                    cursor: "pointer"
                  }
                }}
              >
                GUARDAR
              </LoadingButton>
            ) : (
              <LoadingButton
                onClick={guardar}
                disabled
                sx={{
                  width: "170px",
                  height: "50px",
                  backgroundColor: "#157CC1",
                  fontFamily: "poppins",
                  borderRadius: "10px",
                  border: "none",
                  fontWeight: "500",
                  fontSize: "14px",
                  letterSpacing: "0.07em",
                  color: "#FFFFFF",
                  marginLeft: "2px",
                  "@media (min-width: 600px)": {
                    marginBottom: "6px"
                  },
                  "&:hover": {
                    backgroundColor: "#1565c0",
                    cursor: "pointer"
                  }
                }}
              >
                GUARDAR
              </LoadingButton>
            )}
          </div>
        </div>
      </div>
    </Vista>
  )
}

export default Detail
