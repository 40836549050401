import React, { useState } from "react";
import TableCustom from "../../components/TableCustom";
import _ from "lodash";
import Input from "../../components/Input";
import { Grid } from "@mui/material";
import Vista from "../../components/Vista";
import dateComponent from "../../components/dateComponent";

const busquedaDefault = {
  correo: "",
  fechaInicio: dateComponent.getFirstDayOfYear(),
  fechaFinal: dateComponent.getActualDay(),
};

let dataTable = {
  nombre: {
    nombre: "Nombre",
    content: (e) => {
      return e.nombre;
    },
  },
  telefono: {
    nombre: "Número de Teléfono",
    content: (e) => {
      return e.telefono;
    },
  },
  correo: {
    nombre: "Correo Electrónico",
    content: (e) => {
      return e.correo;
    },
  },
};

let dataPdf = {
  nombre: {
    nombre: "Nombre",
    content: (e) => {
      return e.nombre;
    },
  },
  telefono: {
    nombre: "Número de Teléfono",
    content: (e) => {
      return e.telefono;
    },
  },
  correo: {
    nombre: "Correo Electrónico",
    content: (e) => {
      return e.correo;
    },
  },
};
const Empresa = ({ onClick, onKeyDown, ...props }) => {
    const [busqueda, setBusqueda] = useState({ ...busquedaDefault });
  return (
    <Vista>
      <TableCustom
        tipo={"idEmpresa"}
        busqueda={busqueda}
        setBusqueda={setBusqueda}
        dataTable={dataTable}
        dataPdf={dataPdf}
        buscador={[
          <Grid item xs={10} sm={8} sx={{ marginRight: "10px", height:"41px" }} key={1}>
            <Input
              value={busqueda?.correo}
              placeholder={"Correo"}
              typeInput={"onlyInput"}
              nombreBusqueda="correo"
              onChange={(e) =>
                setBusqueda({ ...busqueda, correo: e.target.value })
              }
            />
          </Grid>,
        ]}
        permisoCheck={"empresas"}
        nombreBusqueda={"nombre"}
      />
    </Vista>
  );
};

export default Empresa;
