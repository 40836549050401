import React, { useState } from "react"
import TableCustom from "../../components/TableCustom"
import _ from "lodash"
import Input from "../../components/Input"
import { Grid } from "@mui/material"
import Vista from "../../components/Vista"
import dateComponent from "../../components/dateComponent"

const busquedaDefault = {
  claveSat: "",
  codigo: "",
  fechaInicio: dateComponent.getFirstDayOfYear(),
  fechaFinal: dateComponent.getActualDay()
}

let dataTable = {
  codigo: {
    nombre: "Codigo",
    content: (e) => {
      return e.codigo
    }
  },
  descripcion: {
    nombre: "Descripcion",
    content: (e) => {
      return e.descripcion
    }
  },
  precio: {
    nombre: "Precio",
    content: (e) => {
      return e.precio
    }
  },
  tipo: {
    nombre: "Tipo",
    content: (e) => {
      return e.tipo
    }
  }
}
//PDF
let dataPdf = {
  codigo: {
    nombre: "Codigo",
    content: (e) => {
      return e.codigo
    }
  },
  descripcion: {
    nombre: "Descripcion",
    content: (e) => {
      return e.descripcion
    }
  },
  precio: {
    nombre: "Precio",
    content: (e) => {
      return e.precio
    }
  },
  tipo: {
    nombre: "Tipo",
    content: (e) => {
      return e.tipo
    }
  }
}
const ManoObra = ({ onClick, onKeyDown, ...props }) => {
  const [busqueda, setBusqueda] = useState({ ...busquedaDefault })

  return (
    <Vista>
      <TableCustom
        tipo={"idManoObra"}
        busqueda={busqueda}
        setBusqueda={setBusqueda}
        dataTable={dataTable}
        dataPdf={dataPdf}
        buscador={[
          <Grid
            item
            xs={10}
            sm={8}
            sx={{ marginRight: "10px", height: "41px" }}
            key={1}
          >
            <Input
              value={busqueda?.codigo}
              placeholder={"Codigo"}
              typeInput={"onlyInput"}
              nombreBusqueda="codigo"
              onChange={(e) =>
                setBusqueda({ ...busqueda, codigo: e.target.value })
              }
            />
          </Grid>
        ]}
        permisoCheck={"manosObra"}
        nombreBusqueda={"codigo"}
      />
    </Vista>
  )
}

export default ManoObra
