import React, { useState } from "react"
import TableCustom from "../../components/TableCustom"
import _ from "lodash"
import Input from "../../components/Input"
import { Grid } from "@mui/material"
import Vista from "../../components/Vista"
import dateComponent from "../../components/dateComponent"

const busquedaDefault = {
  placas: "",
  fechaInicio: dateComponent.getFirstDayOfYear(),
  fechaFinal: dateComponent.getActualDay()
}

// Configuración de la tabla
let dataTable = {
  placas: {
    nombre: "Placas",
    content: (e) => {
      return e.placas
    }
  },
  economico: {
    nombre: "Numero Económico",
    content: (e) => {
      return e.economico
    }
  },
  datos: {
    nombre: "Datos",
    content: (e) => {
      return e.marca + " " + e.modelo + " " + e.year
    }
  }
}

let dataPdf = {
  placas: {
    nombre: "Placas",
    content: (e) => {
      return e.placas
    }
  },
  economico: {
    nombre: "Numero Económico",
    content: (e) => {
      return e.economico
    }
  },
  datos: {
    nombre: "Datos",
    content: (e) => {
      return e.marca + " " + e.modelo + " " + e.year
    }
  }
}
const Carro = ({ onClick, onKeyDown, ...props }) => {
  const [busqueda, setBusqueda] = useState({ ...busquedaDefault })

  return (
    <Vista>
      <TableCustom
        tipo={"idCarro"}
        busqueda={busqueda}
        setBusqueda={setBusqueda}
        dataTable={dataTable}
        dataPdf={dataPdf}
        fechaInicio={busqueda.fechaInicio}
        fechaFinal={busqueda.fechaFinal}
        buscador={[
          <Grid
            item
            xs={10}
            sm={8}
            sx={{ marginRight: "10px", height: "41px" }}
            key={1}
          >
            <Input
              value={busqueda?.placas}
              placeholder={"Placas"}
              typeInput={"onlyInput"}
              nombreBusqueda="placas"
              onChange={(e) =>
                setBusqueda({ ...busqueda, placas: e.target.value })
              }
            />
          </Grid>
        ]}
        permisoCheck={"carros"}
        nombreBusqueda={"economico"}
      />
    </Vista>
  )
}

export default Carro
