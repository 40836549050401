import { CircularProgress, Grid } from "@mui/material"
import React, { useEffect, useState } from "react"
import Vista from "../../components/Vista"
import { ChecklistStyles } from "../../utils/styles"
import Servo from "../../services/servo"
import { useHistory } from "react-router-dom"
import { useMessages } from "../../hooks/useMessages"
import { useAlert } from "../../hooks/useAlert"
import Selector from "./Selector"
import DatosOrdenServicio from "./DatosOrdenServicio"
import Observacion from "./Observacion"
import Diagnostico from "./Diagnostico"
import Refaccion from "./Refaccion"
import ManoObra from "./ManoObra"
import OtroTaller from "./OtroTaller"
import { useLocalStorage } from "../../hooks/useLocalStorage"
import { socketUrl } from "../../utils/variables"
import moment from "moment"

let vs = ChecklistStyles()
const Detail = ({ match }) => {
  const permisoCheck = "ordenServicios"
  const [permiso] = useLocalStorage("permiso", null)
  const [screen, setScreen] = useState("DatosOrdenServicio")
  const { showAlert } = useAlert()
  const id = match?.params?.id
  const editado = !!id
  const { setMessages } = useMessages()
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  const getDatos = async () => {
    setLoading(true)

    let res = await Servo.get(
      "orden-servicios" +
        "?idOrdenServicio=" +
        id +
        "&expand=checklists.datoschecklists.areas," +
        "checklists.datoschecklists.carros," +
        "checklists.datoschecklists.empresas," +
        "checklists.gasolinachecklists.notas," +
        "empresas," +
        "observaciones," +
        "manosObraOrdenServicio.manosObra," +
        "refacciones.productos," +
        "diagnosticos.productos.lote," +
        "otroTaller.archivos," +
        "ordenesCompra.ordenesCompraRefacciones.productos," +
        "ordenesCompra.ordenesCompraRefacciones.proveedores"
    )
    if (
      res &&
      res.cuerpo &&
      res.cuerpo.resultado &&
      Array.isArray(res.cuerpo.resultado) &&
      res.cuerpo.resultado.length > 0
    ) {
      setformDetails(res?.cuerpo?.resultado[0])
      setEmpresa(res?.cuerpo?.resultado[0]?.empresas)
      setChecklist({
        ...checklist,
        idChecklist: res?.cuerpo?.resultado[0]?.idChecklist,
        economico:
          res?.cuerpo?.resultado[0]?.checklists?.datoschecklists?.carros
            ?.economico,
        placas:
          res?.cuerpo?.resultado[0]?.checklists?.datoschecklists?.carros
            ?.placas,
        modelo:
          res?.cuerpo?.resultado[0]?.checklists?.datoschecklists?.carros
            ?.modelo,
        marca:
          res?.cuerpo?.resultado[0]?.checklists?.datoschecklists?.carros?.marca,
        year: res?.cuerpo?.resultado[0]?.checklists?.datoschecklists?.carros
          ?.year,
        vin: res?.cuerpo?.resultado[0]?.checklists?.datoschecklists?.carros
          ?.vin,
        fechaPoliza:
          res?.cuerpo?.resultado[0]?.checklists?.datoschecklists?.carros
            ?.fechaPoliza,
        tarjetaCirculacion:
          res?.cuerpo?.resultado[0]?.checklists?.datoschecklists?.carros
            ?.tarjetaCirculacion,
        nombreEmpresa:
          res?.cuerpo?.resultado[0]?.checklists?.datoschecklists?.empresas
            ?.nombre,
        proyecto:
          res?.cuerpo?.resultado[0]?.checklists?.datoschecklists?.areas?.nombre,
        notas: res?.cuerpo?.resultado[0]?.checklists?.gasolinachecklists?.notas,
        kilometraje:
          res?.cuerpo?.resultado[0]?.checklists?.gasolinachecklists
            ?.kilometraje,
        gasolina:
          res?.cuerpo?.resultado[0]?.checklists?.gasolinachecklists?.gasolina
      })
      setDiagnosticos(res?.cuerpo?.resultado[0]?.diagnosticos || [])
      setOrdenesCompra(res?.cuerpo?.resultado[0]?.ordenesCompra || [])
      setObservaciones(res?.cuerpo?.resultado[0]?.observaciones || [])
      setManosObraOrdenServicio(
        res?.cuerpo?.resultado[0]?.manosObraOrdenServicio || []
      )
      setFiles(res?.cuerpo?.resultado[0]?.otroTaller?.archivos || [])
      setRefaccionesGuardadas(res?.cuerpo?.resultado[0]?.refacciones)
    }
    setLoading(false)
  }

  const getChecklists = async () => {
    let res = await Servo.get(
      "checklists?expand=datoschecklists.carros,datoschecklists.empresas,datoschecklists.areas,gasolinachecklists.notas"
    )
    if (res?.cuerpo?.resultado?.length > 0) {
      setChecklist(res?.cuerpo?.resultado)
    }
  }

  useEffect(() => {
    if (editado) {
      getDatos()
    } else {
      getChecklists()
    }
  }, [editado])

  const validacionesToChange = () => {
    let dg = true

    if (!editado) {
      if (screen == "DatosOrdenServicio") {
        showAlert({
          message: "seleccionar un checklist, es requerido",
          severity: "warning"
        })
      }
      dg = false
    }

    return { dg }
  }

  const [loadingButton, setLoadingButton] = useState(false)
  const [formDetails, setformDetails] = useState("")
  const [empresa, setEmpresa] = useState(null)
  const [checklist, setChecklist] = useState("")
  const [observaciones, setObservaciones] = useState([])
  const [manosObraOrdenServicio, setManosObraOrdenServicio] = useState([])
  const [diagnosticos, setDiagnosticos] = useState([])
  const [ordenesCompra, setOrdenesCompra] = useState([])
  const [servicioOtroTaller, setServicioOtroTaller] = useState([])
  const [files, setFiles] = useState([])
  const [mecanicosSeleccionados, setMecanicosSeleccionados] = useState({})
  const [refaccionesGuardadas, setRefaccionesGuardadas] = useState([])

  const guardarDatosOrdenServicio = async () => {
    setLoadingButton(true)
    let body = {
      DatosOrdenServicio: {
        idEmpresa: empresa?.idEmpresa,
        idChecklist: checklist?.idChecklist,
        fechaSolicitud: formDetails?.fechaSolicitud
      }
    }

    if (editado) {
      body.DatosOrdenServicio.idOrdenServicio = id
    }

    let res = await Servo.post(
      "orden-servicios/guardar-datos-orden-servicio",
      body,
      "ordenServicios",
      true,
      history
    )

    if (res?.cuerpo) {
      setMessages(res?.cuerpo)
      if (res?.cuerpo?.detalle?.estatus === 200) {
        fetch(socketUrl + "/noti", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            title: editado
              ? "SE MODIFICO UNA ORDEN DE SERVICIO"
              : "NUEVA ORDEN DE SERVICIO",
            id: res?.cuerpo?.detalle?.id,
            date: moment().format("DD/MM/YYYY"),
            time: moment().format("HH:mm")
          })
        })
      }
    }

    setLoadingButton(false)
  }

  const guardarObservacion = async () => {
    setLoadingButton(true)
    let body = {
      observacion: observaciones.map((obs) => obs.observacion)
    }

    if (editado) {
      body = { ...body, idOrdenServicio: id }
    }

    let res = await Servo.post(
      "orden-servicios/guardar-observaciones",
      body,
      "ordenServicios"
    )

    if (res?.cuerpo) {
      setMessages(res?.cuerpo)
      if (res?.cuerpo?.detalle?.estatus === 200) {
        fetch(socketUrl + "/noti", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            title: editado
              ? "SE MODIFICO UNA ORDEN DE SERVICIO"
              : "NUEVA ORDEN DE SERVICIO",
            id: res?.cuerpo?.detalle?.id,
            date: moment().format("DD/MM/YYYY"),
            time: moment().format("HH:mm")
          })
        })
      }
    }

    // if (res?.cuerpo) {
    //   showAlert({
    //     message: "Guardado exitosamente",
    //     severity: "success"
    //   })
    // } else {
    //   showAlert({
    //     message: "No se pudo guardar la observación",
    //     severity: "error"
    //   })
    // }

    setLoadingButton(false)
  }

  const guardarDiagnostico = async () => {
    setLoadingButton(true)

    // Transformar diagnosticos para incluir 'refaccion'
    const diagnosticosConRefaccion = diagnosticos.map((diagnostico) => ({
      ...diagnostico,
      refaccion:
        diagnostico.nombreProducto ||
        diagnostico.producto?.nombreProducto ||
        diagnostico.refaccion
    }))

    let body = {
      diagnostico: diagnosticosConRefaccion
    }

    if (editado) {
      body = { ...body, idOrdenServicio: id }
    }

    try {
      let res = await Servo.post(
        "orden-servicios/guardar-diagnostico",
        body,
        "ordenServicios"
      )

      if (res?.cuerpo?.detalle?.estatus === 200) {
        setMessages({
          mensaje: "Diagnóstico guardado con éxito",
          color: "success",
          detalle: { estatus: 200 }
        })
      } else {
        setMessages({
          mensaje: "Error al guardar el diagnóstico",
          color: "error",
          detalle: { estatus: res?.cuerpo?.detalle?.estatus || 400 },
          errores: res?.cuerpo?.errores || null
        })
      }
    } catch (error) {
      setMessages({
        mensaje: "Error de conexión al guardar diagnóstico",
        color: "error",
        detalle: { estatus: 500 }
      })
    } finally {
      setLoadingButton(false)
    }
  }

  const guardarManosObra = async (
    manosObraOrdenServicio,
    mecanicosSeleccionados
  ) => {
    setLoadingButton(true)
    let body = {
      manosObraOrdenServicio: manosObraOrdenServicio.map((mano) => ({
        ...mano,
        mecanicoCargo: mecanicosSeleccionados[mano.idManoObra] || ""
      }))
    }

    if (editado) {
      body = { ...body, idOrdenServicio: id }
    }

    let res = await Servo.post(
      "orden-servicios/guardar-manos-obra",
      body,
      "ordenServicios"
    )
    if (res?.cuerpo) {
      setMessages(res?.cuerpo)
    }
    setLoadingButton(false)
  }

  const guardarOrdenCompra = async (ordenesCompra) => {
    setLoadingButton(true)

    // Verifica que todos los productos tengan un proveedor asignado
    const ordenCompraConProveedores = ordenesCompra.map((producto) => {
      if (!producto.proveedor) {
        showAlert({
          message: "Todos los productos deben tener un proveedor asignado.",
          severity: "warning"
        })
        return null
      }
      return producto
    })

    // Si hay algún producto sin proveedor, no continuamos con la solicitud
    if (ordenCompraConProveedores.some((producto) => producto === null)) {
      setLoadingButton(false)
      return
    }

    let body = {
      ordenesCompra: ordenCompraConProveedores
    }

    if (editado) {
      body = { ...body, idOrdenServicio: id }
    }

    try {
      let res = await Servo.post(
        "orden-servicios/guardar-orden-compra",
        body,
        "ordenServicios"
      )

      if (res?.cuerpo) {
        setMessages(res?.cuerpo)
        showAlert({
          message: "Guardado exitosamente",
          severity: "success"
        })
        history.push("/ordenServicios/editar/" + id)
      } else {
        showAlert({
          message: "No se pudo guardar la orden de compra",
          severity: "error"
        })
      }
    } catch (error) {
      showAlert({
        message: "Orden de compra guardada correctamente",
        severity: "success"
      })
    } finally {
      setLoadingButton(false)
    }
  }

  const guardarOtroTaller = async (archivos) => {
    setLoadingButton(true)
    let body = {
      archivos: archivos,
      servicioOtroTaller: servicioOtroTaller
    }

    if (editado) {
      body = { ...body, idOrdenServicio: id }
    }

    let res = await Servo.post(
      "orden-servicios/guardar-otro-taller",
      body,
      "ordenServicios",
      true,
      history
    )
    if (res?.cuerpo) {
      setMessages(res?.cuerpo)
    }
    setLoadingButton(false)
  }

  const finalizarOrdenServicio = async () => {
    setLoadingButton(true)
    let body = {
      idOrdenServicio: id
    }

    let res = await Servo.post(
      "orden-servicios/finalizar-orden-servicio",
      body,
      "ordenServicios",
      true,
      history
    )

    if (res?.cuerpo) {
      setMessages(res?.cuerpo)
    }

    setLoadingButton(false)
  }

  let ChecklistStyles = vs()
  return (
    <Vista style={{ display: "block", backgroundColor: "#FFFFFF" }}>
      {!loading ? (
        <>
          {/* <div>{formDetails?.idChecklist}</div> */}
          <div className={ChecklistStyles.optionsContainer}>
            <Selector
              toChange={validacionesToChange}
              setScreen={setScreen}
              screen={screen}
            />
          </div>
          {screen === "DatosOrdenServicio" ? (
            <DatosOrdenServicio
              formDetails={formDetails}
              setformDetails={setformDetails}
              empresa={empresa}
              setEmpresa={setEmpresa}
              checklist={checklist}
              setChecklist={setChecklist}
              guardarDatosOrdenServicio={guardarDatosOrdenServicio}
            />
          ) : screen === "Observacion" ? (
            <Observacion
              guardarObservacion={guardarObservacion}
              observaciones={observaciones}
              setObservaciones={setObservaciones}
            />
          ) : screen === "Diagnostico" ? (
            <Diagnostico
              diagnosticos={diagnosticos}
              setDiagnosticos={setDiagnosticos}
              guardarDiagnostico={guardarDiagnostico}
              ordenesCompra={ordenesCompra}
              setOrdenesCompra={setOrdenesCompra}
              guardarOrdenCompra={guardarOrdenCompra}
            />
          ) : screen === "OtroTaller" ? (
            <OtroTaller
              servicioOtroTaller={servicioOtroTaller}
              setServicioOtroTaller={setServicioOtroTaller}
              guardarOtroTaller={guardarOtroTaller}
              files={files}
              setFiles={setFiles}
            />
          ) : screen === "ManoObra" ? (
            <ManoObra
              manosObraOrdenServicio={manosObraOrdenServicio}
              setManosObraOrdenServicio={setManosObraOrdenServicio}
              guardarManosObra={guardarManosObra}
              mecanicosSeleccionados={mecanicosSeleccionados}
              setMecanicosSeleccionados={setMecanicosSeleccionados}
            />
          ) : screen === "Refaccion" ? (
            <Refaccion
              refaccionesGuardadas={refaccionesGuardadas}
              finalizarOrdenServicio={finalizarOrdenServicio}
              empresa={empresa}
              setEmpresa={setEmpresa}
              id={id}
            />
          ) : (
            ""
          )}
        </>
      ) : (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            "@media (min-width: 600px)": {
              margin: "100px"
            }
          }}
        >
          <CircularProgress />
        </Grid>
      )}
    </Vista>
  )
}

export default Detail
