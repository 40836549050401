import firebase from "firebase/app"
import { backendUrl } from "../utils/variables"

const getCurrentToken = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onIdTokenChanged(async (user) => {
      unsubscribe()
      if (user) {
        const token = await user.getIdToken()
        resolve(token)
      }
    }, reject)
  })
}

const getHeaders = (token) => ({
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: `Bearer ${token}`
})

const getHeadersWhitOutToken = () => ({
  "Content-Type": "application/json",
  Accept: "application/json"
})

// ?variable=valor
class Servo {
  async get(url, wt = true) {
    const res = await fetch(backendUrl + url, {
      method: "GET",
      headers: wt
        ? getHeaders(await getCurrentToken())
        : getHeadersWhitOutToken()
    })
    return res.json()
  }

  async post(
    url,
    body,
    redirectURL,
    redirectOnSave = true,
    history,
    wt = true
  ) {
    const res = await fetch(backendUrl + url, {
      method: "POST",
      headers: wt
        ? getHeaders(await getCurrentToken())
        : getHeadersWhitOutToken(),
      body: JSON.stringify(body)
    })
    let resJson = await res.json()
    if (resJson?.cuerpo?.detalle?.estatus == 200) {
      if (redirectOnSave) {
        history.push("/" + redirectURL)
      }
    } else {
    }
    return resJson
  }

  async update(url, body, wt = true) {
    const res = await fetch(backendUrl + url, {
      method: "PUT",
      headers: wt
        ? getHeaders(await getCurrentToken())
        : getHeadersWhitOutToken(),
      body: JSON.stringify(body)
    })
    return res.json()
  }

  async delete(url, body, wt = true) {
    const res = await fetch(backendUrl + url, {
      method: "DELETE",
      headers: wt
        ? getHeaders(await getCurrentToken())
        : getHeadersWhitOutToken(),
      body: JSON.stringify(body)
    })
    return res.json()
  }
}

export default new Servo()
