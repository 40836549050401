import React, {
  useMemo,
  createContext,
  useState,
  useEffect,
  useContext
} from "react"

const ModalContext = createContext()

export function ModalProvider(props) {
  const [open, setOpen] = useState(false)
  const [containerModal, setContainerModal] = useState(false)

  useEffect(() => {
    if (containerModal) {
      setOpen(true)
    }
  }, [containerModal])

  useEffect(() => {
    if (!open) {
      setContainerModal(false)
    }
  }, [open])

  const memData = useMemo(() => {
    return {
      open,
      setOpen,
      containerModal,
      setContainerModal
    }
  }, [open, containerModal])

  return <ModalContext.Provider value={memData} {...props} />
}

export function useModal() {
  const context = useContext(ModalContext)
  if (!context) {
    // eslint-disable-next-line no-throw-literal
    throw "error: mesages context not defined."
  }
  return context
}
