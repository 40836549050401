import React, { useState } from "react"
import TableCustom from "../../components/TableCustom"
import _ from "lodash"
import SelectFormat from "../../components/SelectFormat"
import { Grid } from "@mui/material"
import Vista from "../../components/Vista"
import dateComponent from "../../components/dateComponent"
import Input from "../../components/Input"

const busquedaDefault = {
  categoria: "",
  marca: "",
  fechaInicio: dateComponent.getFirstDayOfYear(),
  fechaFinal: dateComponent.getActualDay()
}

let dataTable = {
  nombreProducto: {
    nombre: "Nombre",
    content: (e) => {
      return e.nombreProducto
    }
  },
  categoria: {
    nombre: "Categoría",
    content: (e) => {
      return e.categoria
    }
  },
  marca: {
    nombre: "Marca",
    content: (e) => {
      return e.marca
    }
  },
  precioProveedor: {
    nombre: "Precio Proveedor",
    content: (e) => {
      return e.precioProveedor
    }
  },
  precioPublico: {
    nombre: "Precio Público",
    content: (e) => {
      return e.precioPublico
    }
  },
  numParte: {
    nombre: "Número de parte",
    content: (e) => {
      return e.numParte
    }
  },
  inventario: {
    nombre: "Existencia",
    content: (e) => {
      return e.inventario
    }
  }
}

let dataPdf = {
  nombreProducto: {
    nombre: "Nombre",
    content: (e) => {
      return e.nombreProducto
    }
  },
  categoria: {
    nombre: "Categoría",
    content: (e) => {
      return e.categoria
    }
  },
  marca: {
    nombre: "Marca",
    content: (e) => {
      return e.marca
    }
  },
  precioProveedor: {
    nombre: "Precio Proveedor",
    content: (e) => {
      return e.precioProveedor
    }
  },
  precioPublico: {
    nombre: "Precio Público",
    content: (e) => {
      return e.precioPublico
    }
  },
  numParte: {
    nombre: "Número de parte",
    content: (e) => {
      return e.numParte
    }
  },
  inventario: {
    nombre: "Existencia",
    content: (e) => {
      return e.inventario
    }
  }
}

const Producto = ({ onClick, onKeyDown, ...props }) => {
  const [busqueda, setBusqueda] = useState({ ...busquedaDefault })
  return (
    <Vista>
      <TableCustom
        tipo={"idProducto"}
        busqueda={busqueda}
        setBusqueda={setBusqueda}
        dataTable={dataTable}
        dataPdf={dataPdf}
        buscador={[
          <Grid
            item
            xs={5}
            sm={4}
            sx={{ marginRight: "10px", height: "41px" }}
            key={1}
          >
            <Input
              value={busqueda?.numParte}
              placeholder={"No.Parte"}
              typeInput={"onlyInput"}
              nombreBusqueda="numero de parte"
              onChange={(e) =>
                setBusqueda({ ...busqueda, numParte: e.target.value })
              }
            />
          </Grid>,
          <Grid
            item
            xs={5}
            sm={4}
            sx={{
              marginRight: "10px"
            }}
            key={2}
          >
            <SelectFormat
              value={busqueda?.marca}
              placeholder={"Marca"}
              onChange={(e) =>
                setBusqueda({ ...busqueda, marca: e.target.value })
              }
              datos={[
                "Todos",
                "DURALAST",
                "CARTEK",
                "MONROE",
                "SYD",
                "GRC",
                "MOTORCRAFT",
                "ECOM",
                "VALVOLINE",
                "BARDAHAL",
                "AUTOLITE",
                "NGK",
                "VALUCRAFT",
                "MOBIL",
                "EURO FRICTION",
                "WAGNER",
                "GATES",
                "TMK",
                "LUK",
                "WD-40",
                "MOOG",
                "LTH",
                "REIDEN",
                "TOYOTA",
                "RAM",
                "FORD",
                "MITSUBICHI",
                "MERCEDES-BENZ",
                "BFGOODRICH",
                "MICHELIN",
                "BRIDGESTON",
                "UNIROYAL",
                "ROADMASTER",
                "CHAMPION",
                "DENSO"
              ]}
            />
          </Grid>
        ]}
        expand={"inventario"}
        permisoCheck={"productos"}
        nombreBusqueda={"nombreProducto"}
      />
    </Vista>
  )
}
export default Producto
