import React, { useEffect } from "react"
import { PublicHeaderStyles } from "../utils/styles"
import { FaBell } from "react-icons/fa"
import { MdDelete } from "react-icons/md"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

let PHS = PublicHeaderStyles()

const PrivateHeader = () => {
  const history = useHistory()
  let PublicHeaderStyles = PHS()

  const [show, setShow] = React.useState(false)
  const [notis, setNotis] = React.useState(
    localStorage.getItem("notis") &&
      JSON.parse(localStorage.getItem("notis").length) > 0
      ? [...JSON.parse(localStorage.getItem("notis"))].reverse()
      : []
  )

  useEffect(() => {
    setNotis(
      localStorage.getItem("notis") &&
        JSON.parse(localStorage.getItem("notis").length) > 0
        ? [...JSON.parse(localStorage.getItem("notis"))].reverse()
        : []
    )
  }, [localStorage.getItem("notis")])

  const deleteNoti = (_noti) => {
    let newNotis = notis.filter((anoti) => {
      return !(
        anoti.date == _noti.date &&
        anoti.time == _noti.time &&
        anoti.id == _noti.id
      )
    })
    localStorage.setItem("notis", JSON.stringify(newNotis))
    setNotis(newNotis)
  }

  return (
    <div className={PublicHeaderStyles.barra}>
      <img
        src="https://firebasestorage.googleapis.com/v0/b/zonda-b0ce9.appspot.com/o/Sistema%2FLogotipo-Zonda-Vector.svg?alt=media&token=e3d435da-9937-473b-8994-f4ac1cd7b850&_gl=1*da6o61*_ga*MTA2MTM0NDcyMC4xNjc3MTAxNzk3*_ga_CW55HF8NVT*MTY4NjU5MzU3Mi4xOC4xLjE2ODY1OTM2NDkuMC4wLjA."
        alt="Logo de la Empresa"
        className={PublicHeaderStyles.logo}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "40px",
          width: "40px",
          marginRight: "20px",
          position: "relative"
        }}
      >
        <div
          style={{
            backgroundColor: "red",
            height: "15px",
            width: "15px",
            borderRadius: "50%",
            position: "absolute",
            top: "0px",
            right: "0px",
            color: "#FFF",
            fontSize: "10px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {localStorage.getItem("notis")
            ? JSON.parse(localStorage.getItem("notis")).length > 9
              ? "+9"
              : JSON.parse(localStorage.getItem("notis")).length.toString()
            : "0"}
        </div>
        <button
          onClick={() => setShow(!show)}
          style={{
            backgroundColor: "#FFF",
            border: "none",
            borderRadius: "50%",
            height: "30px",
            width: "30px"
          }}
        >
          <FaBell />
        </button>
        <div
          style={{
            top: "32px",
            width: "0",
            height: "0",
            border: "10px solid transparent",
            borderTop: "0",
            borderBottom: "20px solid #FFF",
            position: "absolute",
            display: show ? "block" : "none",
            zIndex: "101"
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            height: "calc(100vh - 90px)",
            width: "500px",
            background: "#FFF",
            zIndex: "100",
            top: "50px",
            right: "0px",
            display: show ? "flex" : "none",
            padding: "10px 0px",
            borderRadius: "5px",
            border: "1px solid #CCC",
            flexDirection: "column"
          }}
        >
          {notis.map((noti) => (
            <div
              className={PublicHeaderStyles.noti}
              key={noti.id + noti.date + noti.time}
              onClick={() => {
                history.push("/ordenServicios/editar/" + noti.id)
                deleteNoti(noti)
                setShow(false)
              }}
            >
              <div
                style={{
                  color: "#FFF",
                  fontSize: "12px",
                  fontWeight: "bold",
                  textAlign: "center",
                  backgroundColor: "#157CC1",
                  width: "50%",
                  clipPath: "polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%)",
                  position: "absolute"
                }}
              >
                {noti?.date} {noti?.time}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px"
                }}
              >
                <div
                  style={{
                    marginTop: "10px",
                    fontWeight: "bold"
                  }}
                >
                  {noti?.title} CON ID {noti?.id}
                </div>
                {/* <button
                  style={{
                    backgroundColor: "red",
                    color: "#FFF",
                    border: "none",
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer"
                  }}
                  onClick={() => deleteNoti(noti)}
                >
                  <MdDelete />
                </button> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default PrivateHeader
