import React, { useState, useEffect, useMemo } from "react"
import Vista from "../../components/Vista"
import Servo from "../../services/servo"

const SearchBar = ({ value, onChange, economico }) => (
  <div style={{ marginBottom: "20px", textAlign: "center" }}>
    <input
      type="text"
      placeholder="Buscar por económico..."
      value={value}
      onChange={onChange}
      style={styles.searchInput}
    />
  </div>
)
const ServiceTable = ({ data, dataTable }) => (
  <table style={styles.table}>
    <thead>
      <tr style={styles.headerRow}>
        {Object.keys(dataTable).map((key) => (
          <th key={key} style={styles.headerCell}>
            {dataTable[key].nombre}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {data.length > 0 ? (
        data.map((item) => (
          <tr key={item.id} style={styles.row}>
            {Object.keys(dataTable).map((key) => (
              <td key={key} style={styles.cell}>
                {dataTable[key].content(item)}
              </td>
            ))}
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={Object.keys(dataTable).length} style={styles.emptyRow}>
            No se encontraron resultados.
          </td>
        </tr>
      )}
    </tbody>
  </table>
)
const calculateDaysLeft = (serviceDate) => {
  const today = new Date()
  const service = new Date(serviceDate)
  const diffInTime = service - today // Diferencia en milisegundos
  return Math.ceil(diffInTime / (1000 * 60 * 60 * 24)) // Convertir a días
}

const Servicio = () => {
  const [searchTerm, setSearchTerm] = useState("")
  const [checklistData, setChecklistData] = useState([])
  const [loading, setLoading] = useState(false)

  // Obtener datos dinámicos desde el servidor
  useEffect(() => {
    const getDatos = async () => {
      setLoading(true)
      try {
        let res = await Servo.get(
          "checklists" +
            "?idChecklist" + // Aquí puedes usar un ID dinámico si es necesario
            "&expand=datoschecklists.carros,"
        )
        if (res?.cuerpo?.resultado?.length > 0) {
          const resultado = res?.cuerpo?.resultado

          // Convertir siempre a arreglo
          setChecklistData(Array.isArray(resultado) ? resultado : [resultado])
        } else {
          console.warn("No se encontraron resultados en la respuesta.")
        }
      } catch (error) {
        console.error("Error al obtener los datos:", error)
      } finally {
        setLoading(false)
      }
    }

    getDatos()
  }, [])

  // Filtrar datos en base al término de búsqueda
  const filteredData = useMemo(
    () =>
      checklistData.filter((item) => {
        const economico = item?.datoschecklists?.carros?.economico
        console.log("Economico:", economico) // Verifica el valor de 'economico'
        return (
          economico &&
          economico.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      }),
    [checklistData, searchTerm]
  )
  // Calcular la fecha de servicio sumando 3 meses
  const calculateServiceDate = (entryDate) => {
    const date = new Date(entryDate)
    date.setMonth(date.getMonth() + 3) // Sumar 3 meses
    return date.toISOString().split("T")[0] // Retorna en formato YYYY-MM-DD
  }

  // Configuración dinámica de columnas de la tabla
  const dataTable = useMemo(
    () => ({
      economico: {
        nombre: "Económico",
        content: (e) => e?.datoschecklists?.carros?.economico || "N/A"
      },
      fecha: {
        nombre: "Fecha del ultimo Check",
        content: (e) => e?.creado || "N/A"
      },
      fechaServicio: {
        nombre: "Fecha del Proximo Servicio",
        content: (item) => {
          const serviceDate = item?.creado
            ? calculateServiceDate(item.creado)
            : "N/A"
          if (serviceDate === "N/A") return serviceDate

          const daysLeft = calculateDaysLeft(serviceDate)

          // Definir estilos dinámicos
          const cellStyle = {
            backgroundColor:
              daysLeft <= 7
                ? "#FF0000"
                : daysLeft <= 30
                ? "#FFD700"
                : "transparent",
            fontWeight: daysLeft <= 30 ? "bold" : "normal"
          }

          return (
            <span style={cellStyle}>
              {serviceDate} ({daysLeft} días restantes)
            </span>
          )
        }
      }
    }),
    []
  )

  return (
    <Vista>
      <div style={{ padding: "20px" }}>
        <h1 style={{ textAlign: "center" }}>Próximo Servicio</h1>

        {loading ? (
          <p>Cargando datos...</p>
        ) : (
          <>
            {/* Barra de búsqueda */}
            <SearchBar
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />

            {/* Tabla de resultados */}
            <ServiceTable data={filteredData} dataTable={dataTable} />
          </>
        )}
      </div>
    </Vista>
  )
}
const styles = {
  searchBarContainer: {
    marginBottom: "30px", // Mayor separación en la parte inferior
    textAlign: "center"
  },
  searchInput: {
    padding: "15px 20px", // Espaciado más grande
    width: "90%", // Hace el input responsivo
    maxWidth: "300px", // Limita el ancho máximo
    borderRadius: "8px", // Bordes redondeados
    border: "1px solid #ccc", // Borde más sutil
    fontSize: "12px", // Aumento del tamaño de la fuente
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Sombra sutil para darle profundidad
    transition: "all 0.3s ease-in-out", // Transición suave para los cambios
    outline: "none" // Quitar borde al enfocar
  },
  searchInputFocus: {
    borderColor: "#157CC1", // Color de borde al recibir foco
    boxShadow: "0 0 8px rgba(21, 124, 193, 0.5)" // Sombra más intensa al enfocar
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "20px",
    textAlign: "center",
    backgroundColor: "#fff",
    borderRadius: "8px",
    overflow: "hidden",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxHeight: "400px", // Limita la altura visible de la tabla
    overflowY: "auto", // Habilita scroll vertical
    overflowX: "auto", // Habilita scroll horizontal si es necesario
    border: "1px solid #ddd"
  },
  headerRow: {
    backgroundColor: "#157CC1",
    color: "#FFFFFF",
    textTransform: "uppercase"
  },
  headerCell: {
    padding: "12px",
    fontWeight: "bold",
    fontSize: "14px",
    borderBottom: "2px solid #004A91",
    textAlign: "center"
  },
  row: {
    backgroundColor: "#F9F9F9",
    transition: "background-color 0.3s"
  },
  cell: {
    padding: "12px",
    fontSize: "14px",
    borderBottom: "1px solid #E0E0E0",
    textAlign: "left"
  },
  rowHover: {
    backgroundColor: "#E6F7FF"
  },
  emptyRow: {
    textAlign: "center",
    padding: "20px",
    fontSize: "16px",
    color: "#757575"
  },
  searchBar: {
    marginBottom: "20px",
    textAlign: "center"
  },

  loadingMessage: {
    textAlign: "center",
    fontSize: "18px",
    color: "#157CC1",
    fontWeight: "bold"
  }
}

export default Servicio
