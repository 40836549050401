import React, { useEffect, useState } from "react"
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material"
import Input from "../../components/Input"
import AutocompleteDeluxe from "../../components/AutocompleteDelux"
import { EstilosButton, OrdenServicioStyles } from "../../utils/styles"
import { useAlert } from "../../hooks/useAlert"
import Vista from "../../components/Vista"
import { BiPlus } from "react-icons/bi"
import { LoadingButton } from "@mui/lab"
import { DeleteOutlined } from "@mui/icons-material"
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty"
import PanToolIcon from "@mui/icons-material/PanTool"
import moment from "moment"

let VS = OrdenServicioStyles()
let VS2 = EstilosButton()
const ManosObra = ({
  manosObraOrdenServicio,
  setManosObraOrdenServicio,
  guardarManosObra,
  mecanicosSeleccionados,
  setMecanicosSeleccionados
}) => {
  let OrdenServicioStyles = VS()
  let EstilosButton = VS2()
  const { showAlert } = useAlert()
  const [manoObra, setManoObra] = useState(null)
  const [semaforos, setSemaforos] = useState(() => {
    const savedSemaforos = JSON.parse(localStorage.getItem("semaforos")) || {}
    return savedSemaforos
  })

  const addServices = async () => {
    if (manoObra != null) {
      let copyManosObra = [...manosObraOrdenServicio]
      let validacion = manosObraOrdenServicio.some((mano) => {
        return mano.idManoObra === manoObra.idManoObra
      })
      if (validacion) {
        showAlert({
          message: "Servicio repetido",
          severity: "warning"
        })
        return
      }
      copyManosObra.push({ ...manoObra })
      setManosObraOrdenServicio([...copyManosObra])
    } else {
      showAlert({
        message: "Campo de Mano de Obra Vacío",
        severity: "warning"
      })
      return
    }
  }

  const deleteServices = (idManoObra) => {
    let copyServices = [...manosObraOrdenServicio]
    let index = copyServices.findIndex((services) => {
      return services.idManoObra === idManoObra
    })
    copyServices.splice(index, 1)
    setManosObraOrdenServicio([...copyServices])

    showAlert({
      message: "Mano de Obra eliminada",
      severity: "success"
    })
  }

  const iniciarSemaforo = (idManoObra) => {
    setSemaforos((prevState) => {
      const newState = { ...prevState, [idManoObra]: true }
      localStorage.setItem("semaforos", JSON.stringify(newState))
      return newState
    })
  }

  const detenerSemaforo = (idManoObra) => {
    setSemaforos((prevState) => {
      const newState = { ...prevState, [idManoObra]: false }
      localStorage.setItem("semaforos", JSON.stringify(newState))
      return newState
    })
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const updatedManosObra = manosObraOrdenServicio.map((service) => {
        const updatedService = { ...service }
        if (semaforos[service.idManoObra]) {
          const tiempoActual = moment(new Date())
          const tiempoInicio = moment(service.agregado)
          const duracionMinutos = service.manosObra.duracion * 60

          updatedService.color = tiempoActual.isBefore(
            tiempoInicio.clone().add(duracionMinutos / 2, "minutes")
          )
            ? "green"
            : tiempoActual.isBetween(
                tiempoInicio.clone().add(duracionMinutos / 2, "minutes"),
                tiempoInicio.clone().add(duracionMinutos, "minutes")
              )
            ? "yellow"
            : tiempoActual.isAfter(
                tiempoInicio.clone().add(duracionMinutos, "minutes")
              )
            ? "red"
            : "gray"
        }
        return updatedService
      })

      setManosObraOrdenServicio((prevState) => {
        // Mantén los datos de los mecánicos al actualizar los servicios
        return prevState.map((service) => {
          const updatedService = updatedManosObra.find(
            (u) => u.idManoObra === service.idManoObra
          )
          return {
            ...updatedService,
            mecanicoCargo:
              service.mecanicoCargo || updatedService?.mecanicoCargo
          }
        })
      })
    }, 30000)

    return () => clearInterval(interval)
  }, [semaforos, manosObraOrdenServicio])

  useEffect(() => {
    const inicializarMecanicosSeleccionados = () => {
      setMecanicosSeleccionados((prevState) => {
        const newState = { ...prevState }
        manosObraOrdenServicio.forEach((_manoObra) => {
          if (!newState[_manoObra.idManoObra]) {
            newState[_manoObra.idManoObra] = _manoObra.mecanicoCargo || ""
          }
        })
        return newState
      })
    }

    if (manosObraOrdenServicio.length > 0) {
      inicializarMecanicosSeleccionados()
    }
  }, [manosObraOrdenServicio.length])

  return (
    <Vista>
      <div className={OrdenServicioStyles.container}>
        <div className={OrdenServicioStyles.formArea}>
          <div className={OrdenServicioStyles.formDataContainer}>
            <Grid
              container
              direction="row"
              sx={{
                width: "100%",
                height: "100%",
                alignItems: "center"
              }} // Alineación vertical centrada
              columns={{ xs: 12, sm: 12, md: 12 }}
            >
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "92px",
                  padding: "0 10px"
                }}
              >
                <AutocompleteDeluxe
                  required
                  labelToShow="Manos de Obra"
                  labelProp="codigo"
                  nombre="manos-obra"
                  value={manoObra}
                  setDato={setManoObra}
                  background={"#FFFFFF"}
                  sx={{ height: "100%" }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "92px",
                  padding: "0 10px"
                }}
              >
                <Button
                  sx={{
                    width: "100%",
                    height: "41px",
                    backgroundColor: "#157CC1",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    textTransform: "none",
                    marginBottom: "7px"
                  }}
                  onClick={addServices}
                  variant="contained"
                >
                  <BiPlus className={EstilosButton.icon} />
                  <p className={EstilosButton.font}>Agregar Producto</p>
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={OrdenServicioStyles.formAreaTable}>
          <Paper
            sx={{
              "@media (max-width: 600px)": {
                width: "98%",
                minHeight: "50%"
              },
              width: "70%",
              overflow: "hidden"
            }}
          >
            <TableContainer sx={{ maxHeight: 320 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className={OrdenServicioStyles?.tableHead}>
                    <TableCell
                      className="headColor"
                      sx={{ fontFamily: "poppins", textAlign: "center" }}
                    >
                      #
                    </TableCell>
                    <TableCell
                      className="headColor"
                      sx={{ fontFamily: "poppins", textAlign: "center" }}
                    >
                      Codigo
                    </TableCell>
                    <TableCell
                      className="headColor"
                      sx={{ fontFamily: "poppins", textAlign: "center" }}
                    >
                      Descripcion
                    </TableCell>
                    <TableCell
                      className="headColor"
                      sx={{ fontFamily: "poppins", textAlign: "center" }}
                    >
                      Precio
                    </TableCell>
                    <TableCell
                      className="headColor"
                      sx={{ fontFamily: "poppins", textAlign: "center" }}
                    >
                      Servicio
                    </TableCell>
                    <TableCell
                      className="headColor"
                      sx={{ fontFamily: "poppins", textAlign: "center" }}
                    >
                      A Tiempo
                    </TableCell>
                    <TableCell
                      className="headColor"
                      sx={{ fontFamily: "poppins", textAlign: "center" }}
                    >
                      Mecanico a Cargo
                    </TableCell>
                    <TableCell
                      className="headColor"
                      sx={{ fontFamily: "poppins", textAlign: "center" }}
                    >
                      Eliminar
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {manosObraOrdenServicio &&
                    manosObraOrdenServicio.length > 0 &&
                    manosObraOrdenServicio.map((services, index) => (
                      <TableRow
                        className={OrdenServicioStyles?.tableRow}
                        key={index}
                      >
                        <TableCell
                          className="rowColor"
                          sx={{ fontFamily: "poppins", textAlign: "center" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          className="rowColor"
                          sx={{
                            fontFamily: "poppins",
                            lineHeight: "13px",
                            textAlign: "center"
                          }}
                        >
                          {services?.manosObra?.codigo || services?.codigo}
                        </TableCell>
                        <TableCell
                          className="rowColor"
                          sx={{
                            fontFamily: "poppins",
                            lineHeight: "13px",
                            textAlign: "center"
                          }}
                        >
                          {services?.manosObra?.descripcion ||
                            services?.descripcion}
                        </TableCell>
                        <TableCell
                          className="rowColor"
                          sx={{
                            fontFamily: "poppins",
                            lineHeight: "13px",
                            textAlign: "center"
                          }}
                        >
                          {services?.precio}
                        </TableCell>
                        <TableCell
                          className="rowColor"
                          sx={{
                            fontFamily: "poppins",
                            lineHeight: "13px",
                            textAlign: "center"
                          }}
                        >
                          {services?.manosObra?.tipo || services?.tipo}
                        </TableCell>
                        {/* Columna del semáforo */}
                        <TableCell>
                          <div
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                              backgroundColor: services.color || "gray"
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center"
                            }}
                          >
                            <IconButton
                              style={{
                                margin: "0",
                                borderRadius: "50px",
                                width: "40px",
                                height: "40px"
                              }}
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                iniciarSemaforo(services.idManoObra)
                              }
                              disabled={semaforos[services.idManoObra]}
                            >
                              <HourglassEmptyIcon />
                            </IconButton>
                            <IconButton
                              style={{
                                margin: "0",
                                borderRadius: "50px",
                                width: "40px",
                                height: "40px"
                              }}
                              variant="contained"
                              color="secondary"
                              onClick={() =>
                                detenerSemaforo(services.idManoObra)
                              }
                              disabled={!semaforos[services.idManoObra]}
                            >
                              <PanToolIcon />
                            </IconButton>
                          </div>
                        </TableCell>
                        <TableCell
                          className="manoObra"
                          sx={{
                            fontFamily: "poppins",
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center", // Cambiado a "center" para alinear verticalmente
                            padding: "0px 0px 15px 10px",
                            marginTop: "-15px"
                          }}
                        >
                          <Input
                            sx={{ marginTop: "-15px" }}
                            typeInput={"formSelect"}
                            valor={
                              mecanicosSeleccionados[services.idManoObra] || ""
                            }
                            onChange={(e) => {
                              const selectedMecanico = e.target.value
                              setMecanicosSeleccionados((prevState) => ({
                                ...prevState,
                                [services.idManoObra]: selectedMecanico
                              }))
                            }}
                            data={[
                              "BARAJAS OJEDA SERGIO RAUL",
                              "LUGO ISLAS MARCOS ALEXIS",
                              "CRUZ CRUZ FRANCISCO ALBERTO",
                              "CORDOVA COHEN LUIS RAMON",
                              "HERNANDEZ ALCALA FERNANDO",
                              "CARLOS HASSIEL PAYAN MARTINEZ",
                              "TERAN VALENZUELA LUIS GUSTAVO",
                              "PAYAN QUINTERO JESUS MIGUEL",
                              "GURROLA PERAL JUAN FERNANDO",
                              "GIL QUIJADA LUIS ELIAS",
                              "ALMAGUER GONZALEZ SION DANIEL",
                              "BERNAL SOBARZO JESUS CARLOS",
                              "VALENZUELA VIEZCAS MANUEL ANDRES",
                              "HERNANDEZ ALCALA JOSE FERNANDO",
                              "DORAME VILLA JULIAN FERNANDO"
                            ]}
                          />
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <IconButton
                            onClick={() => deleteServices(services.idManoObra)}
                          >
                            <DeleteOutlined />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
        <div className={OrdenServicioStyles?.buttonAreaContainer}>
          <div
            className={OrdenServicioStyles?.buttonContainer}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "10px",
              alignItems: "center"
            }}
          >
            <LoadingButton
              loading={false}
              sx={{
                width: "170px",
                height: "50px",
                backgroundColor: "#157CC1",
                fontFamily: "poppins",
                borderRadius: "10px",
                border: "none",
                fontWeight: "500",
                fontSize: "14px",
                letterSpacing: "0.07em",
                color: "#FFFFFF",
                marginLeft: "2px",
                "@media (min-width: 600px)": {
                  marginBottom: "6px"
                },
                "&:hover": {
                  backgroundColor: "#1565c0",
                  cursor: "pointer"
                }
              }}
              className={OrdenServicioStyles?.buttonSave}
              onClick={async () => {
                guardarManosObra(manosObraOrdenServicio, mecanicosSeleccionados)
              }}
            >
              GUARDAR
            </LoadingButton>
          </div>
        </div>
      </div>
    </Vista>
  )
}

export default ManosObra
