import React from "react"
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  pdf
} from "@react-pdf/renderer"
import moment from "moment"

const PdfOrdenCompra = ({ data }) => {
  const fecha = moment(data.creado).format("L")
  const fechaActual = moment().format("L")
  const folio = data.idOrdenCompra
  const ordenServicio = data.idOrdenServicio
  const proveedor = data.ordenesCompraRefacciones[0]?.proveedores?.nombre
  const numEconomico = data.checklists?.datoschecklists?.carros
  const empresa = data.empresas?.nombre
  const nOrden = data.numOrden

  // Calcular subtotales, impuestos, y total
  const subtotal = data.ordenesCompraRefacciones.reduce(
    (acc, item) => acc + parseFloat(item.subtotal),
    0
  )
  const impuestos = 0 // Agregar cálculo de impuestos si es necesario
  const impuestoVentas = subtotal * 0.16 // Ejemplo: 16% de IVA
  const total = subtotal + impuestos + impuestoVentas

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.title}>ZONDA-BYLSA</Text>
          <Text style={styles.subTitle}>ORDEN COMPRA: {folio}</Text>
        </View>

        <View style={styles.infoContainer}>
          <View style={styles.leftContainer}>
            <Text>{empresa}</Text>
            <Text>UNIDAD: {numEconomico}</Text>
            {/* <Text>Emitido para:</Text>
            <Text>{proveedor}</Text> */}
            <Text>HERMOSILLO, SONORA</Text>
          </View>
          <View style={styles.rightContainer}>
            <Text>Fecha: {fecha}</Text>
            
            <Text>Autorizado por: {}</Text>
            <Text>ORDEN SERVICIO NO.: {ordenServicio}</Text>
            <Text>ORDEN NO.: {nOrden}</Text>
            <Text>Enviar el: INMEDIATO</Text>
          </View>
        </View>

        <View style={styles.body}>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <Text style={styles.tableCellHeader}># PARTE</Text>
              <Text style={styles.tableCellHeader}>DESCRIPCIÓN</Text>
              <Text style={styles.tableCellHeader}>PRECIO</Text>
              <Text style={styles.tableCellHeader}>CANTIDAD</Text>
              <Text style={styles.tableCellHeader}>PROVEEDOR</Text>
            </View>
            {data.ordenesCompraRefacciones.map((item, index) => (
              <View style={styles.tableRow} key={index}>
                <Text style={styles.tableCell}>{item.productos.numParte}</Text>
                <Text style={styles.tableCell}>
                  {item.productos.nombreProducto}
                </Text>
                <Text style={styles.tableCell}>$ {item.precio}</Text>
                <Text style={styles.tableCell}>{item.cantidad}</Text>
                <Text style={styles.tableCell}>{item.proveedores.nombre}</Text>
              </View>
            ))}
          </View>
        </View>

        <View style={styles.totals}>
          <Text>Subtotal: $ {subtotal.toFixed(2)}</Text>
          <Text>Impuestos: $ {impuestos.toFixed(2)}</Text>
          <Text>Impuesto ventas: $ {impuestoVentas.toFixed(2)}</Text>
          <Text>Total: $ {total.toFixed(2)}</Text>
        </View>

        <View style={styles.footer}>
          <Text>____________________________________________</Text>
          <Text>Firma del empleado</Text>
          <Text style={styles.footerText}>
            El número de la orden de compra debe aparecer en todas las facturas
            y correspondencia.
          </Text>
          <Text style={styles.footerDate}>{fechaActual}</Text>
        </View>
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12
  },
  header: {
    marginBottom: 20,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  title: {
    fontSize: 20,
    fontWeight: "bold"
  },
  subTitle: {
    fontSize: 12
  },
  infoContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20
  },
  leftContainer: {
    width: "50%"
  },
  rightContainer: {
    width: "50%",
    textAlign: "right"
  },
  body: {
    marginBottom: 20
  },
  table: {
    width: "100%",
    borderWidth: 1,
    borderColor: "#000"
  },
  tableRow: {
    flexDirection: "row"
  },
  tableCellHeader: {
    width: "25%",
    padding: 8,
    backgroundColor: "#d3d3d3",
    fontWeight: "bold",
    borderWidth: 1,
    borderColor: "#000"
  },
  tableCell: {
    width: "25%",
    padding: 8,
    borderWidth: 1,
    borderColor: "#000"
  },
  totals: {
    textAlign: "right",
    marginRight: 10
  },
  footer: {
    marginTop: 20,
    textAlign: "center"
  },
  footerText: {
    fontSize: 10,
    marginTop: 10
  },
  footerDate: {
    marginTop: 10,
    fontSize: 10
  }
})

const blobToExport = async (data) => {
  return await pdf(<PdfOrdenCompra data={data} />).toBlob()
}

export default blobToExport
