import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableContainer,
  Paper,
} from "@mui/material";
import Vista from "../../components/Vista";
import { useState } from "react";
import Input from "../../components/Input";
import { EstilosButton, InventarioStyles } from "../../utils/styles";
import AutocompleteDeluxe from "../../components/AutocompleteDelux";
import { productDetails } from "../../utils/styles";
import { LoadingButton } from "@mui/lab";
import { BiPlus } from "react-icons/bi";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import servo from "../../services/servo";
import { useHistory } from "react-router-dom";
import { useAlert } from "../../hooks/useAlert";
import { useMessages } from "../../hooks/useMessages";
import { NavLink } from "react-router-dom";
import { useLocalStorage } from "../../hooks/useLocalStorage";

const defaultPropiedades = {
  operacion: "",
  comentario: "",
};
let vs = InventarioStyles();
let VS2 = EstilosButton();
const Detail = ({match }) => {
  let InventarioStyles = vs();
  let EstilosButton = VS2();
  const { showAlert } = useAlert();
  const { setMessages } = useMessages();
  const history = useHistory();
  const [propiedades, setPropiedades] = useState({ ...defaultPropiedades });
  const id = match?.params?.id;
  const [productos, setProductos] = useState([]);
  const [empresa, setEmpresa] = useState(null);
  const [producto, setProducto] = useState(null);
  const [sucursal, setSucursal] = useState(null);
  const[permiso] = useLocalStorage("permiso", null);
  const permisoCheck = "inventarios";

  const changeCantidad = async (_cantidad, pos) => {
    //validacion de productoinventario >  _cantidad && _cantidad > 0
    if ((_cantidad && _cantidad > 0) || _cantidad === "") {
      let copyData = [...productos];
      copyData[pos].cantidad = _cantidad;
      setProductos([...copyData]);
    }
  };
  const agregado = () => {
    if (producto.inventario) {
      if (producto != null) {
        let copyProductos = [...productos];
        let validacion = productos.some((product) => {
          return product.idProducto == producto.idProducto;
        });
        if (validacion) {
          showAlert({
            message: "producto repetido",
            severity: "warning",
          });
          return;
        }
        copyProductos.push({ ...producto, cantidad: 1 });
        setProductos([...copyProductos]);
      } else {
        producto == null &&
          showAlert({
            message: "Campo de Producto Vacío",
            severity: "warning",
          });
        return;
      }
    } else {
      if (propiedades.operacion == "Salidas") {
        showAlert({
          message: `${producto.nombreProducto} sin inventario disponible`,
          severity: "warning",
        });
      } else {
        if (producto != null) {
          let copyProductos = [...productos];
          let validacion = productos.some((product) => {
            return product.idProducto == producto.idProducto;
          });
          if (validacion) {
            showAlert({
              message: "producto repetido",
              severity: "warning",
            });
            return;
          }
          copyProductos.push({ ...producto, cantidad: 1 });
          setProductos([...copyProductos]);
        } else {
          producto == null &&
            showAlert({
              message: "Campo de Producto Vacío",
              severity: "warning",
            });
          return;
        }
      }
    }
  };
  const addProducto = async () => {
    //validacion de producto cantidad > 0
    if (propiedades?.operacion != "") {
      if (propiedades?.operacion == "Salidas") {
        if (empresa != null) {
          agregado();
        } else {
          showAlert({
            message: "Campo de Empresa Vacío",
            severity: "warning",
          });
          return;
        }
      } else {
        agregado();
      }
    } else {
      showAlert({
        message: "Campo Operación Vacío",
        severity: "warning",
      });
      return;
    }
  };

  const deleteProduct = (id) => {
    let arr = [...productos];
    productos.map((product, i) => {
      if (product.idProducto == id) {
        arr.splice(i, 1);
      }
    });
    setProductos([...arr]);
  };

  return (
    <Vista>
      <div className={InventarioStyles.container}>
      <div className={InventarioStyles.volverInv}>
          {(permiso[permisoCheck] & 1) === 1 ? (
            <NavLink exact to={"/inventarios"}>
              « Volver
            </NavLink>
          ) : (
            <p>« Volver</p>
          )}
        </div>
        <div className={InventarioStyles.formArea}>
          <div className={InventarioStyles.formDataContainer}>
            <Grid
              container
              direction="row"
              sx={{ width: "100%", height: "100%" }}
              columns={{ xs: 12, sm: 12, md: 12 }}>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  height: "92px",
                  padding: "0 10px 0 10px",
                  // height: "32%",
                }}>
                <Input
                  required="true"
                  typeInput={"formSelect"}
                  valor={propiedades?.operacion}
                  nombre="Operación"
                  onChange={(e) => {
                    {
                      propiedades?.operacion !== "" && setProductos([]);
                      setEmpresa(null);
                    }
                    setPropiedades({
                      ...propiedades,
                      operacion: e.target.value,
                    });
                  }}
                  data={["Ingreso", "Salidas"]}
                />
              </Grid>
              {propiedades?.operacion === "Salidas" && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    "@media (max-width: 600px)": {
                      alignItems: "center",
                    },
                    alignItems: "flex-end",
                    // height:"33%",
                    height: "92px",
                    // backgroundColor: "red",
                    padding: "0 10px 0 10px",
                  }}>
                  {propiedades?.operacion === "Salidas" && (
                    <AutocompleteDeluxe
                      labelToShow="Empresa"
                      labelProp="nombre"
                      nombre="empresas"
                      value={empresa}
                      setDato={setEmpresa}
                      background={"#FFFFFF"}
                    />
                  )}
                </Grid>
              )}

              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  // marginBottom: "4px",
                  height: "33%",
                  // height: "92px",
                  // backgroundColor: "red",
                  padding: "0 10px 0 10px",
                }}>
                <Input
                  nombre="Comentario"
                  typeFormat="text100"
                  typeInput={"form"}
                  valor={propiedades?.comentario}
                  placeholder="Comentario"
                  onChange={(e) =>
                    setPropiedades({
                      ...propiedades,
                      comentario: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  "@media (max-width: 600px)": {
                    alignItems: "center",
                  },
                  alignItems: "flex-end",
                  // height:"33%",
                  height: "92px",
                  // backgroundColor: "red",
                  padding: "0 10px 0 10px",
                }}>
                <AutocompleteDeluxe
                  labelToShow="Sucursal"
                  labelProp="nombreSucursal"
                  nombre="sucursales"
                  value={sucursal}
                  setDato={setSucursal}
                  background={"#FFFFFF"}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // marginBottom: "4px",
                  // height:"33%",
                  height: "92px",
                  // backgroundColor: "red",
                  padding: "0 10px 0 10px",
                }}>
                <AutocompleteDeluxe
                  required="true"
                  labelToShow="Productos"
                  labelProp="nombreProducto"
                  nombre="productos"
                  filtro={{ expand: "inventario" }}
                  value={producto}
                  setDato={setProducto}
                  background={"#FFFFFF"}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  "@media (max-width: 600px)": {
                    alignItems: "center",
                  },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  // marginBottom: "4px",
                  // height:"33%",
                  height: "92px",
                  // backgroundColor: "red",
                  padding: "0 10px 0 10px",
                }}>
                <Button
                  sx={{
                    width: "100%",
                    height: "41px",
                    backgroundColor: "#157CC1",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    textTransform: "none",
                    marginBottom: "7px",
                  }}
                  onClick={(e, i) => {
                    addProducto();
                  }}
                  variant="contained">
                  <BiPlus className={EstilosButton.icon} />
                  <p className={EstilosButton.font}>Agregar Producto</p>
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={InventarioStyles.formAreaTable}>
          <Paper
            sx={{
              "@media (max-width: 600px)": {
                width: "98%",
                minHeight: "50%",
              },
              width: "70%",
              overflow: "hidden",
            }}>
            <TableContainer sx={{ maxHeight: 320 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className={InventarioStyles?.tableHead}>
                    <TableCell
                      className="headColor"
                      sx={{ fontFamily: "poppins" }}>
                      #
                    </TableCell>
                    <TableCell
                      className="headColor"
                      sx={{ fontFamily: "poppins" }}>
                      Nombre
                    </TableCell>
                    <TableCell
                      className="headColor"
                      sx={{ fontFamily: "poppins" }}>
                      Cantidad
                    </TableCell>
                    <TableCell
                      className="headColor"
                      sx={{ fontFamily: "poppins" }}>
                      Eliminar
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productos?.map((element, i) => {
                    return (
                      <TableRow className={InventarioStyles?.tableRow} key={i}>
                        <TableCell
                          className="rowColor"
                          sx={{ fontFamily: "poppins" }}>
                          {i + 1}
                        </TableCell>
                        <TableCell
                          className="rowColor"
                          sx={{ fontFamily: "poppins", lineHeight: "13px" }}>
                          {element?.nombreProducto}
                        </TableCell>
                        <TableCell className="rowColor">
                          <div className={InventarioStyles?.quantity}>
                            <div
                              className={InventarioStyles?.quantityContainer}>
                              <div className={InventarioStyles?.quantityArea}>
                                <Input
                                  typeInput={"onlyInput"}
                                  style={{
                                    textAlign: "center",
                                    padding: "0px",
                                    color:
                                      element?.cantidad ==
                                      element?.inventario &&
                                      propiedades?.operacion == "Salidas" &&
                                      "#C11515",
                                  }}
                                  typeFormat={"number"}
                                  valor={element?.cantidad}
                                  onChange={(e) => {
                                    changeCantidad(e.target.value, i);
                                  }}
                                />
                              </div>
                              <div className={InventarioStyles?.moreLessArea}>
                                <div className={InventarioStyles?.buttonsArea}>
                                  <div
                                    className={
                                      InventarioStyles?.buttonsMoreLess
                                    }>
                                    <IconButton
                                      onClick={(e) => {
                                        if (element.cantidad === "") {
                                          changeCantidad(1, i);
                                        } else {
                                          propiedades?.operacion == "Salidas"
                                            ? element?.cantidad <
                                              element?.inventario
                                              ? changeCantidad(
                                                parseInt(element?.cantidad) +
                                                1,
                                                i
                                              )
                                              : element?.cantidad ==
                                              element?.inventario &&
                                              showAlert({
                                                message:
                                                  "Limite máximo de productos.",
                                                severity: "warning",
                                              })
                                            : changeCantidad(
                                              parseInt(element?.cantidad) + 1,
                                              i
                                            );
                                        }
                                      }}
                                      sx={{
                                        width: "100%",
                                        height: "100%",
                                        fontSize: "10px",
                                        color: "#717171",
                                      }}>
                                      <AddIcon sx={{ fontSize: "16px" }} />
                                    </IconButton>
                                  </div>
                                </div>
                                <div className={InventarioStyles?.buttonsArea}>
                                  <div
                                    className={
                                      InventarioStyles?.buttonsMoreLess
                                    }>
                                    <IconButton
                                      onClick={(e) => {
                                        if (element?.cantidad === "") {
                                          changeCantidad(1, i);
                                        } else {
                                          changeCantidad(
                                            element?.cantidad - 1,
                                            i
                                          );
                                        }
                                      }}
                                      sx={{
                                        width: "100%",
                                        height: "100%",
                                        fontSize: "10px",
                                        color: "#717171",
                                      }}>
                                      <RemoveIcon sx={{ fontSize: "16px" }} />
                                    </IconButton>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell className="rowColor">
                          <div
                            style={{
                              width: "100%",
                              height: "40px",
                              // backgroundColor: "red",
                            }}>
                            <IconButton
                              onClick={(e) => {
                                deleteProduct(element.idProducto);
                              }}>
                              <DeleteIcon sx={{ color: "#C11515" }} />
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
        <div className={InventarioStyles?.buttonAreaContainer}>
          <div className={InventarioStyles?.buttonContainer}>
            
            <LoadingButton
              style={
                productos.length <= 0
                  ? { color: "white", background: "#AAAAAA" }
                  : productos.some((prod) => prod.cantidad == "")
                    ? { color: "white", background: "#AAAAAA" }
                    : {}
              }
              disabled={
                productos.length <= 0
                  ? true
                  : productos.some((prod) => prod.cantidad == "")
                    ? true
                    : false
              }
              // loading={loadingB}
              // onClick={guardar}
              sx={{
                width: "170px",
                height: "50px",
                backgroundColor: "#157CC1",
                fontFamily: "poppins",
                borderRadius: "10px",
                border: "none",
                fontWeight: "500",
                fontSize: "14px",
                letterSpacing: "0.07em",
                color: "#FFFFFF",
                marginLeft: "2px",
                "@media (min-width: 600px)": {
                  marginBottom: "6px",
                },
                "&:hover": {
                  backgroundColor: "#1565c0",
                  cursor: "pointer",
                },
              }}
              className={InventarioStyles?.buttonSave}
              onClick={async (e) => {
                {
                  productos.length <= 0 && setEmpresa(null);
                  await servo.post(
                    "inventarios" + "/guardar",
                    { ...propiedades, productos, empresa, sucursal },
                    "inventarios",
                    true,
                    history
                  );
                  showAlert({
                    message: "Inventario agregado correctamente",
                    severity: "success",
                  });
                }
              }}>
              GUARDAR
            </LoadingButton>
          </div>
        </div>
      </div>
    </Vista>
  );
};
export default Detail;
