import React, { useEffect, useMemo } from "react"
import "./App.css"
import { CacheBusterProvider } from "./hooks/CacheBuster"
import { AlertProvider, useAlert } from "./hooks/useAlert"
import { AuthProvider, useAuth } from "./hooks/useAuth"
import { ProfileProvider, useProfile } from "./hooks/useProfile"
import Alert from "@mui/material/Alert"
import PrivateRoutes from "./routes/PrivateRoutes"
import PublicRoutes from "./routes/PublicRoutes"
import FullScreenLoader from "./components/FullScreenLoader"
import { MessagesProvider } from "./hooks/useMessages"
import { Collapse } from "@mui/material"
import { MessageStyles } from "./utils/styles"
import { ModalProvider, useModal } from "./hooks/useModal"
import { GetDatosProvider } from "./hooks/useGetDatos"
import { useSocket, SocketProvider } from "./hooks/useSockets"
import Modal from "./components/Modal"

const MS = MessageStyles()
const RootComponent = () => {
  const { session, sessionLoading, loginLoading } = useAuth()
  const { alertas, showAlert } = useAlert()
  const { socket } = useSocket()

  const MessageStyles = MS()

  const AlertMemo = useMemo(
    () => (
      <>
        {alertas.length > 0 && (
          <div className={MessageStyles.alert}>
            {alertas &&
              alertas.map((alerta, i) => {
                return (
                  <Collapse key={i} in={alerta.open}>
                    <Alert
                      style={{ margin: "10px" }}
                      severity={alerta.severity}
                      elevation={6}
                      variant="filled"
                    >
                      {alerta.message}
                    </Alert>
                  </Collapse>
                )
              })}
          </div>
        )}
      </>
    ),
    [alertas]
  )
  useEffect(() => {
    if (socket) {
      // Escuchar el evento 'serverMessage' que enviamos desde el servidor
      socket.on("serverMessage", (noti) => {
        let currentNotis = window.localStorage.getItem("notis")
          ? JSON.parse(window.localStorage.getItem("notis"))
          : []
        currentNotis = [...currentNotis, noti]
        window.localStorage.setItem("notis", JSON.stringify(currentNotis)) // Guardar en localStorage
        showAlert({ message: noti.title, severity: "success" })
      })

      // Limpiar el evento cuando el componente se desmonte
      return () => {
        socket.off("serverMessage")
      }
    }
  }, [socket])

  if (sessionLoading) return <FullScreenLoader />

  return (
    <>
      <Modal />
      {!session ? (
        <PublicRoutes />
      ) : !loginLoading ? (
        <PrivateRoutes />
      ) : (
        <FullScreenLoader />
      )}
      {AlertMemo}
    </>
  )
}

function App() {
  return (
    <CacheBusterProvider>
      <AlertProvider>
        <MessagesProvider>
          <SocketProvider>
            <ModalProvider>
              <ProfileProvider>
                <AuthProvider>
                  <GetDatosProvider>
                    <RootComponent />
                  </GetDatosProvider>
                </AuthProvider>
              </ProfileProvider>
            </ModalProvider>
          </SocketProvider>
        </MessagesProvider>
      </AlertProvider>
    </CacheBusterProvider>
  )
}

export default App
