import {
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material"
import React, { useEffect, useState } from "react"
import Input from "../../components/Input"
import Vista from "../../components/Vista"
import { productDetails } from "../../utils/styles"
import { NavLink } from "react-router-dom"
import { useLocalStorage } from "../../hooks/useLocalStorage"
import Servo from "../../services/servo"
import { useHistory } from "react-router-dom"
import { useMessages } from "../../hooks/useMessages"
import { LoadingButton } from "@mui/lab"
import { useAlert } from "../../hooks/useAlert"
import DeleteIcon from "@mui/icons-material/Delete"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import AutocompleteDeluxe from "../../components/AutocompleteDelux"

let vs = productDetails()
const Detail = ({ match }) => {
  const history = useHistory()
  const formDefault = {
    status: "",
    justificacion: "",
    fecha: new Date(),
    total: 0,
    tipoPago: ""
  }

  // AREA DE DECLARACIONES
  const { setMessages } = useMessages()
  const { showAlert } = useAlert()
  const permisoCheck = "compras"
  const [permiso] = useLocalStorage("permiso", null)
  const [formDetails, setformDetails] = useState({ ...formDefault })
  const id = match?.params?.id
  const editado = !!id
  const [loadingB, setLoadingB] = useState(false)
  const [loading, setLoading] = useState(false)
  const [detallesCompra, setDetallesCompra] = useState([])
  const [articulo, setArticulo] = useState("")
  const [precio, setPrecio] = useState(0)
  const [cantidad, setCantidad] = useState(0)
  const [unidadMedida, setUnidadMedida] = useState("")
  const [sucursal, setSucursal] = useState(null)

  const addItem = () => {
    if (articulo.trim() !== "" && cantidad > 0 && precio > 0) {
      setDetallesCompra([
        ...detallesCompra,
        { articulo, cantidad, precio, unidadMedida }
      ])
      setArticulo("")
      setPrecio(0)
      setCantidad(0)

      showAlert("Producto agregado correctamente", "success")
    } else {
      showAlert("Por favor, llene todos los campos", "error")
    }
  }

  const deleteItem = (index) => {
    const newDetalleCompra = [...detallesCompra]
    newDetalleCompra.splice(index, 1)
    setDetallesCompra(newDetalleCompra)

    showAlert("Producto eliminado correctamente", "success")
  }

  // SE EXTRAEN LOS PRODUCTOS
  const getDatos = async () => {
    setLoading(true)
    let res = await Servo.get("compras?idCompra=" + id + "&expand=productos")
    if (res?.cuerpo?.resultado?.length > 0) {
      setformDetails(res?.cuerpo?.resultado[0])
      setLoading(false)
    }
  }

  // const handleProveedorChange = (prov, index) => {
  //   let newCompra = [...detallesCompra];
  //   newCompra[index].proveedor = prov;
  //   setDetallesCompra(newCompra);
  // };

  useEffect(() => {
    if (editado) {
      getDatos()
    }
  }, [editado])

  const totalCompra = detallesCompra.reduce(
    (acc, curr) => acc + curr.cantidad * curr.precio,
    0
  )

  useEffect(() => {
    setformDetails((prev) => ({
      ...prev,
      total: totalCompra
    }))
  }, [detallesCompra])

  // SE GUARDAN LOS PROVEEDORES
  const guardar = async () => {
    setLoadingB(true)
    let body = {
      ...formDetails,
      detallesCompra: detallesCompra,
      idSucursal: sucursal.idSucursal
    }

    if (editado) {
      body = { ...body, idCompra: id }
    }

    let res = await Servo.post(
      "compras/guardar",
      body,
      "compras",
      true,
      history,
      true
    )

    if (res?.cuerpo?.resultado) {
      setMessages(res?.cuerpo?.mensaje)
    }
    setLoadingB(false)
  }

  let productDetails = vs()

  return (
    <Vista>
      <div className={productDetails.screenSize}>
        <div className={productDetails.volverCompras}>
          {(permiso[permisoCheck] & 1) === 1 ? (
            <NavLink exact to={"/" + permisoCheck}>
              « Volver
            </NavLink>
          ) : (
            <p>« Volver</p>
          )}
        </div>
        <div className={productDetails.volverCompras}>
          <div className={productDetails.title}>
            {editado ? <p>Editar Compra</p> : <p>Nuevo Compra</p>}
          </div>
        </div>
        <div className={productDetails.formArea}>
          <Grid container direction="row" columns={{ xs: 12, sm: 12, md: 12 }}>
            {!loading ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px"
                    }
                  }}
                  className={productDetails.inputArea}
                >
                  <Input
                    typeInput={"date"}
                    nombre="Fecha"
                    valor={formDetails?.fecha}
                    setValor={(newValue) => {
                      setformDetails({
                        ...formDetails,
                        fecha: newValue
                      })
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px",
                      marginBottom: "10px"
                    },
                    display: "flex",
                    alignItems: "flex-end"
                  }}
                  className={productDetails.inputArea}
                >
                  <AutocompleteDeluxe
                    labelToShow="Sucursal"
                    labelProp="nombreSucursal"
                    nombre="sucursales"
                    value={sucursal}
                    setDato={setSucursal}
                    backgroundColor={"#FFFFFF"}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px",
                      marginBottom: "10px"
                    },
                    display: "flex",
                    alignItems: "flex-end"
                  }}
                  className={productDetails.inputArea}
                >
                  <Input
                    typeInput={"form"}
                    valor={formDetails?.justificacion}
                    setValor={setformDetails}
                    placeholder=""
                    nombre="Justificacion"
                    required="true"
                    onChange={(e) => {
                      setformDetails({
                        ...formDetails,
                        justificacion: e.target.value
                      })
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px",
                      marginBottom: "10px"
                    },
                    display: "flex",
                    alignItems: "flex-end"
                  }}
                  className={productDetails.inputArea}
                >
                  <Input
                    typeInput={"formSelect"}
                    valor={formDetails?.tipoPago}
                    nombre="Tipo de pago"
                    required="true"
                    onChange={(e) => {
                      setformDetails({
                        ...formDetails,
                        tipoPago: e.target.value
                      })
                    }}
                    data={["Efectivo", "Credito", "Transferencia", "Tarjeta"]}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px",
                      marginBottom: "10px"
                    },
                    display: "flex",
                    alignItems: "flex-end"
                  }}
                  className={productDetails.inputArea}
                ></Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px",
                      marginBottom: "10px"
                    },
                    display: "flex",
                    alignItems: "flex-end"
                  }}
                  className={productDetails.inputArea}
                ></Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px",
                      marginBottom: "10px"
                    },
                    display: "flex",
                    alignItems: "flex-end"
                  }}
                  className={productDetails.inputArea}
                >
                  <Input
                    typeInput={"form"}
                    valor={articulo}
                    setValor={setArticulo}
                    placeholder="Nombre del producto"
                    nombre="Producto"
                    required="true"
                    onChange={(e) => {
                      setArticulo(e.target.value)
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px",
                      marginBottom: "10px"
                    },
                    display: "flex",
                    alignItems: "flex-end"
                  }}
                  className={productDetails.inputArea}
                >
                  <Input
                    typeInput={"form"}
                    valor={cantidad}
                    setValor={setCantidad}
                    placeholder="Cantidad"
                    nombre="Cantidad"
                    required="true"
                    onChange={(e) => {
                      setCantidad(e.target.value)
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px",
                      marginBottom: "10px"
                    },
                    display: "flex",
                    alignItems: "flex-end"
                  }}
                  className={productDetails.inputArea}
                >
                  <Input
                    typeInput={"formSelect"}
                    valor={unidadMedida}
                    setValor={setUnidadMedida}
                    placeholder="Unidad de medida"
                    nombre="Unidad"
                    required="true"
                    onChange={(e) => {
                      setUnidadMedida(e.target.value)
                    }}
                    data={[
                      "Pieza",
                      "Litro(s)",
                      "kilo(s)",
                      "Metro(s)",
                      "Caja(s)"
                    ]}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px",
                      marginBottom: "10px"
                    },
                    display: "flex",
                    alignItems: "flex-end"
                  }}
                  className={productDetails.inputArea}
                >
                  <Input
                    typeInput={"form"}
                    valor={precio}
                    setValor={setPrecio}
                    placeholder="Precio unitario"
                    nombre="Precio"
                    required="true"
                    onChange={(e) => {
                      setPrecio(e.target.value)
                    }}
                  />
                  <IconButton onClick={addItem} color="primary">
                    <AddCircleIcon />
                  </IconButton>
                </Grid>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Nombre del producto</TableCell>
                        {/* <TableCell>Proveedor</TableCell> */}
                        <TableCell>Cantidad</TableCell>
                        <TableCell>Precio Unitario</TableCell>
                        <TableCell>Unidad Medida</TableCell>
                        <TableCell>Total</TableCell>
                        <TableCell>Acciones</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {detallesCompra.map((dato, index) => (
                        <TableRow key={index}>
                          <TableCell>{dato.articulo}</TableCell>
                          {/* <TableCell>
                            <AutocompleteDeluxe
                              labelProp="nombre"
                              nombre="proveedores"
                              value={dato.proveedor}
                              setDato={(prov) => handleProveedorChange(prov, index)}
                              backgroundColor={"#FFFFFF"}
                            />
                          </TableCell> */}
                          <TableCell>{dato.cantidad}</TableCell>
                          <TableCell>${dato.precio}</TableCell>
                          <TableCell>{dato.unidadMedida}</TableCell>
                          <TableCell>
                            ${(dato.total = dato.precio * dato.cantidad)}
                          </TableCell>
                          <TableCell>
                            <IconButton onClick={() => deleteItem(index)}>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px",
                      marginBottom: "10px"
                    },
                    display: "flex",
                    alignItems: "flex-end"
                  }}
                  className={productDetails.inputArea}
                >
                  <Input
                    disabled
                    typeInput={"form"}
                    typeFormat="numberDecimal"
                    valor={totalCompra}
                    setValor={setformDetails}
                    placeholder=""
                    nombre="Total de la compra"
                    required="true"
                  />
                </Grid>
              </>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{
                  "@media (min-width: 600px)": {
                    margin: "100px"
                  }
                }}
                className={productDetails.inputArea}
              >
                <CircularProgress />
              </Grid>
            )}
          </Grid>
        </div>
        <div className={productDetails.buttonArea}>
          <div className={productDetails.buttonArea}>
            {(permiso[permisoCheck] & 2) === 2 ? (
              <LoadingButton
                loading={loadingB}
                onClick={guardar}
                sx={{
                  width: "170px",
                  height: "50px",
                  backgroundColor: "#157CC1",
                  fontFamily: "poppins",
                  borderRadius: "10px",
                  border: "none",
                  fontWeight: "500",
                  fontSize: "14px",
                  letterSpacing: "0.07em",
                  color: "#FFFFFF",
                  marginLeft: "2px",
                  "@media (min-width: 600px)": {
                    marginBottom: "6px"
                  },
                  "&:hover": {
                    backgroundColor: "#1565c0",
                    cursor: "pointer"
                  }
                }}
              >
                GUARDAR
              </LoadingButton>
            ) : (
              <LoadingButton
                onClick={guardar}
                disabled
                sx={{
                  width: "170px",
                  height: "50px",
                  backgroundColor: "#157CC1",
                  fontFamily: "poppins",
                  borderRadius: "10px",
                  border: "none",
                  fontWeight: "500",
                  fontSize: "14px",
                  letterSpacing: "0.07em",
                  color: "#FFFFFF",
                  marginLeft: "2px",
                  "@media (min-width: 600px)": {
                    marginBottom: "6px"
                  },
                  "&:hover": {
                    backgroundColor: "#1565c0",
                    cursor: "pointer"
                  }
                }}
              >
                GUARDAR
              </LoadingButton>
            )}
          </div>
        </div>
      </div>
    </Vista>
  )
}

export default Detail
