import { ExcelFile, ExcelColumn, ExcelSheet } from "react-excel-exporter"
import React from "react"
import { IconButton } from "@mui/material"
import { SiMicrosoftexcel } from "react-icons/si"

const TableExcel = ({ data, permisoCheck, fechaInicio, fechaFinal }) => {
  if (data.length === 0) {
    return null
  }

  const keys = Object.keys(data[0])

  const formatFecha = (fecha) => {
    if (!fecha) return "SinFecha"
    return new Date(fecha).toLocaleDateString().replace(/\//g, "-")
  }

  const generateFilename = () => {
    const fechaInicioFormat = formatFecha(fechaInicio)
    const fechaFinalFormat = formatFecha(fechaFinal)
    return `Reporte_${permisoCheck}_${fechaInicioFormat}_a_${fechaFinalFormat}`
  }

  return (
    <ExcelFile
      element={
        <IconButton
          sx={{
            color: "green",
            backgroundColor: "transparent",
            borderRadius: "10px",
            height: { xs: "35px", sm: "40px", md: "45px", lg: "50px" },
            width: { xs: "35px", sm: "40px", md: "45px", lg: "50px" },
            "@media (max-width: 1024px)": {
              marginLeft: "50px"
            },
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.1)" // Cambia el fondo para mejor visibilidad
            },
            display: "block" // Asegúrate de que el botón sea visible en todas las pantallas
          }}
        >
          <SiMicrosoftexcel />
        </IconButton>
      }
      filename={generateFilename()}
    >
      <ExcelSheet data={data} name="Datos">
        {keys.map((key) => (
          <ExcelColumn
            key={key}
            label={key.charAt(0).toUpperCase() + key.slice(1)}
            value={key}
          />
        ))}
      </ExcelSheet>
    </ExcelFile>
  )
}

export default TableExcel
